import Config   from "../../config.js";
import Response from "./entity/response";

export default class Server {
  static token() {
    return extract_cookie(AUTH_TOKEN);
  }

  static async get(action) {
    let response = await fetch(
      prepare_url(action),
      {
        method : "GET",
        headers: prepare_headers({
          "Accept"      : "application/json",
          "Content-Type": "application/json"
        })
      }
    );
    return create_response(response);
  }

  static async post(action, data) {
    let response = await fetch(
      prepare_url(action),
      {
        body   : JSON.stringify(data),
        method : "POST",
        headers: prepare_headers({
          "Accept"      : "application/json",
          "Content-Type": "application/json" 
        })
      }
    );
    return create_response(response);
  }

  static async put(action, data) {
    let response = await fetch(
      prepare_url(action),
      {
        body   : JSON.stringify(data),
        method : "PUT",
        headers: prepare_headers({
          "Accept"      : "application/json",
          "Content-Type": "application/json"
        })
      }
    );
    return create_response(response);
  }

  static async delete(action) {
    let response = await fetch(
      prepare_url(action),
      {
        method : "DELETE",
        headers: prepare_headers({
          "Accept"      : "application/json",
          "Content-Type": "application/json"
        })
      }
    );
    return create_response(response);
  }

  static async upload(action, data) {
    let response = await fetch(
      prepare_url(action),
      {
        body   : data,
        method : "POST",
        headers: prepare_headers()
      }
    );
    return create_response(response);
  }
}

const URL_LOGOUT = "/login";
const AUTH_TOKEN = "auth-token";

const UNAUTHENTICATED_MESSAGE = "Unauthenticated.";
const UNVERIFIED_MESSAGE      = "Your email address is not verified.";

function prepare_url(action) {
  return Config.API + "/api/" + action;
}

function prepare_headers(headers) {
  let result = {
    "Authorization": "Bearer " + extract_cookie(AUTH_TOKEN)
  };

  for (const key in headers) {
    result[key] = headers[key];
  }

  return result;
}

function extract_cookie(name) {
  /* eslint no-useless-escape: 0 */
  let matches = document.cookie.match(new RegExp(
    "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
  ));
  return matches ? decodeURIComponent(matches[1]) : '';
}

async function create_response(response) {
  let json = await response.json();

  redirect_without_access(json);
  redirect_unverified(json);

  handle_failure(json);
  handle_message(json);
  handle_success(json);

  return new Response(json.access, json.data, json.status, json.errors, response.headers, json.access_token);
}

async function handle_failure(response) {
  if (response.trace) {
    let {default: Reminder} = await import (/* webpackChunkName: "reminder" */ "../notice/reminder");
    Reminder.render([ "<p>Что-то пошло не так. Свяжитесь с техподдержкой.</p><p>Сообщение: " + response.message.substring(0, 255) + "</p>" ]);
  }
}

async function handle_message(response) {
  if (!response.trace && response.message && response.message != UNAUTHENTICATED_MESSAGE) {
    let {default: Reminder} = await import (/* webpackChunkName: "reminder" */ "../notice/reminder");
    Reminder.render([ "<p>" + response.message.substring(0, 255) + "</p>" ]);
  }
}

async function handle_success(response) {
  if (response.success) {
    let {default: Reminder} = await import (/* webpackChunkName: "reminder" */ "../notice/reminder");

    let messages = [];
    response.success.forEach(element => {
      messages.push(element.message);
    });

    Reminder.render(messages);
  }
}

function redirect_without_access(response) {
  if (response.message && response.message == UNAUTHENTICATED_MESSAGE && window.location.pathname !== URL_LOGOUT) {
    document.location.replace(URL_LOGOUT);
  }
}

function redirect_unverified(response) {
  if (response.message && response.message == UNVERIFIED_MESSAGE && window.location.pathname !== URL_LOGOUT) {
    document.location.replace(URL_LOGOUT);
  }
}