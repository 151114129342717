<template>
  <div class="d-flex w-100 c-pointer" @click.stop="$emit('choose', 'deadline')">
    <div class="p-relative w-32px h-32px">
      <div class="icon large" :class="icon_percent()" />
      <div class="icon small p-absolute t-9px l-9px" :class="icon_status()" />
    </div>

    <div
      class="d-flex w-100 h-32px border-top-1 border-right-1 border-bottom-1 border-radius-0-16-16-0"
      :class="style()"
    >
      <div class="d-flex flex-column justify-content-center align-items-center pl-2 pr-8">
        <div class="font-section" :class="style()">
          {{ deadline ? format_date(deadline): 0 }}
        </div>

        <div class="font-soft">
          {{ format_date(created) }}
        </div>
      </div>

      <div
        class="diagram-edit d-flex align-items-center base-neutral font-dark h-30px my-1 border-radius-16"
        :class="{ 'transition-100': edit, 'd-none': !edit }"
        :style="{ 'opacity': 0 }"
      >
        <field-input
          v-model="fields.deadline"
          :class="{ 'mx-0': !edit, 'mx-16': edit }"
          :autofocus="true"
          @keyup.stop
          @keyup.esc.stop="hide_form"
          @keyup.enter.stop="set_value"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import {
    ref,
    reactive,
    watch,
    onMounted
  } from "vue";
  
  import moment from "moment";

  import FieldInput from "../../../../fields/input/Input.vue";

  export default {
    components: {
      FieldInput
    },

    props: {
      created : { type: String , default: () => ""    },
      deadline: { type: String , default: () => ""    },
      edit    : { type: Boolean, default: () => false }
    },

    emits: [
      "clear",
      "choose",
      "submit"
    ],

    setup(props, context) {
      const percent = ref(0);
      const fields  = reactive({ deadline: props.deadline ? moment(props.deadline).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD") });

      onMounted(() => recount(props.deadline));
      watch(() => props.deadline, async (deadline) => recount(deadline));

      let recount = (deadline) => {
        if (deadline) {
          let summary = moment(deadline).diff(moment(props.created), 'days');
          let current = moment().diff(moment(props.created), 'days');
  
          if (moment() <= moment(props.created)) { percent.value = 0; }
          else { percent.value = summary == 0 ? 0 : Math.ceil((current / summary * 100) / 5) * 5; }
        }
        else {
          percent.value = 0;
        }
      };

      let icon_percent = () => {
        if (percent.value > 200) { return "progress-percent-200"; }
        else { return "progress-percent-" + percent.value; }
      };

      let icon_status = () => {
        if      (percent.value == 0)                       { return "progress-calendar-empty";    }
        else if (percent.value > 0 && percent.value < 100) { return "progress-calendar-progress"; }
        else if (percent.value == 100)                     { return "progress-calendar-full";     }
        else if (percent.value > 100)                      { return "progress-calendar-denial";   }
      };

      let style = () => {
        if      (percent.value == 0)                       { return "color-border-soft font-soft";           }
        else if (percent.value > 0 && percent.value < 100) { return "color-border-highlight font-highlight"; }
        else if (percent.value == 100)                     { return "color-border-selection font-selection"; }
        else if (percent.value > 100)                      { return "color-border-denial font-denial";       }
      };

      let format_date = (date) => {
        return moment(date).format("DD/MM");
      };

      let hide_form = () => {
        context.emit("clear");
        fields.deadline = props.deadline ? moment(props.deadline).format("YYYY-MM-DD") : moment().format("YYYY-MM-DD");
      };

      return {
        fields,

        icon_percent,
        icon_status,
        style,

        format_date,
        hide_form,

        set_value: () => {
          if (fields.deadline && moment(fields.deadline).isValid()) {
            context.emit("submit", moment(fields.deadline).format("YYYY-MM-DD"));
          } 

          hide_form();
        }
      };
    }
  };
</script>