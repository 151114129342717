export default class Element {
  constructor(data) {
    this.name        = data.name        || "";
    this.description = data.description || "";
    this.path        = data.path        || "";
    this.icon        = data.icon        || "";
    this.type        = data.type        || DEFAULT_TYPE;
    this.weight      = data.weight      || DEFAULT_WEIGHT;
    this.bundle      = data.bundle      || DEFAULT_BUNDLE;
  }
}

const DEFAULT_TYPE   = "element";
const DEFAULT_BUNDLE = 0;
const DEFAULT_WEIGHT = 0;