<template>
  <div class="p-relative base-neutral p-8">
    <div v-if="!sprints.length" @click="show_form">
      <div v-if="!form.status" class="d-flex c-pointer font-memo align-items-center">
        {{ $t("break_sprints") }}
      </div>

      <div v-else class="d-flex c-pointer align-items-center">
        <field-input
          v-model="form.value"
          :autofocus="true"
          :placeholder="$t('duration_sprint')"
          @keyup.enter="form_handler"
          @keyup.esc="hide_form"
        />
      </div>
    </div>

    <div v-else>
      <div v-if="project.roadmap_pick"
        class="d-flex font-memo justify-content-end mb-4 c-pointer"
        @click="$emit('create', project.roadmap_pick)"
      >
        {{ $t("add_sprint") }}
      </div>

      <widget-scrolling-main :array="sprints">
        <template #item="element">
          <sprint-partial
            :project="project"
            :list="list"
            :item="element.item"

            @detail="data => $emit('detail', data)"
            @cancel="$emit('cancel')"
            @remove="(milestones_id) => $emit('remove', milestones_id)"
            @reset="(node) => $emit('reset', node)"
            @move="(node, target) => $emit('move', node, target)"
            @period="(milestones_id, start, finish) => $emit('period', milestones_id, start, finish)"
            @shipped="(milestones_id) => $emit('shipped', milestones_id)"
          />
        </template>
      </widget-scrolling-main>
    </div>
  </div>
</template>

<script>
  import {
    reactive
  } from 'vue';

  import SprintPartial from "./Spint.vue";

  import WidgetScrollingMain from "../../../../visual/widgets/scrolling/Main.vue";

  import FieldInput from "../../../../visual/fields/input/Input.vue";

  export default {
    components: {
      SprintPartial,
      WidgetScrollingMain,
      FieldInput
    },

    props: {
      project: { type: Object, default: () => {} },
      sprints: { type: Array , default: () => [] },
      list   : { type: Array , default: () => [] }
    },

    emits: [
      "detail",
      "cancel",

      "break",
      "create",
      "remove",
      "reset",
      "move",
      "period",
      "shipped"
    ],

    setup(props, context) {
      const form = reactive({ status: false, value: "" });

      let show_form = () => form.status = true;
      let hide_form = () => form.status = false;

      return {
        form,

        show_form,
        hide_form,

        form_handler: () => {
          context.emit("break", props.project.roadmap_pick, form.value);
          hide_form();
        }
      };
    }
  };
</script>