import Server from "../../../../system/server/server";
import Entity from "../../entities/Detail";

export default class Role {
  static async create(unit) {
    return new Entity(await Server.post("chains/unit", unit));
  }

  static async update(id, unit) {
    return new Entity(await Server.put("chains/unit/" + id, unit));
  }

  static async delete(id) {
    return new Entity(await Server.delete("chains/unit/" + id));
  }
}