<template>
  <div class="popup popup-base p-fixed">
    <div class="popup-body p-fixed"
      :class="{
        'h-60': overview,
        'overflow-scroll': overview,
        'scrollbar-disable': overview
      }">
      <div class="popup-header d-flex justify-content-between">
        <slot name="header" />
      </div>

      <div class="popup-content">
        <slot name="content" />
      </div>

      <div class="popup-footer d-flex justify-content-end">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      overview: { type: Boolean, default: () => false },
    },

    setup () { return {}; }
  };
</script>