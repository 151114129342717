import SYSTEM from "../system.js";

export default class Detail {
  constructor(response) {
    this.access     = false;
    this.errors     = {};
    this.status     = {};
    this.root       = {};
    this.categories = [];
    this.releases   = [];
    this.cycles     = [];
    this.nodes      = [];
    this.links      = [];
    this.leaves     = [];
    this.goals      = [];
    this.related    = [];
    this.path       = {};

    if (response) {
      if (response.access) {
        this.access = Object.values(response.access).includes(SYSTEM.MODULE);
      }

      if (response.errors) {
        this.errors = response.errors;
      }

      if (response.status) {
        this.status = response.status;
      }

      if (response.data) {
        this.root       = response.data.root;
        this.categories = response.data.categories;
        this.releases   = response.data.releases;
        this.cycles     = response.data.cycles;
        this.nodes      = response.data.nodes;
        this.links      = response.data.links;
        this.leaves     = response.data.leaves;
        this.goals      = response.data.goals;
        this.related    = response.data.related;
        this.path       = response.data.path;
      }
    }
  }
}