<template>
  <div class="id text-right pl-8" :class="{ 'font-dark': !selected, 'font-neutral': selected }">
    #{{ id }}
  </div>
</template>

<script>
  export default {
    props: {
      id      : { type: Number , default: () => 0     },
      selected: { type: Boolean, default: () => false }
    }
  };
</script>