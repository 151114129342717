<template>
  <layout-web fullscreen>
    <div class="p-8">
      <div class="d-flex base-neutral border-radius-8 mb-16 p-8">
        <div class="font-title">
          {{ $t("Projects") }}
        </div>
      </div>

      <div class="base-neutral border-radius-8 p-8">
        <div class="box-grid col-5">
          <widget-cards-simple
            v-for="item in source.projects"
            :key="item.id"
            class="base-neutral"
            :item="item"
            :path="'/projects/' + item.id"
            :menu="['details', 'remove']"
            :partials="['code', 'description', 'menu']"
            @remove="remove"
          />
        </div>

        <div class="d-flex">
          <div class="button confirm bottom" @click="show_popups_create">
            {{ $t("add") }}
          </div>
        </div>
      </div>
    </div>

    <!-- Popup: Create project -->
    <popup-base v-if="popups.create">
      <template #header>
        <div>{{ $t("add_project") }}</div>

        <div
          class="icon icon-close c-pointer"
          @click="hide_popups_create"
        />
      </template>

      <template #content>
        <field-input
          v-model="fields.name"
          class="mb-10"
          :placeholder="$t('insert_name')"
          :message="$t('name_is_empty')"
          :failure="errors.name"
        />

        <field-select
          v-model="fields.spaces_id"
          :items="source.spaces"
          :selected="source.spaces[0].id"
          :placeholder="$t('insert_organization')"
          :failure="errors.spaces_id"
          :message="$t('organization_is_empty')"
        />
      </template>

      <template #footer>
        <div class="button primary" @click="create">
          {{ $t("ok") }}
        </div>
      </template>
    </popup-base>
  </layout-web>
</template>

<script>
  import {
    reactive,
    onMounted
  } from "vue";

  import LayoutWeb   from "../../../layouts/Web.vue";
  import PopupBase   from "../../../visual/popups/base/Base.vue";
  import FieldInput  from "../../../visual/fields/input/Input.vue";
  import FieldSelect from "../../../visual/fields/select/Select.vue";

  import WidgetCardsSimple from "../../../visual/widgets/cards/simple/Simple.vue";

  import RosterEntity  from "../entities/Roster.js";
  import RosterService from "../services/Roster.js";

  export default {
    components: {
      LayoutWeb,
      PopupBase,
      FieldInput,
      FieldSelect,
      WidgetCardsSimple
    },

    setup() {
      const source = reactive(new RosterEntity);
      const popups = reactive({ create: false });

      const fields = reactive({ name: ""   , spaces_id: ""    });
      const errors = reactive({ name: false, spaces_id: false });

      onMounted(async () => Object.assign(source, await RosterService.load()));

      let show_popups_create = () => popups.create = true;

      let hide_popups_create = () => {
        popups.create    = false;
        fields.name      = "";
        fields.spaces_id = "";
      };

      let attach_errors = () => {
        errors.name      = fields.name      == "" ? true : false;
        errors.spaces_id = fields.spaces_id == "" ? true : false;
      };

      let remove_errors = () => {
        errors.name      = false;
        errors.spaces_id = false;
      };

      return { source, errors, fields, popups,
        show_popups_create,
        hide_popups_create,

        create: async () => {
          if (fields.name != "" && fields.spaces_id != "") {
            remove_errors();

            Object.assign(source, await RosterService.create(fields));

            hide_popups_create();
          }
          else {
            attach_errors();
          }
        },

        remove: async (id) => Object.assign(source, await RosterService.delete({ id: id }))
      };
    }
  };
</script>