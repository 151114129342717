<template>
  <div class="d-flex">
    <div v-if="status.list"
      class="d-flex flex-column p-absolute box-shadow-top w-300px h-500px mt--518 z-102 p-8 base-neutral border-1 border-radius-8 color-border-light overflow-y-scroll scrollbar"
      @dragover.prevent
      @dragenter.prevent
      @dragleave.prevent
      @dragend.prevent
      @drop="drop($event, item.id)"
      @wheel.stop
    >
      <div v-for="node in list.filter(node => node.milestones_id == item.id)" :key="node.id" class="mb-8">
        <widget-card-node-main
          class="base-neutral h-100"
          :item="node"
          :menu="['details', 'strucrure']"
          :partials="['code', 'description', 'complexity', 'schedule', 'deadline', 'period-value', 'menu', 'close', 'path', 'progress-stages']"

          draggable="true"
  
          @keyup.enter.exact="$emit('detail', { id: node.id, code: node.code, header: '#' + node.code })"
          @keyup.esc.exact="$emit('cancel')"

          @click="$emit('detail', { id: node.id, code: node.code, header: '#' + node.code })"
          @close="node => $emit('reset', node)"

          @dragover.prevent
          @dragend.prevent
          @dragstart.stop="drag($event, node.id)"
        />
      </div>
    </div>

    <widget-card-milestone-sprint
      class="base-neutral min-w-300px w-300px"
      :class="{ 'base-soft': item.shipped}"

      :item="item"
      :selected="list.find(id => id == item.id)"
      :menu="['remove']"
      :partials="['menu', 'top-dropdown', 'shipped', 'progress-period-simple', 'progress-completed', 'progress-estimate']"
      :dropdown="status.list"
      :complexity="complexity()"
      :total="total()"
      :completed="completed()"

      @click="() => status.list = !status.list"
      @remove="$emit('remove', item.id)"
      @period="(start, finish) => $emit('period', item.id, start, finish)"
      @shipped="(milestones_id) => $emit('shipped', milestones_id)"
      @dragover.prevent
      @dragenter.prevent
      @dragleave.prevent
      @dragend.prevent
      @drop="drop($event, item.id)"
    />
  </div>
</template>

<script>
  import {
    reactive
  } from 'vue';

  import WidgetCardNodeMain        from "../../../../visual/widgets/cards/node/Main.vue";
  import WidgetCardMilestoneSprint from "../../../../visual/widgets/cards/milestone/Sprint.vue";

  export default {
    components: {
      WidgetCardNodeMain,
      WidgetCardMilestoneSprint,
    },

    props: {
      project: { type: Object, default: () => {} },
      list   : { type: Array , default: () => [] },
      item   : { type: Object, default: () => {} }
    },

    emits: [
      "detail",
      "cancel",
      "remove",
      "reset",
      "move",
      "period",
      "shipped",
    ],

    setup(props, context) {
      const status = reactive({ list: false });

      let complexity = () => {
        let value = 0;
        props.list.map(node => {
          if(node.milestones_id == props.item.id) { value += node.complexity; }
        });
        return value;
      };

      let total = () => {
        let value = 0;
        props.list.map(node => {
          if(node.milestones_id == props.item.id) { value += 1; }
        });
        return value;
      };

      let completed = () => {
        let value = 0;
        props.list.map(node => {
          if(node.milestones_id == props.item.id && node.weight == "DONE") { value += 1; }
        });
        return value;
      };

      return {
        status,

        complexity,
        total,
        completed,

        drag: (event, id) => event.dataTransfer.setData("schema_node_id", id),
        drop: (event, id) => context.emit("move", id, event.dataTransfer.getData("schema_node_id")),
      };
    }
  };
</script>