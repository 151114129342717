<template>
  <div class="p-relative">
    <div
      class="d-flex p-relative justify-content-between align-items-center border-radius-12 box-shadow-bottom px-8 h-32px c-pointer"
      :class="{
        'base-neutral': !item.status,
        'base-highlight': item.status
      }"
      @click.stop="$emit('handler', item.id, !item.status)"
    >
      <div :class="icons()" />

      <div class="ml-8" :class="{ 'font-neutral': item.status }">
        {{ $t(name) }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      icon: { type: String, default: () => "" },
      name: { type: String, default: () => "" },
      item: { type: Object, default: () => {} }
    },

    emits: [
      "handler"
    ],

    setup(props) {
      return {
        icons: () => {
          return props.item.status ? "icon icon-" + props.icon + "-hover" : "icon icon-" + props.icon;
        }
      };
    }
  };
</script>