import Server from "../../../system/server/server";
import Entity from "../entities/Roster";

export default class Roster {
  static async load() {
    return new Entity(await Server.get("spaces"));
  }

  static async create(space) {
    return new Entity(await Server.post("spaces", space));
  }

  static async delete(id) {
    return new Entity(await Server.delete("spaces/" + id));
  }
}