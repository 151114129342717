<template>
  <layout-web>
    <partial-header :active="1" />

    <div v-if="source.node">
      <div class="m-10">
        {{ source.node.title }}
      </div>

      <hr>

      <div
        v-for="excuse in source.excuses"
        :key="excuse.id"
        class="d-flex flex-row justify-content-between m-10"
      >
        {{ excuse.title }}

        <div
          class="icon icon-plus mx-10 c-pointer"
          @click="add_capture(excuse.id)"
        />
      </div>
    </div>
  </layout-web>
</template>

<script>
  import {
    reactive,
    onMounted
  } from "vue";

  import LayoutWeb     from "../../../layouts/Web.vue";
  import PartialHeader from "./partials/Header.vue";

  import TrackerEntity   from "../entities/Tracker.js";
  import CapturesService from "../services/Captures.js";

  export default {
    components: {
      LayoutWeb,
      PartialHeader
    },

    setup() {
      const source = reactive(new TrackerEntity);

      onMounted(async () => Object.assign(source, await CapturesService.tracker()));
    
      return {
        source,

        add_capture: async (excuse_id) => {
          let response = await CapturesService.create({
            excuse_id: excuse_id
          });

          window.location.href = "/excuses/captures/" + response.capture.id;
        }
      };
    }
  };
</script>