import Server from "../../../system/server/server";
import Entity from "../entities/Roster.js";

export default class Roster {
  static async load() {
    return new Entity(await Server.get("schedule"));
  }

  static async search(keyword = "") {
    return new Entity(await Server.get("schedule?keyword=" + keyword));
  }

  static async finish(data) {
    return new Entity(await Server.post("schedule/finish", data));
  }

  static async move(data) {
    return new Entity(await Server.post("schedule/move", data));
  }

  static async states(project_id, option, value, keyword = "") {
    return new Entity(await Server.post("schedule/states", {
      id     : project_id,
      option : option,
      value  : value,
      keyword: keyword
    }));
  }

  static async priority(projects, keyword = "") {
    return new Entity(await Server.post("schedule/priority", {
      list   : projects,
      keyword: keyword
    }));
  }
}