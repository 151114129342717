import Server from "../../../system/server/server";
import Entity from "../entities/Roster";

export default class Detail {
  static async load() {
    return new Entity(await Server.get("people"));
  }

  static async create(team) {
    return new Entity(await Server.post("people", team));
  }

  static async delete(id) {
    return new Entity(await Server.delete("people/" + id));
  }

  static async states(project_id, option, value) {
    return new Entity(await Server.post("people/states", {
      id    : project_id,
      option: option,
      value : value
    }));
  }

  static async priority(projects) {
    return new Entity(await Server.post("people/priority", {
      list: projects
    }));
  }

}