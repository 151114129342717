<template>
  <div class="field w-100">
    <div v-if="label" class="font-soft font-memo mb-8">
      {{ label }}
    </div>

    <div
      class="ckeditor-wrapper"
      :class="{ failure: failure.length }"
    >
      <ckeditor
        v-model="editorData"
        :editor="editor"
        :config="editorConfig"
        @input="(content) => $emit('update:modelValue', content)"
      />
    </div>

    <div v-if="description" class="description font-memo">
      {{ description }}
    </div>

    <div v-if="failure" class="font-denial font-memo">
      {{ message }}
    </div>
  </div>
</template>

<script>
  import {
    ref,
    watch,
  } from "vue";

  import CKEditor      from "@ckeditor/ckeditor5-vue";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

  export default {
    components: {
      ckeditor: CKEditor.component
    },

    props: {
      // eslint-disable-next-line vue/prop-name-casing
      modelValue : { type: String , default: () => "" },
      label      : { type: String , default: () => "" },
      type       : { type: String , default: () => "" },
      placeholder: { type: String , default: () => "" },
      description: { type: String , default: () => "" },
      failure    : { type: Boolean, default: false    },
      message    : { type: String , default: () => "" }
    },

    emits: [
      "update:modelValue"
    ],

    setup(props) {
      let content = ref(props.modelValue);
      watch(() => props.modelValue, (value) => { content.value = value; });

      return {
        editor      : ClassicEditor,
        editorData  : content,
        editorConfig: { language: "ru" }
      };
    }
  };
</script>
