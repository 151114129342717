<template>
  <section class="seminars">
    <div id="seminars" class="container-fluid">
      <div class="title">
        {{ $t("seminars") }}
      </div>

      <div class="content">
        <div>{{ $t("seminars_common") }}</div>

        <div class="item d-none d-md-block">
          {{ $t("seminars_details_part_1") }}
        </div>
        <div class="item d-none d-md-block">
          {{ $t("seminars_details_part_2") }}
        </div>
      </div>

      <div id="products">
        <div class="product">
          <div class="caption"
            data-toggle="collapse"
            data-target="#seminar-logic"
            @click="toggle"
          >
            {{ $t("course_logics") }}
          </div>

          <div id="seminar-logic" class="details collapse" data-parent="#products">
            {{ $t("courses_skills") }}:<br><br>

            <li>{{ $t("course_logics_skills_1") }}</li>
            <li>{{ $t("course_logics_skills_2") }}</li>
            <li>{{ $t("course_logics_skills_3") }}</li>
            <li>{{ $t("course_logics_skills_4") }}</li>

            <div class="options">
              <div class="option">
                <div class="name">
                  {{ $t("courses_duration") }}
                </div>
                <div class="value">
                  {{ $t("course_logics_duration") }}
                </div>
              </div>

              <div class="option">
                <div class="name">
                  {{ $t("courses_format") }}
                </div>
                <div class="value">
                  {{ $t("courses_format_online") }}
                  <br>
                  {{ $t("courses_format_your_place") }}
                </div>
              </div>

              <div class="option">
                <div class="name">
                  <!-- {{ $t("courses.details") }} -->
                </div>
                <div class="value">
                  <!-- <a href="https://bewebdev.ru">bewebdev.ru</a> -->
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="product">
          <div class="caption"
            data-toggle="collapse"
            data-target="#product-webdev"
            @click="toggle"
          >
            {{ $t("course_basics") }}
          </div>

          <div id="product-webdev" class="details collapse" data-parent="#products">
            {{ $t("courses_skills") }}:<br><br>

            <li>{{ $t("course_basics_skills_1") }}</li>
            <li>{{ $t("course_basics_skills_2") }}</li>
            <li>{{ $t("course_basics_skills_3") }}</li>

            <div class="options">
              <div class="option">
                <div class="name">
                  {{ $t("courses_duration") }}
                </div>
                <div class="value">
                  {{ $t("course_basics_duration") }}
                </div>
              </div>

              <div class="option">
                <div class="name">
                  {{ $t("courses_format") }}
                </div>
                <div class="value">
                  {{ $t("courses_format_online") }}
                  <br>
                  {{ $t("courses_format_your_place") }}
                </div>
              </div>

              <div class="option">
                <div class="name">
                  <!--{{ $t("courses_details") }}-->
                </div>
                <div class="value">
                  <!-- <a href="https://bewebdev.ru">bewebdev.ru</a> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    setup() {
      return {
        toggle: (event) => event.target.closest(".product").classList.toggle("active")
      };
    }
  };
</script>

<style lang="scss">
  .landing {
    section.seminars {
      background-image: url("@/themes/light/images/landing/seminars-back.png");
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center center;

      .container-fluid {
        min-height: 580px;
        margin-top: 50px;
        padding-top: 70px;
      }

      .title {
        margin: 20px 0;
      }

      .content {
        margin: 30px 30px 50px 30px;

        .item {
          margin-top: 15px;
        }
      }

      .product {
        padding: 10px 30px;
        margin: 20px 30px 0px 30px;
        background-color: rgba(255, 255, 255, 0.9);

        .caption {
          display: flex;
          align-items: center;
          height: 80px;
          padding-right: 30px;
          cursor: pointer;
          color: #68788D;
          text-decoration: none;
          background: url("@/themes/light/images/landing/more.png") no-repeat right center;
        }

        .details {
          height: 0;
          visibility: hidden;
          opacity: 0;
          transition: all 0.2s linear;

          margin-left: 20px;
          padding-bottom: 20px;
          font-size: 112.5%;

          .options {
            display: flex;
            margin-top: 50px;

            .option {
              margin-right: 30px;

              .name {
                color: #C4C4C4;
                margin-bottom: 10px;
              }

              .value {
                color: #68788D;
                font-weight: bold;
              }
            }
          }
        }

        &.active {
          .caption {
            background: url("@/themes/light/images/landing/hide.png") no-repeat right center;
          }

          .details {
            height: 100%;
            visibility: visible;
            opacity: 1;
            transition: all 0.2s linear;
          }
        }
      }
    }

    @media (max-width: 576px) {
      section.seminars {
        padding-left: 10px;
        padding-right: 10px;

        .container-fluid {
          margin-top: 0px;
        }
      }
    }

    @media (min-width: 576px) {
      section.seminars {
        margin-top: -407px;
        padding-top: 260px;
        padding-left: 10px;
        padding-right: 10px;
        min-height: 894px;
        background-image:
          url("@/themes/light/images/landing/seminars-analytics-sm.png"),
          url("@/themes/light/images/landing/seminars-back.png")
        ;
        background-size: auto, auto;
        background-repeat: no-repeat;
        background-position: right top, center center;

        .product {
          .caption {
            font-size: 137.5%;
          }

          .details {
            .options {
              .option {
                margin-right: 80px;
              }
            }
          }
        }
      }

      section.services[data-service="1"] + section.seminars {
        background-image:
          url("@/themes/light/images/landing/seminars-architecture-sm.png"),
          url("@/themes/light/images/landing/seminars-back.png")
        ;
      }

      section.services[data-service="2"] + section.seminars {
        background-image:
          url("@/themes/light/images/landing/seminars-management-sm.png"),
          url("@/themes/light/images/landing/seminars-back.png")
        ;
      }

      section.services[data-service="3"] + section.seminars {
        background-image:
          url("@/themes/light/images/landing/seminars-innovations-sm.png"),
          url("@/themes/light/images/landing/seminars-back.png")
        ;
      }
    }

    @media (min-width: 768px) {
      section.seminars {
        margin-top: -407px;
        padding-top: 60px;
        background-image:
          url("@/themes/light/images/landing/seminars-analytics-sm.png"),
          url("@/themes/light/images/landing/seminars-back-left.png"),
          url("@/themes/light/images/landing/seminars-back-right.png"),
          url("@/themes/light/images/landing/seminars-back.png")
        ;
        background-size: auto, 30%, 30%, 100% auto;
        background-position: right top, left bottom, right bottom, center center;
      }

      section.services[data-service="1"] + section.seminars {
        background-image:
          url("@/themes/light/images/landing/seminars-architecture-sm.png"),
          url("@/themes/light/images/landing/seminars-back-left.png"),
          url("@/themes/light/images/landing/seminars-back-right.png"),
          url("@/themes/light/images/landing/seminars-back.png")
        ;
      }

      section.services[data-service="2"] + section.seminars {
        background-image:
          url("@/themes/light/images/landing/seminars-management-sm.png"),
          url("@/themes/light/images/landing/seminars-back-left.png"),
          url("@/themes/light/images/landing/seminars-back-right.png"),
          url("@/themes/light/images/landing/seminars-back.png")
        ;
      }

      section.services[data-service="3"] + section.seminars {
        background-image:
          url("@/themes/light/images/landing/seminars-innovations-sm.png"),
          url("@/themes/light/images/landing/seminars-back-left.png"),
          url("@/themes/light/images/landing/seminars-back-right.png"),
          url("@/themes/light/images/landing/seminars-back.png")
        ;
      }
    }

    @media (min-width: 992px) {
      section.seminars {
        margin-top: -588px;
        padding-top: 60px;
        background-image:
          url("@/themes/light/images/landing/seminars-analytics-lg.png"),
          url("@/themes/light/images/landing/seminars-back-left.png"),
          url("@/themes/light/images/landing/seminars-back-right.png"),
          url("@/themes/light/images/landing/seminars-back.png")
        ;
        background-size: auto, 50%, 50%, cover;
        background-position: right top, left bottom, right bottom, center center;

        .container-fluid {
          padding-left: 50px;
          padding-right: 50px;
        }

        .product {
          .details {
            .options {
              .option {
                margin-right: 120px;
              }
            }
          }
        }
      }

      section.services[data-service="1"] + section.seminars {
        background-image:
          url("@/themes/light/images/landing/seminars-architecture-lg.png"),
          url("@/themes/light/images/landing/seminars-back-left.png"),
          url("@/themes/light/images/landing/seminars-back-right.png"),
          url("@/themes/light/images/landing/seminars-back.png")
        ;
      }

      section.services[data-service="2"] + section.seminars {
        background-image:
          url("@/themes/light/images/landing/seminars-management-lg.png"),
          url("@/themes/light/images/landing/seminars-back-left.png"),
          url("@/themes/light/images/landing/seminars-back-right.png"),
          url("@/themes/light/images/landing/seminars-back.png")
        ;
      }

      section.services[data-service="3"] + section.seminars {
        background-image:
          url("@/themes/light/images/landing/seminars-innovations-lg.png"),
          url("@/themes/light/images/landing/seminars-back-left.png"),
          url("@/themes/light/images/landing/seminars-back-right.png"),
          url("@/themes/light/images/landing/seminars-back.png")
        ;
      }
    }

    @media (min-width: 1200px) {
      section.seminars {
        margin-top: -1284px;
        padding-top: 60px;
        background-image:
          url("@/themes/light/images/landing/seminars-analytics-xl.png"),
          url("@/themes/light/images/landing/seminars-back-left.png"),
          url("@/themes/light/images/landing/seminars-back-right.png"),
          url("@/themes/light/images/landing/seminars-back.png")
        ;
        background-size: auto, auto, auto, cover;
        background-position: right top, left bottom, right bottom, center center;
      }

      section.services[data-service="1"] + section.seminars {
        background-image:
          url("@/themes/light/images/landing/seminars-architecture-xl.png"),
          url("@/themes/light/images/landing/seminars-back-left.png"),
          url("@/themes/light/images/landing/seminars-back-right.png"),
          url("@/themes/light/images/landing/seminars-back.png")
        ;
      }

      section.services[data-service="2"] + section.seminars {
        background-image:
          url("@/themes/light/images/landing/seminars-management-xl.png"),
          url("@/themes/light/images/landing/seminars-back-left.png"),
          url("@/themes/light/images/landing/seminars-back-right.png"),
          url("@/themes/light/images/landing/seminars-back.png")
        ;
      }

      section.services[data-service="3"] + section.seminars {
        background-image:
          url("@/themes/light/images/landing/seminars-innovations-xl.png"),
          url("@/themes/light/images/landing/seminars-back-left.png"),
          url("@/themes/light/images/landing/seminars-back-right.png"),
          url("@/themes/light/images/landing/seminars-back.png")
        ;
      }
    }
  }
</style>