<template>
  <layout-web v-slot="slotProps" fullscreen>
    <partial-filter>
      <template #content>
        <widget-projects
          :projects="filter.projects"
          @activate="project_active"
          @priority="priority"
        />

        <div class="d-flex align-items-center">
          <field-search
            class="max-w-400px"
            :value="filter.search"
            @search="search"
          />

          <widget-filter
            class="ml-8"
            :projects="source.projects"
            @projects_handler="project_show"
          />
        </div>
      </template>
    </partial-filter>

    <partial-content :project="filter.active" :background="false">
      <template #content>
        <div class="d-flex flex-column w-100">
          <div class="d-flex flex-justify-content-end align-items-center flex-wrap base-neutral border-radius-8 mb-16 p-8">
            <widget-tabs
              :tabs="tabs"
              @activate="activate"
            />
          </div>

          <div v-if="tabs[0].active">
            <events-partial
              :nodes="source.events"
              @detail="redirect"
              @more="more"
            />
          </div>

          <div v-if="tabs[1].active">
            <div class="box-grid col-5">
              <widget-card-node-main
                v-for="node in source.nodes"
                :key="node.id"
                class="h-100 base-neutral"
                :item="node"
                :selected="popups.detail.id == node.id"
                :menu="['details', 'strucrure']"
                :partials="['code', 'description', 'complexity', 'deadline', 'menu', 'path', 'progress-stages']"
                @click="show_popups_detail({ id: node.id, code: node.code, header: '#' + node.code })"
              />
            </div>
          </div>
        </div>
      </template>
    </partial-content>

    <!-- Popup: Node details -->
    <popup-sidebar v-if="popups.detail">
      <template #header>
        <div
          class="font-title c-pointer"
          @click="redirect(popups.detail.code)"
        >
          {{ popups.detail.header }}
        </div>

        <div
          class="font-highlight lh-16 c-pointer"
          @click="hide_popups_detail"
        >
          {{ $t("close") }}
        </div>
      </template>

      <template #content>
        <node-plugin
          :node_id="popups.detail.id"
          :code_id="popups.detail.code"
          :user_id="slotProps.user.id"
        />
      </template>

      <template #footer />
    </popup-sidebar>
  </layout-web>
</template>

<script>
  import {
    reactive,
    onMounted,
    defineAsyncComponent
  } from "vue";

  import Mediator from "../../../system/mediator/Mediator";

  import LayoutWeb  from "../../../layouts/Web.vue";
  import WidgetTabs from "../../../visual/widgets/tabs/Tabs.vue";

  import PartialFilter  from "../../../visual/partials/Filter.vue";
  import PartialContent from "../../../visual/partials/Content.vue";

  import PopupSidebar from "../../../visual/popups/sidebar/Sidebar.vue";

  import FieldSearch from "../../../visual/fields/search/Search.vue";

  import WidgetFilter   from "../../../visual/widgets/filter/Filter.vue";
  import WidgetProjects from "../../../visual/widgets/projects/Projects.vue";

  import WidgetCardNodeMain from "../../../visual/widgets/cards/node/Main.vue";

  import EventsPartial from "./partials/Events.vue";

  import DetailEntity  from "../entities/Detail.js";
  import DetailService from "../services/Detail.js";

  export default {
    components: {
      LayoutWeb,
      WidgetTabs,
      PartialFilter,
      PartialContent,
      PopupSidebar,
      FieldSearch,
      WidgetFilter,
      WidgetProjects,
      WidgetCardNodeMain,
      EventsPartial,
      NodePlugin: defineAsyncComponent(() => import('../../node/plugins/roster/Roster.vue'))
    },

    setup() {
      const source = reactive(new DetailEntity);
      const popups = reactive({ detail: false });
      const filter = reactive({ offset: 0, search: "", projects: [], active: {} });

      const tabs = reactive([
        { id: 1, name: "Stream"     , active: true  },
        { id: 2, name: "Observation", active: false }
      ]);

      onMounted(async () => {
        Object.assign(source, await DetailService.load(filter.offset));
        refresh();

        Mediator.define("stream:reload", async () => {
          let result = await DetailService.load(filter.offset, filter.search);
          source.nodes = result.nodes;
          refresh();
        });
      });

      let refresh = () => {
        filter.projects = source.projects.filter(item => item.hidden != 1);
        filter.active   = filter.projects.find(item => item.hidden != 1 && item.active);
      };

      let activate = (tab) => {
        tabs.map((tab) => tab.active = false);
        tab.active = tab.id;
        hide_popups_detail();
      };

      let show_popups_detail = (data) => popups.detail = data;
      let hide_popups_detail = ()     => popups.detail = false;

      let redirect = (id) => window.location.href = "/node/" + id;

      return {
        source,
        popups,
        filter,
        tabs,

        show_popups_detail,
        hide_popups_detail,

        redirect,

        activate,

        project_active: async (project) => {
          filter.offset = 0;
          Object.assign(source, await DetailService.states(project.id, "project_active", project.id, filter.offset, filter.search));
          refresh();
        },

        project_show: async (project) => {
          filter.offset = 0;
          Object.assign(source, await DetailService.states(project.id, "project_show", project.status, filter.offset, filter.search));
          refresh();
        },

        priority: async (event) => {
          if (event.moved) {
            let result = await DetailService.priority(filter.projects.map(project => { return { id: project.id }; }), filter.offset, filter.search);
            source.projects = result.projects;
            refresh();
          }
        },

        search: async (keyword) => {
          filter.offset = 0;
          filter.search = keyword;
          Object.assign(source, await DetailService.load(filter.offset, keyword));
        },

        more: async () => {
          filter.offset++;
          let result = await DetailService.load(filter.offset, filter.search);
          source.events = source.events.concat(result.events);
        }
      };
    }
  };
</script>