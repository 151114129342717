<template>
  <div class="d-flex flex-column h-100 base-dark p-relative">
    <div
      class="d-flex c-pointer p-12 element"
      @click="redirect('/')"
    >
      <div class="icon logo-short c-pointer" />

      <div v-if="expand"
        class="font-header font-neutral text-uppercase lh-36 ml-16"
      >
        {{ $t("Lorida") }}
      </div>
    </div>

    <unit
      v-for="action in actions"
      :key="action.name"
      :expand="expand"
      :action="action"
      @redirect="redirect"
    />

    <!-- <div class="flex-grow" /> -->

    <!-- <setting
      :expand="expand"
      :value="popup.setting"
      @handler="setting"
    /> -->

    <prompt
      :expand="expand"
      :value="popup.prompt"
      @handler="prompt"
    />
  </div>
</template>

<script>
  import {
    reactive,
    computed
  } from "vue";
  
  import Unit    from "./toolbar/Unit.vue";
  // import Setting from "./toolbar/Setting.vue";
  import Prompt  from "./toolbar/Prompt.vue";

  import Toolbar from "./Toolbar.js";

  export default {
    components: {
      Unit,
      // Setting,
      Prompt
    },

    props: {
      items : { type: Array  , default: () => []    },
      expand: { type: Boolean, default: () => false }
    },

    setup (props) {
      const actions = computed(() => { return Toolbar.computed(props.items); });

      const popup = reactive({ setting: false, prompt: false });

      let reset_tutorial = () => {
        localStorage.removeItem("step");
        window.location.reload();
      };

      let redirect = (url) => document.location.href = url;

      // let setting = () => { popup.prompt  = false; popup.setting = !popup.setting; };
      let prompt  = () => { popup.setting = false; popup.prompt  = !popup.prompt;  };

      return { actions, popup,
        redirect,

        // setting,
        prompt,

        tutorial: async (id) => {
          await Toolbar.tutorial(id);
          reset_tutorial();
        }
      };
    }
  };
</script>