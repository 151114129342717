<template>
  <layout-web>
    <div class="p-8">
      <div class="d-flex base-neutral border-radius-8 mb-16 p-8">
        <div class="font-title">
          {{ $t("Organizations") }}
        </div>
      </div>

      <div class="base-neutral border-radius-8 p-8">
        <div class="box-grid">
          <widget-cards-simple
            v-for="item in source.spaces"
            :key="item.id"
            class="base-neutral"
            :item="item"
            :path="'/spaces/' + item.id"
            :menu="['details', 'remove']"
            :partials="['description', 'menu']"
            @remove="remove"
          />
        </div>

        <div class="d-flex">
          <div class="button confirm bottom" @click="show_popups_create">
            {{ $t("add") }}
          </div>
        </div>
      </div>
    </div>

    <!-- Popup: Create organization -->
    <popup-base v-if="popups.create">
      <template #header>
        <div>{{ $t("add_organization") }}</div>

        <div
          class="icon icon-close c-pointer"
          @click="hide_popups_create"
        />
      </template>

      <template #content>
        <field-input
          v-model="fields.name"
          :placeholder="$t('insert_name')"
          :message="$t('name_is_empty')"
          :failure="errors.name"
        />
      </template>

      <template #footer>
        <div class="button primary" @click="create">
          {{ $t("ok") }}
        </div>
      </template>
    </popup-base>
  </layout-web>
</template>

<script>
  import {
    reactive,
    onMounted
  } from "vue";

  import LayoutWeb         from "../../../layouts/Web.vue";
  import PopupBase         from "../../../visual/popups/base/Base.vue";
  import FieldInput        from "../../../visual/fields/input/Input.vue";
  import WidgetCardsSimple from "../../../visual/widgets/cards/simple/Simple.vue";

  import RosterEntity  from "../entities/Roster.js";
  import RosterService from "../services/Roster.js";

  export default {
    components: {
      LayoutWeb,
      PopupBase,
      FieldInput,
      WidgetCardsSimple
    },

    setup() {
      const source = reactive(new RosterEntity);
      const popups = reactive({ create: false });

      const fields = reactive({ name: ""    });
      const errors = reactive({ name: false });

      onMounted(async () => Object.assign(
        source,
        await RosterService.load()
      ));

      let show_popups_create = () => popups.create = true;
      let hide_popups_create = () => {
        popups.create = false;
        fields.name   = "";
      };

      let attach_errors = () => errors.name = fields.name == "" ? true : false;
      let remove_errors = () => errors.name = false;

      return {
        source,
        errors,
        fields,
        popups,

        show_popups_create,
        hide_popups_create,

        create: async () => {
          if (fields.name != "") {
            remove_errors();
            Object.assign(source, await RosterService.create(fields));
            hide_popups_create();
          }
          else {
            attach_errors();
          }
        },

        remove: async (id) => Object.assign(source, await RosterService.delete({ id: id }))
      };
    }
  };
</script>