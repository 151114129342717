<template>
  <div class="widget d-flex flex-column p-relative w-100">
    <div v-if="label" class="d-flex font-soft font-memo mb-8">
      {{ label }}
    </div>

    <div class="d-flex flex-wrap">
      <div v-for="worker in workers"
        :key="worker"
        class="margin-x-truncate ml-5 mr-5 mt-5 mb-5 flex-grow"
      >
        <div
          class="avatar avatar-card d-flex p-relative"
          @mouseenter="popups.hint = worker.route_id"
          @mouseleave="hide"
          @click="show_workers(worker)"
        >
          <div v-if="worker.worker_user_id"
            class="item base-soft w-100"
            :class="{
              'c-pointer': !worker.appointed,
              'base-selection': worker.state_status == 'history',
              'base-highlight': worker.state_status == 'current',
              'font-neutral': worker.state_status == 'history' || worker.state_status == 'current',
              'color-border-neutral': worker.state_status == 'history' || worker.state_status == 'current',
              'font-soft': worker.state_status != 'history' && worker.state_status != 'current',
              'border-1': worker.state_status != 'history' && worker.state_status != 'current'
            }"
          >
            <img v-if="worker.worker_user_avatar"
              class="user object-fit-cover"
              :src="url(worker.worker_user_id)"
            >

            <div v-if="!worker.worker_user_avatar"
              class="user"
            >
              {{ title(worker) }}
            </div>

            <div class="mr-2">
              {{ worker.route_stage }}
            </div>
          </div>

          <div v-else
            class="item base-soft font-soft border-1 w-100 c-pointer"
            @mouseenter="popups.hint = worker.route_id"
            @mouseleave="popups.hint = false"
          >
            <div class="user" />

            <div class="mr-2">
              {{ worker.route_stage }}
            </div>
          </div>

          <div v-if="popups.hint == worker.route_id && popups.worker.route_id != worker.route_id"
            class="p-absolute t-30px l-0 base-neutral border-radius-8 p-8 font-dark box-shadow-bottom z-105"
          >
            <div v-if="worker.worker_user_id">
              {{ worker.worker_user_name + " " + worker.worker_user_lastname }}
            </div>

            <div v-else>
              {{ $t('Performer_not_assigned') }}
            </div>

            <div v-if="worker.route_step"
              class="mt-8"
            >
              {{ worker.route_step }}
            </div>
          </div>

          <div v-if="popups.worker && popups.worker.route_id == worker.route_id && !popups.worker.appointed"
            class="avatar avatar-card flex-column p-absolute t-30px base-neutral border-radius-8 box-shadow-bottom overflow-y-scroll scrollbar p-8 z-106"
            @mouseleave="hide_workers"
          >
            <div v-if="popups.worker.users.length">
              <div v-for="user in popups.worker.users"
                :key="user.id"
                class="item c-pointer my-4"
                @click="submit(popups.worker.route_id, user.id)"
              >
                <img v-if="user.avatar"
                  class="user object-fit-cover"
                  :src="url(user.id)"
                >

                <div v-if="!user.avatar"
                  class="user base-highlight color-border-highlight font-neutral"
                >
                  {{ user.name.charAt(0) + user.lastname.charAt(0) }}
                </div>

                <div class="ml-8 mr-8">
                  {{ user.name + ' ' + user.lastname }}
                </div>
              </div>
            </div>

            <div v-else>
              {{ $t('No_suitable_performer') }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="description" class="description font-memo">
      {{ description }}
    </div>
  </div>
</template>

<script>
  import {
    ref,
    reactive
  } from "vue";

  import Config from "../../../../config.js";
  import Server from "../../../../system/server/server.js";

  export default {
    props: {
      label      : { type: String, default: () => "" },
      workers    : { type: Array , default: () => [] },
      description: { type: String, default: () => "" }
    },

    emits: [
      "submit"
    ],

    setup(props, context) {
      const popups = reactive({ worker: false, hint: false });

      const api = ref(Config.API);

      let hide = () => {
        popups.hint = false;
        popups.worker = false;
      };

      let hide_workers = () => popups.worker = false;
      let show_workers = (worker) => {
        popups.worker = worker;
        popups.hint   = false;
      };

      let title = (worker) => {
        return worker.worker_user_name.charAt(0) + worker.worker_user_lastname.charAt(0);
      };

      return {
        popups,

        hide,
        hide_workers,
        show_workers,
        title,

        url: (id) => api.value + '/api/profile/download?id=' + id + '&token=' + Server.token(),

        submit: (route_id, user_id) => {
          context.emit("submit", route_id, user_id);
          hide_workers();
        }
      };
    }
  };
</script>