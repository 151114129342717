import Server from "../../../system/server/server";
import Entity from "../entities/Detail";

export default class Detail {
  static async load(spaces_id, roles_id) {
    return new Entity(await Server.get("roles/" + roles_id + "?spaces_id=" + spaces_id));
  }

  static async change(roles_id, permission) {
    return new Entity(await Server.put("roles/" + roles_id, permission ));
  }
}