<template>
  <div class="p-fixed d-flex justify-content-between align-items-center z-107 px-8 w-100--76px h-52px base-neutral box-shadow">
    <slot name="content" />
  </div>
</template>

<script>
  export default {
    setup () { return {}; }
  };
</script>