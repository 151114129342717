import Server from "../../../system/server/server";
import Entity from "../entities/Roster.js";
import Cycle  from "../entities/Cycle.js";

export default class Roster {
  static async load() {
    return new Entity(await Server.get("calendar"));
  }

  static async states(project_id, option, value, keyword = "") {
    return new Entity(await Server.post("calendar/states", {
      id     : project_id,
      option : option,
      value  : value,
      keyword: keyword
    }));
  }

  static async priority(projects, keyword = "") {
    return new Entity(await Server.post("calendar/priority", {
      list   : projects,
      keyword: keyword
    }));
  }

  static async move(node) {
    return new Entity(await Server.post("calendar/move", {
      node: node
    }));
  }

  static async load_cycles(project_id) {
    return new Cycle(await Server.get("calendar/" + project_id + "/cycles"));
  }

  static async create_node(day, fields) {
    return new Entity(await Server.post("calendar/create", {
      day    : day,
      title  : fields.title,
      project: fields.project,
      cycle  : fields.cycle
    }));
  }

  static async update_node(day, node_id) {
    return new Entity(await Server.post("calendar/update", {
      day    : day,
      node_id: node_id
    }));
  }

  static async clear_node(node_id) {
    return new Entity(await Server.post("calendar/clear", {
      node_id: node_id
    }));
  }

  static async finish_node(data) {
    return new Entity(await Server.post("calendar/finish", data));
  }
}