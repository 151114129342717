<template>
  <div v-if="action.type == 'unit'"
    class="d-flex flex-column align-items-center hover-base-highlight c-pointer"
    :class="action.type"
    @click="$emit('redirect', action.path)"
  >
    <div class="d-flex align-items-center toolbar-box-shadow w-100--28px h-100 py-8 c-pointer">
      <div
        class="icon large my-8"
        :class="action.icon"
      />

      <div v-if="expand"
        class="font-neutral font-memo ml-8"
      >
        {{ $t(action.name) }}
      </div>
    </div>
  </div>

  <div v-else class="flex-grow" />
</template>

<script>
  export default {
    props: {
      action: { type: Object , default: () => {}    },
      expand: { type: Boolean, default: () => false }
    },

    emits: [
      "redirect"
    ]
  };
</script>