<template>
  <section class="contacts">
    <div id="contacts" class="section container-fluid">
      <div class="logo-long-hover" />
      <div class="email">
        {{ "info@lorida.ru" }}
      </div>

      <div class="">
        <div class="">
          <div class="phone">
            {{ "+7 961 334 5662" }}
          </div>
        </div>

        <div class="">
          <div class="right">
            &#9400; Lorida, 2023. {{ $t("copyright") }}
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  export default {
    setup() {
      return {

      };
    }
  };
</script>

<style lang="scss">
  .landing {
    section.contacts {
      color: #FFF;
      height: 220px;
      padding: 40px 15px;
      background: #3D4651;

      .email {
        margin-top: 40px;
        font-size: 112.5%;
      }

      .phone {
        margin-top: 20px;
        font-size: 112.5%;
      }

      .right {
        color: #E5E5E5;
        font-size: 75%;
        margin-top: 25px;
        text-align: right;
        line-height: 1.125;
      }
    }
  }
</style>