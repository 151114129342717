<template>
  <div class="d-flex w-100">
    <div v-if="project.branch" class="d-flex w-100">
      <div class="structure-list overflow-y-scroll scrollbar w-25 pr-16 pb-16 pt-10 mt--10">
        <div class="w-fit-content min-w-100">
          <widget-draganddrop-hierarchy
            :array="project.structure_show"
            @change="(target, source, offset) => $emit('structure', project.id, target, source, offset)"
          >
            <template #item="element">
              <item-partial
                :selected="element.inside"

                :project="project"
                :item="element.item"
                :cycles="cycles"
                :periods="periods"

                :bundle="bundle"
                @show_bundle="show_bundle"
                @hide_bundle="hide_bundle"

                @move_left="move_structure_left"
                @move_right="move_structure_right"
                @move_up="move_structure_up"
                @move_down="move_structure_down"
                @move_enter="move_structure_enter"

                @catch="(event, project, node) => $emit('dragdrop', event, project, node)"
                @remove="(node_id) => $emit('remove', node_id)"
                @onward="(data) => $emit('onward', data)"
                @period="(data) => $emit('period', data)"
                @insert="(source_id, target_id, action, project) => $emit('insert', source_id, target_id, action, project)"

                @structure_fold="(id, node) => $emit('structure_fold', id, node)"
                @structure_pick="(id, node) => $emit('structure_pick', id, node)"
                @create="create_bundle"

                @set_accesses="(id, type) => $emit('set_accesses', id, type)"
              />
            </template>
          </widget-draganddrop-hierarchy>
        </div>
      </div>

      <div
        id="tasks-content-nodes"
        class="w-75 base-neutral overflow-y-scroll scrollbar scroll-behavior-smooth"
      >
        <div class="p-fixed w-75--89px base-neutral p-8 z-106">
          <header-partial
            :project="project"
            :parent="parent"
            @handler="show_card"
          />
        </div>

        <div class="box-grid scrollbar col-4 p-8 pt-44">
          <widget-card-node-placeholder v-if="card"
            @close="hide_card"
            @create="create"
          />

          <div 
            v-for="(node, index) in nodes"
            :key="node.id"
            :data-id="node.id"
            :data-code="node.code"
          >
            <widget-card-node-main
              class="h-100 base-neutral"

              :data-offset="index + 1"

              :item="node"
              :menu="menu(node)"
              :partials="['code', 'icon', 'description', 'complexity', 'deadline', 'period-value', 'menu', node.progress[0].state_id ? 'period' : 'launch', 'path', 'progress-stages']"
              :cycles="cycles"
              :selected="selected == node.id"
              :periods="periods"

              draggable="true"
              @dragover.prevent
              @dragenter.prevent
              @dragleave.prevent
              @drop.stop="$emit('dragdrop', $event, project, node)"
              @dragstart.stop="$emit('dragstart', $event, project, node)"

              @keyup.left.exact="move_node(project.id, node, -1)"
              @keyup.right.exact="move_node(project.id, node, 1)"
              @keyup.up.exact="move_node(project.id, node, -4)"
              @keyup.down.exact="move_node(project.id, node, 4)"

              @keyup.enter.exact="$emit('detail', { id: node.id, code: node.code, header: '#' + node.code })"
              @keyup.esc.exact="hide_detail()"

              @accesses="(id, type) => $emit('set_accesses', id, type)"
              @onward="(data) => $emit('onward', data)"
              @period="(data) => $emit('period', data)"
              @insert="(source_id, target_id, action) => $emit('insert', source_id, target_id, action)"
              @click="$emit('detail', { id: node.id, code: node.code, header: '#' + node.code })"
            />
          </div>
        </div>
      </div>
    </div>

    <div v-else
      id="tasks-content-nodes"
      class="w-100 base-neutral overflow-y-scroll scrollbar"
    >
      <div class="p-fixed w-100--116px base-neutral p-8 z-106">
        <header-partial
          :root="true"
          :project="project"
          :parent="parent"
          @handler="show_card"
        />
      </div>

      <div class="box-grid col-5 p-8 pt-44">
        <widget-card-node-placeholder v-if="card"
          @close="hide_card"
          @create="create"
        />

        <div
          v-for="(node, index) in nodes"
          :key="node.id"
          :data-id="node.id"
          :data-code="node.code"
        >
          <widget-card-node-main
            class="h-100 base-neutral"

            :data-offset="index + 1"

            :item="node"
            :menu="menu(node)"
            :partials="['code', 'icon', 'description', 'complexity', 'deadline', 'period-value', 'menu', node.progress[0].state_id ? 'period' : 'launch', 'path', 'progress-stages']"
            :cycles="cycles.filter(cycle => cycle.scopes_entity_id == project.id)"
            :periods="periods"
            :selected="selected == node.id"

            draggable="true"
            @dragover.prevent
            @dragenter.prevent
            @dragleave.prevent
            @drop.stop="$emit('dragdrop', $event, project, node)"
            @dragstart.stop="$emit('dragstart', $event, project, node)"

            @keyup.left.exact="move_node(project.id, node, -1)"
            @keyup.right.exact="move_node(project.id, node, 1)"
            @keyup.up.exact="move_node(project.id, node, -4)"
            @keyup.down.exact="move_node(project.id, node, 4)"

            @keyup.enter.exact="$emit('detail', { id: node.id, code: node.code, header: '#' + node.code })"
            @keyup.esc.exact="hide_detail()"

            @accesses="(id, type) => $emit('set_accesses', id, type)"
            @onward="(data) => $emit('onward', data)"
            @period="(data) => $emit('period', data)"
            @insert="(source_id, target_id) => $emit('insert', source_id, target_id)"
            @click="$emit('detail', { id: node.id, code: node.code, header: '#' + node.code })"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";

  import HeaderPartial from "./Header.vue";
  import ItemPartial   from "./Item.vue";

  import WidgetCardNodeMain        from "../../../../visual/widgets/cards/node/Main.vue";
  import WidgetCardNodePlaceholder from "../../../../visual/widgets/cards/node/main/Placeholder.vue";

  import WidgetDraganddropHierarchy from "../../../../visual/widgets/draganddrop/Hierarchy.vue";

  export default {
    components: {
      HeaderPartial,
      ItemPartial,
      WidgetCardNodeMain,
      WidgetCardNodePlaceholder,
      WidgetDraganddropHierarchy
    },

    props: {
      project : { type: Object, default: () => {} },
      parent  : { type: Object, default: () => {} },
      nodes   : { type: Array , default: () => [] },
      cycles  : { type: Array , default: () => [] },
      periods : { type: Array , default: () => [] },
      selected: { type: Number, default: () => 0  }
    },

    emits: [
      "structure",
      "structure_fold",
      "structure_pick",
      "create",
      "create_bundle",
      "remove",
      "finish",
      "insert",
      "nodes_sort",
      "detail",
      "cancel",
      "dragstart",
      "dragdrop",
      "onward",
      "period",
      "set_accesses"
    ],

    setup(props, context) {
      const bundle = ref(false);
      const card   = ref(false);
      const root   = ref(null);

      let show_bundle = async (node) => {
        hide_card();

        if (!props.project.structure_fold.includes(node.id)) {
          await context.emit('structure_fold', props.project.id, node);
        }

        bundle.value = node.id;
      };

      let hide_bundle = () => {
        bundle.value = false;
      };

      let show_card = (id) => {
        hide_bundle();

        card.value = true;
        root.value = id;

        let wrapper = document.getElementById("tasks-content-nodes");
        wrapper.scrollTop = 0;
      };

      let hide_card = () => {
        card.value = false;
        root.value = 0;
      };

      let hide_detail = () => {
        context.emit("cancel");
      };

      let move_structure_left = (id, node) => {
        if(node.folded_access && node.folded_status) { context.emit('structure_fold', id, node); }
      };

      let move_structure_right = (id, node) => {
        if(node.folded_access && !node.folded_status) { context.emit('structure_fold', id, node); }
      };

      let move_structure_up = (id, node) => {
        let element = document.querySelector("[data-id='" + node.id + "']").previousSibling;
        move_structure(element);
      };

      let move_structure_down = (id, node) => {
        let element = document.querySelector("[data-id='" + (node.id || 0) + "']").nextSibling;
        move_structure(element);
      };

      let move_structure_enter = (id, node) => {
        context.emit('structure_pick', id, node, true);
      };

      let move_structure = (element) => {
        let item = element && element.childNodes.length && element.getElementsByClassName("structure-item")[0];
        if (item) {
          item.focus();
          let structure = document.getElementsByClassName("structure-list")[0];
          structure.scrollLeft = item.dataset.level * 34;
        }
      };

      let move_node = (id, node, column) => {
        let element = document.querySelector("[data-id='" + node.id + "']");
        let offset = element.childNodes.length && element.childNodes[0].dataset.offset;
        if (offset) {
          let item = document.querySelector("[data-offset='" + (+offset + column) + "']");
          if (item) {
            item.focus();
            context.emit('detail', { id: item.parentElement.dataset.id, code: item.parentElement.dataset.code, header: '#' + item.parentElement.dataset.code });
          }
        }
      };

      return {
        bundle,
        card,

        show_bundle,
        hide_bundle,
        show_card,
        hide_card,
        hide_detail,

        move_structure_left,
        move_structure_right,
        move_structure_up,
        move_structure_down,
        move_structure_enter,

        move_node,

        menu: (node) => {
          let items = [ 'details', 'strucrure', 'copy', 'cut', 'insert', 'limited', 'shared' ];

          if (node.progress[0].state_id) {
            if (node.progress.find(stage => stage.state_status == "current")) {
              items.push({ title: 'abort', handler: () => context.emit('finish', node.id) });
            }
          }
          else {
            items.push({ title: 'remove', handler: () => context.emit('remove', node.id) });
          }
        
          return items;
        },

        create: async (title) => {
          context.emit("create", props.project.id, root.value, title);
        },

        create_bundle: async (title) => {
          context.emit("create_bundle", props.project.id, bundle.value, title);
        }
      };
    }
  };
</script>