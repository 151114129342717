import Excuses               from './screens/Excuses.vue';
import ExcusesDetail         from './screens/excuses/Detail.vue';
import ExcusesProgress       from './screens/Progress.vue';
import ExcusesTracker        from './screens/Tracker.vue';
import ExcusesCaptures       from './screens/Captures.vue';
import ExcusesCapturesDetail from './screens/captures/Detail.vue';

export default [
  {
    path: "/excuses",
    name: "Excuses",
    component: Excuses
  },
  {
    path: "/excuses/:id",
    name: "ExcusesDetail",
    component: ExcusesDetail,
    props: true
  },
  {
    path: "/excuses/progress",
    name: "ExcusesProgress",
    component: ExcusesProgress
  },
  {
    path: "/excuses/tracker",
    name: "ExcusesTracker",
    component: ExcusesTracker
  },
  {
    path: "/excuses/captures",
    name: "ExcusesCaptures",
    component: ExcusesCaptures
  },
  {
    path: "/excuses/captures/:id",
    name: "ExcusesCapturesDetail",
    component: ExcusesCapturesDetail,
    props: true
  }
];