import Server from "../../../system/server/server";
import Entity from "../entities/Roster";

export default class Roster {
  static async load() {
    return new Entity(await Server.get("profile"));
  }

  static async update(profile) {
    return new Entity(await Server.put("profile", profile));
  }

  static async upload(data) {
    return new Entity(await Server.upload("profile/upload", data));
  }
}