<template>
  <div class="roadmap card-wrapper box-shadow-card">
    <div class="header p-8 c-pointer border-bottom-1 border-color-transparent">
      <div class="d-flex w-100">
        <div class="w-100">
          <field-autocomplete
            v-model="item.name"
            :autofocus="true"
            :creatable="true"
            :items="list.items"
            :target="item.id"
            :placeholder="$t('create_or_choose_node')"
            @handle_search="handle_search"
            @handle_choose="handle_choose"
          />
        </div>
      </div>
    </div>

    <div class="content p-8 pt-0">
      <div class="d-flex">
        <cards-partial-diagram-period-simple v-if="partials.includes('progress-period-simple')"
          :start="item.start"
          :finish="item.finish"
          :shipped="item.shipped"
          :selected="selected"
        />

        <cards-partial-diagram-completed v-if="partials.includes('progress-completed')"
          :tasks="item.tasks"
          :complete="item.complete"
          :selected="selected"
        />

        <cards-partial-diagram-estimate v-if="partials.includes('progress-estimate')"
          :actually="item.complexity_actually"
          :complexity="item.complexity"
          :selected="selected"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import moment from "moment";

  import {
    reactive
  } from "vue";

  import CardsPartialDiagramPeriodSimple from "../../partials/diagram/period/Simple.vue";
  import CardsPartialDiagramCompleted    from "../../partials/diagram/Completed.vue";
  import CardsPartialDiagramEstimate     from "../../partials/diagram/Estimate.vue";

  import FieldAutocomplete from "../../../../fields/autocomplete/Autocomplete.vue";

  export default {
    components: {
      CardsPartialDiagramPeriodSimple,
      CardsPartialDiagramCompleted,
      CardsPartialDiagramEstimate,
      FieldAutocomplete
    },

    props: {
      items   : { type: Array  , default: () => [] },
      partials: { type: Array  , default: () => [] }
    },

    emits: [
      "create"
    ],

    setup(props, context) {
      const item = reactive({ id: 0, name: "", start: moment(), finish: moment(), shipped: "" });
      const list = reactive({ items: [] });

      let clear = () => {
        item.id   = 0;
        item.name = "";
      };

      return {
        item,
        list,

        handle_search: (word) => {
          item.name = word;
          list.items = props.items.filter(item => item.title.toLowerCase().includes(word.toLowerCase()))
            .map(row => { return { id: row.id, name: row.title }; })
          ;
        },

        handle_choose: (data) => {
          context.emit("create", data);
          clear();
        }
      };
    }
  };
</script>