export default {
  ru: {
    Roadmap              : "Дорожная карта",
    break_sprints        : "Разбить на спринты",
    duration_sprint      : "Укажите длительность спринта в днях",
    create_or_choose_node: "Создать или выбрать этап",
    add_sprint           : "Добавить спринт",
  },
  en: {
    Roadmap              : "Roadmap",
    break_sprints        : "Break it down into sprints",
    duration_sprint      : "Specify the duration of the sprint in days",
    create_or_choose_node: "Create or choose node",
    add_sprint           : "Add sprint",
  }
};