<template>
  <layout-web v-slot="slotProps" fullscreen>
    <partial-filter>
      <template #content>
        <widget-projects
          :projects="filter.projects"
          @activate="project_active"
          @priority="priority"
        />

        <div class="d-flex align-items-center">
          <field-search
            class="max-w-400px"
            :value="filter.search"
            @search="search"
          />

          <widget-filter
            class="ml-8"
            :projects="source.projects"
            @projects_handler="project_show"
          />
        </div>
      </template>
    </partial-filter>

    <partial-content :project="filter.active" :complex="true" :background="false">
      <template #content>
        <div class="d-flex h-100 flex-grow box-grid col-6">
          <div v-for="period in source.periods"
            :key="period.id"
            class="h-100 overflow-y-hidden"
          >
            <periods-partial
              :nodes="get_periods(period.id)"
              :period="period"
              :selected="popups.detail.id"
              @detail="show_popups_detail"
              @emit-dragdrop="dragdrop"
              @emit-dragstart="dragstart"
              @emit-dragend="dragend"
              @emit-dragenter="dragenter"
              @emit-dragleave="dragleave"
              @emit-finish="finish"
            />
          </div>

          <div class="h-100 overflow-y-hidden">
            <assigne-partial
              :nodes="get_assigne()"
              :selected="popups.detail.id"
              @detail="show_popups_detail"
              @emit-dragdrop="dragdrop"
              @emit-dragstart="dragstart"
              @emit-dragend="dragend"
              @emit-dragenter="dragenter"
              @emit-dragleave="dragleave"
              @emit-finish="finish"
            />
          </div>

          <div class="h-100 overflow-y-hidden">
            <backlog-partial
              :nodes="get_backlog()"
              :selected="popups.detail.id"
              @detail="show_popups_detail"
              @emit-dragdrop="dragdrop"
              @emit-dragstart="dragstart"
              @emit-dragend="dragend"
              @emit-finish="finish"
            />
          </div>
        </div>
      </template>
    </partial-content>

    <!-- Popup: Node details -->
    <popup-sidebar v-if="popups.detail">
      <template #header>
        <div
          class="font-title c-pointer"
          @click="redirect(popups.detail.code)"
        >
          {{ popups.detail.header }}
        </div>

        <div
          class="font-highlight lh-16 c-pointer"
          @click="hide_popups_detail"
        >
          {{ $t("close") }}
        </div>
      </template>

      <template #content>
        <node-plugin
          :node_id="popups.detail.id"
          :code_id="popups.detail.code"
          :user_id="slotProps.user.id"
        />
      </template>

      <template #footer />
    </popup-sidebar>
  </layout-web>
</template>

<script>
  import {
    reactive,
    onMounted,
    defineAsyncComponent
  } from "vue";

  import Mediator from "../../../system/mediator/Mediator";

  import LayoutWeb from "../../../layouts/Web.vue";

  import PartialFilter  from "../../../visual/partials/Filter.vue";
  import PartialContent from "../../../visual/partials/Content.vue";

  import PopupSidebar from "../../../visual/popups/sidebar/Sidebar.vue";

  import BacklogPartial from "./partials/Backlog.vue";
  import AssignePartial from "./partials/Assigne.vue";
  import PeriodsPartial from "./partials/Periods.vue";

  import FieldSearch from "../../../visual/fields/search/Search.vue";

  import WidgetFilter   from "../../../visual/widgets/filter/Filter.vue";
  import WidgetProjects from "../../../visual/widgets/projects/Projects.vue";

  import RosterEntity  from "../entities/Roster.js";
  import RosterService from "../services/Roster.js";

  export default {
    components: {
      LayoutWeb,
      PartialFilter,
      PartialContent,
      PopupSidebar,
      BacklogPartial,
      AssignePartial,
      PeriodsPartial,
      FieldSearch,
      WidgetFilter,
      WidgetProjects,
      NodePlugin: defineAsyncComponent(() => import('../../node/plugins/roster/Roster.vue'))
    },

    setup() {
      const source = reactive(new RosterEntity);
      const popups = reactive({ detail: false });
      const filter = reactive({ search: "", projects: [], active: {} });

      onMounted(async () => {
        Object.assign(source, await RosterService.load());
        refresh();

        Mediator.define("schedule:reload", async () => {
          Object.assign(source, await RosterService.load());
          refresh();
        });
      });

      let refresh = () => {
        filter.projects = source.projects.filter(item => item.hidden != 1);
        filter.active   = filter.projects.find(item => item.hidden != 1 && item.active);
      };

      let get_periods = (id) => {
        let nodes = [];
        source.nodes
          .filter(node => { return node.periods_id == id; })
          .forEach((node, index, array) => {
            if (!node.type) { nodes.push({ blank: node.id, priority: (node.priority - 0.5) }); }
            nodes.push(node);
            if (!node.type && index == (array.length - 1)) { nodes.push({ blank: node.id, priority: (node.priority + 0.5) }); }
          });
        return nodes;
      };

      let get_assigne = () => {
        let nodes = [];
        source.nodes
          .filter(node => { return node.worker_users_id && !node.periods_id; })
          .forEach((node, index, array) => {
            if (!node.type) { nodes.push({ blank: node.id, priority: (node.priority - 0.5) }); }
            nodes.push(node);
            if (!node.type && index == (array.length - 1)) { nodes.push({ blank: node.id, priority: (node.priority + 0.5) }); }
          });
        return nodes;
      };

      let get_backlog = () => {
        let nodes = [];
        source.nodes
          .filter(node => { return !node.worker_users_id; })
          .forEach((node, index, array) => {
            if (!node.type) { nodes.push({ blank: node.id, priority: (node.priority - 0.5) }); }
            nodes.push(node);
            if (!node.type && index == (array.length - 1)) { nodes.push({ blank: node.id, priority: (node.priority + 0.5) }); }
          });
        return nodes;
      };

      let dragenter = (event) => {
        if (event.target.classList.contains("blank")) {
          event.target.classList.remove("h-10px");
          event.target.classList.add("h-100px");
          event.target.classList.add("base-striped");
          event.target.classList.add("border-1");
          event.target.classList.add("border-radius-5");
          event.target.classList.add("color-border-light");
        }
      };

      let dragleave = (event) => {
        if (event.target.classList.contains("blank")) {
          event.target.classList.add("h-10px");
          event.target.classList.remove("h-100px");
          event.target.classList.remove("base-striped");
          event.target.classList.remove("border-1");
          event.target.classList.remove("border-radius-5");
          event.target.classList.remove("color-border-light");
        }
      };

      let dragstart = (event, node, source) => {
        event.target.closest(".card").classList.add("opacity-40");

        event.dataTransfer.dropEffect    = "move";
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.setData("source"         , source               );
        event.dataTransfer.setData("role_state_id"  , node.role_states_id  );
        event.dataTransfer.setData("status_state_id", node.status_states_id);
      };

      let dragend = (event) => {
        event.target.closest(".card").classList.remove("opacity-40");
      };

      let show_popups_detail = (data) => popups.detail = data;
      let hide_popups_detail = ()     => popups.detail = false;

      let redirect = (id) => window.location.href = "/node/" + id;

      return {
        source,
        popups,
        filter,

        show_popups_detail,
        hide_popups_detail,

        redirect,

        project_active: async (project) => {
          Object.assign(source, await RosterService.states(project.id, "project_active", project.id, filter.search));
          refresh();
        },

        project_show: async (project) => {
          Object.assign(source, await RosterService.states(project.id, "project_show", project.status, filter.search));
          refresh();
        },

        priority: async (event) => {
          if (event.moved) {
            Object.assign(source, await RosterService.priority(filter.projects.map(project => { return { id: project.id }; }), filter.search));
            refresh();
          }
        },

        search: async (keyword) => {
          filter.search = keyword;
          Object.assign(source, await RosterService.search(keyword));
          refresh();
        },

        get_periods,
        get_assigne,
        get_backlog,

        dragenter,
        dragleave,
        dragstart,
        dragend,

        finish: async (data) => {
          Object.assign(source, await RosterService.finish(data));
          Mediator.handle("tracker:reload");
        },

        dragdrop: async (event, target, node) => {
          dragleave(event);

          if (event.dataTransfer.getData("source")) {
            Object.assign(source, await RosterService.move({
              target         : target,
              source         : event.dataTransfer.getData("source"),
              role_state_id  : event.dataTransfer.getData("role_state_id"),
              status_state_id: event.dataTransfer.getData("status_state_id"),
              priority       : node ? node.priority : 0,
              keyword        : filter.search
            }));
  
            Mediator.handle("tracker:reload");
          }
        }
      };
    }
  };
</script>