import Server  from "../../../system/server/server";
import Entity  from "../entities/Captures";
import Tracker from "../entities/Tracker";
import Detail  from "../entities/Detail";

export default class Captures {
  static async load() {
    return new Entity(await Server.get("excuses/captures"));
  }
  static async item(id) {
    return new Detail(await Server.get("excuses/captures/" + id));
  }

  static async create(capture) {
    return new Detail(await Server.post("excuses/captures", capture));
  }

  static async update(capture) {
    return new Detail(await Server.put("excuses/captures/" + capture.id, capture));
  }

  static async delete(capture) {
    return new Entity(await Server.delete("excuses/captures/" + capture.id));
  }

  static async tracker() {
    return new Tracker(await Server.get("excuses/tracker"));
  }
}