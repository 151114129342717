<template>
  <div class="charts-mindmap-picker">
    <div class="charts-picker charts-plugin">
      <div class="mb-32">
        <div class="font-soft font-memo mb-8">
          {{ $t("border_color") }}
        </div>

        <div class="d-flex justify-content-between">
          <div class="d-flex flex-wrap overflow-hidden" :class="{ 'h-auto': options.border, 'h-30px': !options.border }">
            <div v-for="color in colors" :key="color" class="border-1 border-color-transparent border-radius-5 font-dark c-pointer hover-neutral">
              <div
                class="kernel text-center border-radius-5"
                :style="{ 'background-color': color }"
                @click="$emit('handler', { property:'borderColor', value: color })"
              />
            </div>
          </div>

          <div class="p-5 text-center border-radius-5 c-pointer hover-neutral" @click="options.border = !options.border">
            ...
          </div>
        </div>
      </div>

      <div class="mb-32">
        <div class="font-soft font-memo mb-8">
          {{ $t("font_size") }}
        </div>

        <div class="d-flex justify-content-between">
          <div class="d-flex flex-wrap overflow-hidden" :class="{ 'h-auto': options.size, 'h-30px': !options.size }">
            <div v-for="size in sizes" :key="size" class="border-1 border-color-transparent border-radius-5 font-dark c-pointer hover-neutral">
              <div class="kernel text-center border-radius-5" @click="$emit('handler', { property:'size', value: size })">
                {{ size }}
              </div>
            </div>
          </div>

          <!-- <div class="p-5 text-center border-radius-5 c-pointer hover-neutral" @click="options.size = !options.size">
            ...
          </div> -->
        </div>
      </div>

      <div class="mb-32">
        <div class="font-soft font-memo mb-8">
          {{ $t("font_style") }}
        </div>

        <div class="d-flex justify-content-between">
          <div class="d-flex flex-wrap overflow-hidden" :class="{ 'h-auto': options.style, 'h-30px': !options.style }">
            <div v-for="item in style" :key="item" class="border-1 border-color-transparent border-radius-5 font-dark c-pointer hover-neutral">
              <!-- eslint-disable-next-line vue/no-v-html -->
              <div class="kernel text-center border-radius-5" @click="$emit('handler', { property: item.property, value: item.value })" v-html="item.button" />
            </div>
          </div>

          <!-- <div class="p-5 text-center border-radius-5 c-pointer hover-neutral" @click="options.style = !options.style">
            ...
          </div> -->
        </div>
      </div>

      <div class="mb-32">
        <div class="font-soft font-memo mb-8">
          {{ $t("font_color") }}
        </div>

        <div class="d-flex justify-content-between">
          <div class="d-flex flex-wrap overflow-hidden" :class="{ 'h-auto': options.color, 'h-30px': !options.color }">
            <div v-for="color in colors" :key="color" class="border-1 border-color-transparent border-radius-5 font-dark c-pointer hover-neutral">
              <div
                class="kernel text-center border-radius-5"
                :style="{ 'background-color': color }"
                @click="$emit('handler', { property:'color', value: color })"
              />
            </div>
          </div>

          <div class="p-5 text-center border-radius-5 c-pointer hover-neutral" @click="options.color = !options.color">
            ...
          </div>
        </div>
      </div>

      <div class="mb-32">
        <div class="font-soft font-memo mb-8">
          {{ $t("icons") }}
        </div>

        <div class="d-flex justify-content-between">
          <div class="d-flex flex-wrap overflow-hidden" :class="{ 'h-auto': options.icon, 'h-30px': !options.icon }">
            <div v-for="icon in icons" :key="icon" class="border-1 border-color-transparent border-radius-5 font-dark c-pointer hover-neutral">
              <div
                class="kernel icon medium border-radius-5"
                :class="'picker-' + icon"
                @click="$emit('handler', { property: 'icon', value: icon })"
              />
            </div>
          </div>

          <!-- <div class="p-5 text-center border-radius-5 c-pointer hover-neutral" @click="options.icon = !options.icon">
            ...
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    reactive
  } from "vue";

  export default {
    emits: [
      "handler"
    ],

    setup() {
      const options = reactive({
        border: false,
        style : false,
        color : false,
        size  : false,
        icon  : false
      });

      const colors = reactive([
        "black", "gray", "silver", "red", "maroon", "blue", "green", "yellow", "olive", "lime", "aqua", "teal",
        "navy", "orange", "brown", "indigo", "magenta", "purple", "lavender", "thistle", "plum", "violet", "orchid", "fuchsia",
        "mediumorchid", "mediumpurple", "blueviolet", "darkviolet", "darkorchid", "darkmagenta", "darkslateblue", "slateblue",
        "mediumslateblue", "darkgray", "dimgray", "lightslategray", "slategray", "darkslategray", "darkolivegreen", "olivedrab",
        "yellowgreen", "limegreen", "lawngreen", "chartreuse", "greenyellow", "springgreen", "mediumspringgreen", "lightgreen",
        "palegreen", "darkseagreen", "mediumaquamarine", "mediumseagreen", "seagreen", "forestgreen", "darkgreen", "cyan",
        "lightcyan", "paleturquoise", "aquamarine", "turquoise", "mediumturquoise", "darkturquoise", "lightseagreen", "cadetblue",
        "darkcyan", "lightsteelblue", "powderblue", "lightblue", "skyblue", "lightskyblue", "deepskyblue", "dodgerblue",
        "cornflowerblue", "steelblue", "royalblue", "mediumblue", "darkblue", "midnightblue", "pink", "lightpink", "hotpink",
        "deeppink", "palevioletred", "mediumvioletred", "lightsalmon", "salmon", "darksalmon", "lightcoral", "indianred",
        "crimson", "firebrick", "darkred", "orangered", "tomato", "coral", "darkorange", "lightyellow", "lemonchiffon",
        "lightgoldenrodyellow", "papayawhip", "moccasin", "peachpuff", "palegoldenrod", "khaki", "darkkhaki", "gold", "cornsilk",
        "blanchedalmond", "bisque", "navajowhite", "wheat", "burlywood", "tan", "rosybrown", "sandybrown", "goldenrod",
        "darkgoldenrod", "peru", "chocolate", "saddlebrown", "sienna"
      ]);

      const icons = reactive([
        "question", "close"
      ]);

      const style = reactive([
        { button: "<b>B</b>", value: "bold"        , property: "fontWeight"     },
        { button: "<i>I</i>", value: "italic"      , property: "fontStyle"      },
        { button: "<u>U</u>", value: "underline"   , property: "textDecoration" },
        { button: "<s>S</s>", value: "line-through", property: "textDecoration" }
      ]);

      const sizes = reactive([
        "10", "12", "14", "16", "18", "20", "24", "32"
      ]);

      return {
        options,
        colors,
        icons,
        style,
        sizes,
      };
    }
  };
</script>

<style scoped lang="scss">
  .charts-picker {
    .kernel {
      width: 24px;
      height: 24px;
      line-height: 24px;
      margin: 2px;
      background-size: cover;
    }
  }
</style>