<template>
  <layout-web>
    <partial-header :active="0" />

    <h3>Суммарное количество оправданий по неделям</h3>

    <Bar
      :chart-id="options.chartId"
      :dataset-id-key="options.datasetIdKey"
      :chart-data="dataset_total"
      :chart-options="options.chartOptions"

      :width="options.width"
      :height="options.height"
      :css-classes="options.cssClasses"

      :styles="options.styles"
      :plugins="options.plugins"
    />

    <hr>

    <h3>Количество оправданий по неделям</h3>

    <Bar
      :chart-id="options.chartId"
      :dataset-id-key="options.datasetIdKey"
      :chart-data="dataset_detail"
      :chart-options="options.chartOptions"

      :width="options.width"
      :height="options.height"
      :css-classes="options.cssClasses"

      :styles="options.styles"
      :plugins="options.plugins"
    />
  </layout-web>
</template>

<script>
  import {
    ref,
    reactive,
    onMounted
  } from "vue";

  import { Bar } from "vue-chartjs";
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from "chart.js";

  import LayoutWeb     from "../../../layouts/Web.vue";
  import PartialHeader from "./partials/Header.vue";

  import ProgressEntity from "../entities/Progress.js";
  import ExcusesService from "../services/Excuses.js";

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

  export default {
    components: {
      LayoutWeb,
      PartialHeader,
      Bar
    },

    setup() {
      const source = reactive(new ProgressEntity);

      const dataset_detail = ref({ labels: [], datasets: [] });
      const dataset_total  = ref({ labels: [], datasets: [] });

      const options = ref({
        chartId     : "bar-chart",
        datasetIdKey: "label",
        width       : 201,
        height      : 101,
        cssClasses  : "",
        styles      : {},
        plugins     : [],
        chartOptions: {
          responsive: true
        }
      });

      onMounted(async () => {
        Object.assign(source, await ExcusesService.progress());

        dataset_detail.value.labels = Object.values(source.labels);

        Object.keys(source.datasets).forEach(category => {
          dataset_detail.value.datasets.push({
            label          : category,
            backgroundColor: source.datasets[category].color || "green",
            data           : Object.values(source.datasets[category]),
          });
        });

        dataset_total.value.labels = Object.values(source.labels);
        dataset_total.value.datasets = [
          {
            label          : "Общее",
            backgroundColor: '#0288D1',
            data           : Object.values(source.total)
          }
        ];
      });

      return {
        source,
        options,
        dataset_detail,
        dataset_total
      };
    }
  };
</script>