<template>
  <div class="d-flex">
    <div class="icon mr-4" :class="{ 'icon-work': !selected, 'icon-work-hover': selected }" />
    <div :class="{ 'font-dark': !selected, 'font-neutral': selected }">
      {{ count }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      count   : { type: Number , default: () => 0     },
      selected: { type: Boolean, default: () => false }
    }
  };
</script>