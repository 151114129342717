<template>
  <div class="id text-right pl-8" :class="{ 'font-dark': !selected, 'font-neutral': selected }">
    #{{ code }}
  </div>
</template>

<script>
  export default {
    props: {
      code    : { type: String , default: () => ""    },
      selected: { type: Boolean, default: () => false }
    }
  };
</script>