import moment from "moment";
moment.locale("ru");

export default class Node {
  constructor(data = {}) {
    this.id        = data.id                                || 0;
    this.code      = data.code                              || 0;
    this.title     = data.title                             || "Нет активной задачи"; // "No active task";
    this.period_id = data.period_id                         || 1;
    this.type      = data.type                              || "";
    this.deadline  = moment(data.deadline).format("D MMMM") || "";
    this.time      = moment(data.deadline).format("HH:mm")  || "";

    this.path = data.projects_name;
    if (data.path && data.path.length != 0) {
      this.path += " > " + Object.values(data.path).join(" > "); // this.path = data.path.join(" > ");
    }

    this.started = +data.timing_id;

    this.spent = "00:00";
    if (data.timing_duration) {
      this.spent = format_time(data.timing_duration);
    }

    this.estimate = "00:00";
    if (data.estimate) {
      this.estimate = format_time(data.estimate);
    }
  }
}

function format_time(time) {
  let hour   = parseInt(time / PER_HOUR);
  time       = time - (hour * PER_HOUR);
  let minute = parseInt(time / PER_MINUTE);

  return format_time_value(hour) + ":" + format_time_value(minute);
}

function format_time_value(value) {
  return String(value).length < 2 && ('0' + String(value)) || value;
}

const PER_HOUR   = 3600;
const PER_MINUTE = 60;