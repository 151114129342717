
export default class Bundle {
  constructor() {
    this.link = {
      entity: {
        created        : null,
        id             : 0,
        nodes_root_id  : 0,
        nodes_source_id: 0,
        nodes_target_id: 0,
        type           : 1
      },
      option: {
        id            : 0,
        nodes_links_id: 0,
        style         : null
      }
    };

    this.node = {
      entity: {
        target         : 0,
        type           : "child",
        categories_id  : null,
        complexity     : null,
        created        : null,
        cycles_id      : null,
        deadline       : null,
        description    : null,
        id             : 0,
        projects_id    : 0,
        releases_id    : null,
        state_id       : null,
        state_routes_id: null,
        title          : "",
        users_id       : 0
      },
      option: {
        folded  : 0,
        id      : 0,
        level   : 0,
        nodes_id: 0,
        offset  : 0,
        style   : null
      }
    };
  }
}