<template>
  <div class="field w-100">
    <div v-if="label" class="font-soft font-memo mb-8">
      {{ label }}
    </div>

    <select
      :class="{ failure: failure }"
      :placeholder="placeholder"
      :value="modelValue"
      @change="$emit('update:modelValue', $event.target.value)"
    >
      <option value="" disabled :selected="!selected">
        {{ placeholder }}
      </option>

      <option
        v-for="item in items"
        :key="item.id"
        :value="item.id"
        :selected="item.id == selected"
      >
        {{ item.name || item.title }}
      </option>
    </select>

    <div v-if="description" class="description font-memo">
      {{ description }}
    </div>

    <div v-if="failure" class="font-denial font-memo">
      {{ message }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      // eslint-disable-next-line vue/prop-name-casing
      modelValue : { type: String , default: () => ""    },
      label      : { type: String , default: () => ""    },
      items      : { type: Array  , default: () => []    },
      selected   : { type: Number , default: () => 0     },
      placeholder: { type: String , default: () => ""    },
      description: { type: String , default: () => ""    },
      failure    : { type: Boolean, default: () => false },
      message    : { type: String , default: () => ""    },
    },

    emits: [
      "update:modelValue"
    ]
  };
</script>