<template>
  <div class="p-relative">
    <div
      class="p-absolute base-light w-100 h-18px t-0 l-0 border-radius-4"
      :class="{ 'base-light': !selected, 'base-neutral': selected, 'opacity-50': selected }"
    />

    <div class="progress-bar simple w-100 z-1">
      <span
        :class="{ 'base-highlight-slave': !selected, 'base-neutral': selected }"
        :style="{ width: complete + '%' }"
      />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      complete: { type: Number , default: () => 0     },
      selected: { type: Boolean, default: () => false }
    }
  };
</script>