<template>
  <div
    :ref="(element) => { container = element }"
    class="widget-scrolling-main p-relative h-100"
  >
    <div v-if="show.prev"
      class="p-absolute z-10 t-50 mt--17 l-0 d-flex justify-content-center align-items-center base-neutral border-radius-16 border-1 color-border-light w-32px h-32px c-pointer hover-base-highlight hover-font-neutral"
      @click="move_prev"
    >
      {{ '<' }}
    </div>

    <div
      :ref="(element) => { content = element }"
      class="content d-inline-flex h-100 pb-10 mb--10 transition-250"
      @wheel.exact="scroll"
    >
      <div v-for="item in array" :key="item.id" class="d-flex mx-4 margin-x-truncate">
        <slot name="item" :item="item" />
      </div>
    </div>

    <div v-if="show.next"
      class="p-absolute z-10 t-50 mt--17 r-0 d-flex justify-content-center align-items-center base-neutral border-radius-16 border-1 color-border-light w-32px h-32px c-pointer hover-base-highlight hover-font-neutral"
      @click="move_next"
    >
      {{ '>' }}
    </div>
  </div>
</template>

<script>
  import { 
    ref,
    reactive,
    onMounted
  } from "vue";

  const SCROLL_STEP = 200;

  export default {
    props: {
      array: { type: Array, default: () => [] }
    },

    setup () {
      const container = ref();
      const content   = ref();

      const show = reactive({ prev: false, next: false });

      onMounted(() => show.next = content.value.offsetWidth > container.value.clientWidth ? true : false);

      let move_prev = () => {
        if (parseInt((content.value.style.marginLeft)) > -SCROLL_STEP) {
          content.value.style.marginLeft = "";
          show.prev = false;
        }
        else {
          content.value.style.marginLeft = parseInt((content.value.style.marginLeft)) ?
            parseInt((content.value.style.marginLeft)) + SCROLL_STEP + "px" : ""
          ;
          show.prev = !!content.value.style.marginLeft;
        }

        show.next = true;
      };

      let move_next = () => {
        let max_margin = content.value.offsetWidth - container.value.clientWidth;

        if (max_margin <= -(parseInt((content.value.style.marginLeft)) - SCROLL_STEP)) {
          content.value.style.marginLeft = -max_margin + "px";
          show.next = false;
        }
        else {
          content.value.style.marginLeft = parseInt((content.value.style.marginLeft)) ?
            parseInt((content.value.style.marginLeft)) - SCROLL_STEP + "px" : "-" + SCROLL_STEP + "px"
          ;
          show.next = !!content.value.style.marginLeft;
        }

        show.prev = true;
      };

      return {
        container,
        content,

        show,

        move_prev,
        move_next,

        scroll: (event) => {
          if (content.value.offsetWidth > container.value.clientWidth) {
            if (event.deltaY < 0) { move_prev(); }
            else { move_next(); }
          }
        }
      };
    }
  };
</script>