<template>
  <div class="p-relative">
    <div
      class="d-flex p-relative justify-content-center align-items-center border-radius-12 box-shadow-bottom w-32px h-32px c-pointer"
      :class="{
        'base-neutral': !item.status,
        'base-highlight': item.status
      }"
      @click.stop="$emit('handler', { id: item.id, status: !item.status})"
    >
      <div
        class="icon"
        :class="{
          'icon-structure': !item.status,
          'icon-structure-hover': item.status
        }"
      />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      item: { type: Object, default: () => {} }
    },

    emits: [
      "handler"
    ]
  };
</script>