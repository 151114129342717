<template>
  <div class="charts-mindmap-crumbs">
    <div v-for="(item, index) in source.items" :key="index">
      <div class="member" @click="$emit('handler', index)">
        <div class="short-title max-w-200px">
          {{ item }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    reactive,
    watch
  } from "vue";

  export default {
    props: {
      root: { type: Number, default: () => 0  },
      path: { type: Object, default: () => {} }
    },

    emits: [
      "handler"
    ],

    setup(props) {
      const source = reactive({ items: [] });

      watch([() => props.root, () => props.path], ([root, path]) => {
        source.items = path[root];
      });

      return {
        source
      };
    }
  };
</script>

<style lang="scss">
  .charts-mindmap-crumbs {
    display: flex;
    flex-wrap: wrap;

    .member {
      display: flex;
      color: #007bff;
      text-decoration: none;
      background-color: transparent;
      cursor: pointer;

      &::after{
        display: inline-block;
        padding-right: .5rem;
        padding-left: .5rem;
        color: #007bff;
        content: "/";
      }

      &:hover {
        color: #0056b3;
        text-decoration: underline;

      }
    }
  }
</style>