<template>
  <!-- eslint-disable-next-line vue/no-mutating-props vue/max-attributes-per-line -->
  <div v-if="node.entity.id != 0" :ref="element => { elements[node.entity.id] = element }"
    :class="'node node-' + node.entity.id + ' w-300px base-neutral border-radius-4 box-shadow-bottom'"
    :data-id="node.entity.id"
    :style="style()"
    draggable="true"
    tabindex="1"
  >
    <div
      class="content"
      draggable="true"
    >
      <div class="d-flex flex-column mt-8 w-100">
        <div class="d-flex">
          <div v-if="options && options.icon" class="d-flex ml-5">
            <div v-for="icon in options.icon" :key="icon">
              <div class="icon" :class="'picker-' + icon" />
            </div>
          </div>

          <div v-if="(mode == 'full' || mode == 'wbs' && node.entity.id != root)"
            class="id ml-5"
            :style="{ color: options && options.color }"
          >
            #{{ node.entity.code || node.entity.id }}
          </div>

          <div
            class="text"
            :style="{
              color: options && options.color,
              fontSize: options && options.size + 'px',
              textDecoration: options && options.textDecoration,
              fontStyle: options && options.fontStyle,
              fontWeight: options && options.fontWeight
            }"
          >
            {{ node.entity.title }}
          </div>
        </div>

        <div v-if="node.entity.branch" class="d-flex mb-8 mt-5 px-8 w-100--16px">
          <cards-partial-idea
            class="mr-8 lh-18"
            :count="node.entity.drafts"
            :selected="selected"
          />

          <cards-partial-work
            class="mr-8 lh-18"
            :count="node.entity.nodes"
            :selected="selected"
          />

          <div class="d-flex align-items-center ml-8 w-100">
            <cards-partial-progress-complete
              class="w-100"
              :complete="node.entity.complete"
              :selected="selected"
            />
          </div>
        </div>
      </div>

      <div v-if="links.some(link => link.entity.nodes_source_id == node.entity.id) && (mode == 'full' || mode == 'wbs' && node.entity.id != root)"
        class="fold box-shadow-bottom"
        :class="{ 'base-dark': node.option.folded, 'base-highlight': !node.option.folded }"
        @click.stop="fold(node.option)"
      >
        <div
          class="icon small"
          :class="{ 'icon-plus-hover': node.option.folded, 'icon-minus-hover': !node.option.folded }"
        />
      </div>

      <div v-else-if="links.some(link => link.entity.nodes_source_id == node.entity.id) && mode == 'wbs' && node.entity.id == root"
        class="fold box-shadow-bottom base-highlight"
      />
    </div>

    <div class="p-absolute t--7px r--7px z-105">
      <div
        class="menu box-shadow-bottom z-105"
        @click.stop="$emit('canvasMenu', node.entity.id, node.entity.code, layout.nodes && layout.nodes[node.entity.id] || '', node.option)"
      >
        <div class="icon menu-context" />
      </div>
    </div>
  </div>
</template>

<script>
  import {
    ref,
    watch,
    onMounted
  } from "vue";

  import CardsPartialIdea             from "../../../../visual/widgets/cards/partials/idea/Idea.vue";
  import CardsPartialWork             from "../../../../visual/widgets/cards/partials/work/Work.vue";
  import CardsPartialProgressComplete from "../../../../visual/widgets/cards/partials/progress/complete/Progress.vue";


  export default {
    components: {
      CardsPartialIdea,
      CardsPartialWork,
      CardsPartialProgressComplete
    },

    props: {
      selected: { type: Boolean, default: () => false },
      mode    : { type: String , default: () => ""    },
      root    : { type: Number , default: () => 0     },
      elements: { type: Object , default: () => {}    },
      node    : { type: Object , default: () => {}    },
      links   : { type: Array  , default: () => []    },
      layout  : { type: Object , default: () => {}    }
    },

    emits: [
      "canvasFold",
      "canvasMenu"
    ],

    setup(props, context) {
      const elements = ref(props.elements || []);
      const options  = ref({});

      onMounted(() => {
        options.value = props.node.option.style && JSON.parse(props.node.option.style);
      });

      watch([ () => props.node ], async ([node]) => {
        options.value = node.option.style && JSON.parse(node.option.style);
      });

      return {
        // eslint-disable-next-line vue/no-dupe-keys
        elements,
        options,

        fold: (option) => {
          option.folded = option.folded ? 0 : 1;
          context.emit("canvasFold", option);
        },

        style: () => {
          return "z-index: " + (10000000 - (props.node.option.level * 1000) - props.node.option.offset) + "; " +
            (props.layout.nodes && props.layout.nodes[props.node.entity.id] || "opacity: 0; ") +
            ((options.value && options.value.borderColor) ? "border-color: " + options.value.borderColor + "; " : "")
          ;
        },
      };
    }
  };
</script>

<style lang="scss" scoped>
  .node {
    position: absolute;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 5px;

    &:focus-visible {
      outline: none;
      border-color: #0CAEFF !important;
    }

    &.selected {
      background: #0CAEFF !important;
      border-color: #0CAEFF !important;
      color: #FFFFFF !important;

      .id, .text {
        color: #FFFFFF !important;
      }
    }

    &.focused {
      padding: 30px 50px 30px 0;
    }

    & > .content {
      display: flex;
      align-items: center;
      position: relative;

      & .icons {
        background-repeat: no-repeat;
        width: 0;
        height: 17px;
      }

      & .id {
        display: flex;
        border: 2px solid transparent;
        border-radius: 4px;
      }

      & .text {
        display: flex;
        border: 2px solid transparent;
        border-radius: 4px;
        padding-right: 8px;
        padding-left: 8px;
        min-height: 20px;
      }

      & .fold {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: -25px;
        width: 16px;
        height: 16px;
        text-align: center;
        border: 1px solid transparent;
        border-radius: 15px;
      }
    }

    .menu {
      display: none;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      border: 1px solid;
      border-color: #FFFFFF;
      background-size: auto;
      background-color: #FFFFFF;
      cursor: pointer;

      &:hover {
        border-color: #DEDEDE;
      }
    }

    &:hover {
      .menu { display: flex; }
    }
  }
</style>