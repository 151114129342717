<template>
  <div class="field p-relative w-100">
    <input
      :type="type"
      :placeholder="placeholder"
      :class="{ failure: failure.length }"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    >

    <div class="confirm d-flex p-absolute" :class="confirm" />

    <div v-if="description" class="description font-memo">
      {{ description }}
    </div>
  </div>
</template>

<script>
  import {
    computed
  } from "vue";

  export default {
    props: {
      // eslint-disable-next-line vue/prop-name-casing
      modelValue : { type: String , default: () => "" },
      type       : { type: String , default: () => "" },
      source     : { type: String , default: () => "" },
      failure    : { type: Boolean, default: false    },
      message    : { type: String , default: () => "" },
      description: { type: String , default: () => "" },
      placeholder: { type: String , default: () => "" }
    },

    emits: [
      "update:modelValue"
    ],

    setup(props) {
      const confirm = computed(() => ({
        active: props.source == props.modelValue
      }));

      return {
        confirm
      };
    }
  };
</script>