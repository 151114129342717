export default class Mediator {
  static define(message, handler) {
    roster[message] = roster[message] || [];
    roster[message].push(handler);
  }

  static handle(message) {
    if (roster[message]) {
      roster[message].forEach(handler => {
        handler.apply(this, Array.from(arguments).slice(1));
      });
    }
  }
}

let roster = [];