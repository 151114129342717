<template>
  <div v-if="!complex"
    class="d-flex overflow-y-hidden flex-grow justify-content-between px-8 pt-60"
  >
    <div v-if="project && project.id"
      class="overflow-scroll scrollbar w-100 h-100"
      :class="{ 'base-neutral': background, 'p-8': background }"  
    >
      <slot name="content" />
    </div>

    <div v-else
      class="base-neutral w-100 p-8"
    >
      {{ $t('Choose_project') }}
    </div>
  </div>

  <div v-else
    class="d-flex overflow-y-hidden flex-grow justify-content-between px-8 pt-60"
  >
    <slot v-if="project && project.id" name="content" />

    <div v-else
      class="base-neutral w-100 p-8"
    >
      {{ $t('Choose_project') }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      project   : { type: Object , default: () => {}    },
      complex   : { type: Boolean, default: () => false },
      background: { type: Boolean, default: () => true  }
    },

    setup () {
      return {}; }
  };
</script>