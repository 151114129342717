<template>
  <layout-web>
    <partial-header :active="3" />

    <div class="form">
      <field-input
        v-model="fields.title"
        type="text"
        :placeholder="$t('insert_title')"
        :message="errors.title"
        :failure="errors.title"
      />

      <hr>

      <field-editor
        v-model="fields.solution"
        :placeholder="$t('solution')"
        :description="$t('solution_required')"
        :message="errors.solution"
        :failure="errors.solution"
      />

      <field-input
        v-model="fields.color"
        type="text"
        :placeholder="$t('insert_color')"
        :description="$t('color_required')"
        :message="errors.color"
        :failure="errors.color"
        @click="show_popups_picker"
      />

      <!-- <compact v-if="popups.picker"
        v-model="fields.color"
        class="max-w-100"
        @click="hide_popups_picker"
      /> -->

      <div class="d-flex mt-20">
        <div class="button primary" @click="submit">
          {{ $t("save") }}
        </div>
      </div>
    </div>
  </layout-web>
</template>

<script>
  import {
    reactive,
    onMounted
  } from "vue";

  import LayoutWeb     from "../../../../layouts/Web.vue";
  import PartialHeader from "../partials/Header.vue";
  import FieldInput    from "../../../../visual/fields/input/Input.vue";
  import FieldEditor   from "../../../../visual/fields/editor/Editor.vue";

  import ExcuseEntity   from "../../entities/Excuse.js";
  import ExcusesService from "../../services/Excuses.js";

  // import { Compact } from "@ckpack/vue-color";

  export default {
    components: {
      LayoutWeb,
      PartialHeader,
      FieldInput,
      FieldEditor,
      // Compact
    },

    props: {
      id: { type: String, default: () => "" }
    },

    setup(props) {
      const source = reactive(new ExcuseEntity);
      const popups = reactive({ picker: false });

      const fields = reactive({ title: ""   , solution: ""   , color: ""    });
      const errors = reactive({ title: false, solution: false, color: false });

      onMounted(async () => {
        Object.assign(
          source,
          await ExcusesService.item(props.id)
        );

        fields.title    = source.excuse.title;
        fields.solution = source.excuse.solution;
        fields.color    = source.excuse.color;
      });

      let remove_errors = function () {
        errors.title    = false;
        errors.solution = false;
        errors.color    = false;
      };

      let show_popups_picker = function () {
        popups.picker = true;
      };

      let hide_popups_picker = function () {
        fields.color  = fields.color.hex;
        popups.picker = false;
      };

      return { source, popups, fields, errors,
        show_popups_picker,
        hide_popups_picker,

        submit: async () => {
          remove_errors();

          Object.assign(
            source,
            await ExcusesService.update(Object.assign(fields, {
              id: props.id
            }))
          );

          if (source.errors.length) {
            for (const [key, value] of Object.entries(source.errors)) {
              errors[key] = value[0];
            }
          }
        }
      };
    }
  };
</script>