<template>
  <div v-if="source.access">
    <div class="box-grid col-5">
      <widget-cards-simple
        v-for="item in source.roles"
        :key="item.id"
        class="base-neutral"
        :item="item"
        :path="'/' + root_url + '/' + spaces_id + '/roles/' + item.id"
        :menu="['details', 'remove']"
        :partials="['description', 'menu']"
        @remove="remove"
      />
    </div>

    <div class="d-flex">
      <div class="button confirm bottom" @click="show_popups_create">
        {{ $t("add") }}
      </div>
    </div>

    <!-- Popup: Create role -->
    <popup-base v-if="popups.create">
      <template #header>
        <div>{{ $t("add_role") }}</div>

        <div
          class="icon icon-close c-pointer"
          @click="hide_popups_create"
        />
      </template>

      <template #content>
        <field-input
          v-model="fields.name"
          :placeholder="$t('insert_name')"
          :message="$t('name_is_empty')"
          :failure="errors.name"
        />
      </template>

      <template #footer>
        <div class="button primary" @click="create">
          {{ $t("ok") }}
        </div>
      </template>
    </popup-base>
  </div>

  <div v-else-if="!source.access && mounted" class="failure">
    {{ $t("access_is_closed") }}
  </div>
</template>

<script>
  import {
    ref,
    reactive,
    onMounted
  } from "vue";

  import PopupBase         from "../../../../visual/popups/base/Base.vue";
  import FieldInput        from "../../../../visual/fields/input/Input.vue";
  import WidgetCardsSimple from "../../../../visual/widgets/cards/simple/Simple.vue";

  import RosterEntity  from "../../entities/Roster.js";
  import RosterService from "../../services/Roster.js";

  export default {
    components: {
      PopupBase,
      FieldInput,
      WidgetCardsSimple
    },

    props: {
      spaces_id: { type: String, default: () => "" },
      root_url : { type: String, default: () => "" }
    },

    setup(props) {
      const source = reactive(new RosterEntity);
      const popups = reactive({ create: false });

      const fields = reactive({ name: ""    });
      const errors = reactive({ name: false });

      const mounted = ref(false);
      onMounted(async () => {
        Object.assign( source, await RosterService.load(props.spaces_id) );
        mounted.value = true;
      });

      let show_popups_create = () => popups.create = true;
      let hide_popups_create = () => {
        popups.create = false;
        fields.name   = "";
      };

      let attach_errors = () => errors.name = fields.name == "" ? true : false;
      let remove_errors = () => errors.name = false;

      return {
        source,
        errors,
        fields,
        popups,
        mounted,

        show_popups_create,
        hide_popups_create,

        create: async () => {
          if (fields.name != "") {
            remove_errors();

            Object.assign(
              source,
              await RosterService.create(Object.assign(fields, { spaces_id: props.spaces_id }))
            );

            hide_popups_create();
          } else {
            attach_errors();
          }
        },

        remove: async (id) => Object.assign(source, await RosterService.delete(id))
      };
    }
  };
</script>