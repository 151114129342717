<template>
  <div
    :ref="(element) => { wrapper = element }"
    class="roadmap card-wrapper hint-wrapper box-shadow-card"
    :class="{ 'selected': selected }"
    tabindex="1"
    @click.ctrl.exact="item.id && redirect_node"
    @click.shift.exact="item.id && redirect_schema"
  >
    <div class="header p-8 c-pointer border-bottom-1 border-color-transparent">
      <div class="d-flex">
        <div class="title short-text font-weight-500">
          {{ item.name || item.title }}
        </div>
      </div>

      <cards-partial-id v-if="partials.includes('id') && item.id"
        :id="item.id"
      />

      <cards-partial-code v-if="partials.includes('code') && (item.id || item.code)"
        :code="item.code || item.id"
      />
    </div>

    <div class="content p-8 pt-0 overflow-hidden">
      <div
        :ref="(element) => { container = element }"
        class="container d-flex justify-content-between transition-100"
        :class="{
          'w-100': factors.period != true && factors.estimate != true,
          'w-333': factors.period == true || factors.estimate == true,
          'ml--117p': factors.estimate == true
        }"
        :data-milestone_id="item.milestones_id"
      >
        <div v-if="partials.includes('progress-period-simple')" class="hint-target w-30">
          <cards-partial-diagram-period-simple
            :start="item.start"
            :finish="item.finish"
            :shipped="item.shipped"
            
            :edit="factors.period"
            @clear="factors_clear"
            @choose="factor_choose"

            @mouseover="hint_over(1)"
            @mouseleave="hint_leave(1)"

            @submit="(start, finish) => $emit('period', start, finish)"
          />
        </div>

        <div v-if="partials.includes('progress-estimate')" class="hint-target w-30">
          <cards-partial-diagram-estimate
            :actually="item.complexity_actually"
            :complexity="item.complexity"

            :edit="factors.estimate"
            @clear="factors_clear"
            @choose="factor_choose"

            @mouseover="hint_over(2)"
            @mouseleave="hint_leave(2)"

            @submit="complexity => $emit('complexity', item.id, complexity)"
          />
        </div>

        <div v-if="partials.includes('progress-completed')" class="hint-target w-30">
          <cards-partial-diagram-completed
            :tasks="item.tasks"
            :complete="item.complete"

            @mouseover="hint_over(3)"
            @mouseleave="hint_leave(3)"
          />
        </div>
      </div>
    </div>

    <div class="hint hint-1 bottom left">
      {{ $t('Deadline') }}
    </div>

    <div class="hint hint-2 bottom center">
      {{ $t('Estimate') }}
    </div>

    <div class="hint hint-3 bottom right">
      {{ $t('Completed_tasks_to_total') }}
    </div>

    <cards-partial-menu v-if="partials.includes('menu') && menu"
      :item_id="item.id"
      :actions="actions.list"
    />

    <cards-partial-shipped v-if="partials.includes('shipped')"
      :item="item"
      @handler="(id) => $emit('shipped', item.milestones_id)"
    />

    <!-- Popup: Remove item -->
    <popup-base v-if="popup.remove">
      <template #header>
        <div>{{ $t("Remove") }}</div>
        <div class="icon icon-close c-pointer" @click="hide_popup_remove" />
      </template>

      <template #content>
        {{ $t("do_you_really_want_to_delete_this_element") }} - {{ item.name || item.title }} ?
      </template>

      <template #footer>
        <div class="button primary" @click="remove(item.id)">
          Ok
        </div>
      </template>
    </popup-base>
  </div>
</template>

<script>
  import {
    ref,
    reactive,
    watch,
    onMounted
  } from "vue";

  import CardsPartialDiagramPeriodSimple from "../partials/diagram/period/Simple.vue";
  import CardsPartialDiagramCompleted    from "../partials/diagram/Completed.vue";
  import CardsPartialDiagramEstimate     from "../partials/diagram/Estimate.vue";
  import CardsPartialId                  from "../partials/id/Id.vue";
  import CardsPartialCode                from "../partials/code/Code.vue";
  import CardsPartialMenu                from "../partials/menu/Menu.vue";
  import CardsPartialShipped             from "../partials/shipped/Shipped.vue";

  import PopupBase from "../../../popups/base/Base.vue";

  export default {
    components: {
      CardsPartialDiagramPeriodSimple,
      CardsPartialDiagramCompleted,
      CardsPartialDiagramEstimate,
      CardsPartialId,
      CardsPartialCode,
      CardsPartialMenu,
      CardsPartialShipped,
      PopupBase
    },

    props: {
      edit    : { type: Object , default: () => {}    },
      item    : { type: Object , default: () => {}    },
      menu    : { type: Array  , default: () => []    },
      partials: { type: Array  , default: () => []    },
      selected: { type: Boolean, default: () => false }
    },

    emits: [
      "shipped",
      "remove",
      "period",
      "complexity"
    ],

    setup(props, context) {
      const wrapper = ref();
      const container = ref();

      const factors = reactive({ period: false, estimate: false });
      const actions = reactive({ list: []});
      const popup   = reactive({ remove: false });

      onMounted(() => {
        init_menu(props.menu);
        factors_refresh();
      });

      watch(() => props.menu, async (menu) => init_menu(menu));

      let init_menu = (menu) => {
        actions.list = [];

        menu.forEach((item) => {
          if      (item === "details")   { actions.list.push({ title: "Details"  , handler: redirect_node  , hotkey: "Ctrl + Click"  }); }
          else if (item === "strucrure") { actions.list.push({ title: "Structure", handler: redirect_schema, hotkey: "Shift + Click" }); }
          else if (item === "remove")    { actions.list.push({ title: "Remove"   , handler: show_popup_remove                        }); }
          else                           { actions.list.push(item);                                                                      }
        });
      };

      let redirect_node   = () => window.location.href = "/node/"   + props.item.code;
      let redirect_schema = () => window.location.href = "/schema/" + props.item.code;

      let show_popup_remove = function() { popup.remove = true;  };
      let hide_popup_remove = function() { popup.remove = false; };

      let redirect_schema_code = (code) => window.location.href = "/schema/" + code;

      let factors_refresh = () => {
        let elements = container.value && container.value.querySelectorAll(".diagram-edit");

        if (elements) {
          elements.forEach((element) => { element.style.opacity = 0; });

          container.value.addEventListener("transitionend", function (event) {
            if (factors.period == true || factors.estimate == true) {
              event.target.querySelectorAll(".diagram-edit").forEach((element) => { element.style.opacity = 1; });
            }
          });
        }
      };

      let factors_clear = (name) => {
        factors.period   = false;
        factors.estimate = false;

        if (name) { factors[name] = true; }
        else { container.value.querySelectorAll(".diagram-edit").forEach((element) => { element.style.opacity = 0; }); }
      };

      let hint_over = (index) => wrapper.value.querySelector(".hint-" + index).classList.add("active");
      let hint_leave = (index) => wrapper.value.querySelector(".hint-" + index).classList.remove("active");

      return {
        wrapper,
        container,

        factors,
        actions,
        popup,

        redirect_node,
        redirect_schema,
        redirect_schema_code,

        show_popup_remove,
        hide_popup_remove,

        factors_clear,
        factor_choose: (name) => factors_clear(name),

        hint_over,
        hint_leave,

        remove: (id) => {
          context.emit("remove", id);
          hide_popup_remove();
        }
      };
    }
  };
</script>