<template>
  <div class="charts-mindmap-filter p-relative">
    <div
      class="d-flex p-relative justify-content-center align-items-center border-radius-12 box-shadow-bottom w-32px h-32px c-pointer"
      :class="{
        'base-neutral': !dropdown,
        'base-highlight': dropdown
      }"
      @click.stop="dropdown = !dropdown"
    >
      <div
        class="icon"
        :class="{
          'icon-filter': !dropdown,
          'icon-filter-hover': dropdown
        }"
      />

      <div v-if="selected() && !dropdown"
        class="p-absolute base-highlight w-10px h-10px border-radius-8 t--2px r--2px"
      />
    </div>

    <div v-if="dropdown"
      class="p-absolute t-39px r-0 w-200px p-16 base-neutral z-107 box-shadow-bottom border-radius-16 d-flex flex-column"
    >
      <div class="font-memo font-light mb-16 lh-16">
        {{ $t("Status") }}
      </div>

      <field-checkbox lang
        :value="{ id: 0, content: 'in_progress', status: values.progress }"
        @submit="(data) => progress(+data.status)"
      />

      <field-checkbox lang
        :value="{ id: 0, content: 'archived', status: values.archived }"
        @submit="(data) => archived(+data.status)"
      />
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";

  import FieldCheckbox from "../../../visual/fields/checkbox/Checkbox.vue";

  export default {
    components: {
      FieldCheckbox
    },

    props: {
      values: { type: Object, default: () => {} }
    },

    emits: [
      "handler"
    ],

    setup(props, context) {
      const dropdown = ref(false);

      let selected = () => {
        return props.values && (props.values.progress || props.values.archived);
      };

      return {
        dropdown,
        selected,

        progress: (value) => {
          // eslint-disable-next-line vue/no-mutating-props
          props.values.progress = +value;
          context.emit("handler", props.values);
        },

        archived: (value) => {
          // eslint-disable-next-line vue/no-mutating-props
          props.values.archived = +value;
          context.emit("handler", props.values);
        }
      };
    }
  };
</script>