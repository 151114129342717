export default class Timing {
  constructor(response) {
    this.status = {};

    if (response) {
      if (response.status) {
        this.status = response.status;
      }
    }
  }
}