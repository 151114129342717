import Server from "../../../../system/server/server";
import Entity from "../entities/Comment.js";

export default class Comment {
  static async load(entity) {
    return new Entity(await Server.post("comments", entity));
  }

  static async create(comment) {
    return new Entity(await Server.post("comments/create", comment));
  }

  static async remove(comment) {
    return new Entity(await Server.post("comments/delete", comment));
  }
}
