<template>
  <div class="field w-100">
    <div v-if="label" class="font-soft font-memo mb-8">
      {{ label }}
    </div>

    <input
      :ref="(element) => { input = element }"
      :type="type"
      :placeholder="placeholder"
      :class="{ failure: failure.length }"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
      @blur="$emit('close')"
    >

    <div v-if="description" class="description font-memo">
      {{ description }}
    </div>

    <div v-if="failure" class="font-denial font-memo">
      {{ message }}
    </div>
  </div>
</template>

<script>
  import {
    ref,
    onMounted,
    nextTick
  } from "vue";

  export default {
    props: {
      // eslint-disable-next-line vue/prop-name-casing
      modelValue : { type: String , default: () => ""     },
      label      : { type: String , default: () => ""     },
      type       : { type: String , default: () => "text" },
      placeholder: { type: String , default: () => ""     },
      description: { type: String , default: () => ""     },
      failure    : { type: Boolean, default: () => false  },
      message    : { type: String , default: () => ""     },
      autofocus  : { type: Boolean, default: () => false  }
    },

    emits: [
      "update:modelValue",
      "close",
    ],

    setup(props) {
      const input = ref();

      onMounted(async() => {
        await nextTick();
        if (props.autofocus) input.value.focus();
      });

      return {
        input
      };
    }
  };
</script>