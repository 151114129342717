<template>
  <div class="card card-wrapper">
    <div class="header p-8 c-pointer">
      <field-input
        v-model="title"
        :autofocus="true"
        :placeholder="$t('name')"
        @keyup.esc="$emit('close')"
        @keyup.enter="submit"
        @close="$emit('close')"
      />
    </div>

    <div class="content p-8" />
  </div>
</template>

<script>
  import { ref } from "vue";

  import FieldInput from "../../../../fields/input/Input.vue";

  export default {
    components: {
      FieldInput
    },

    emits: [
      "close",
      "create"
    ],

    setup(props, context) {
      const title = ref("");

      return {
        title,

        submit: () => {
          if (title.value != "") {
            context.emit("create", title.value);
            title.value = "";
          }
        }
      };
    }
  };
</script>