export default class Config {
  static API = "//api.staging.lorida.ru";

  static STATUS_BACKLOG   = "1";
  static STATUS_NEW       = "2";
  static STATUS_PROGRESS  = "3";
  static STATUS_SUSPENDED = "4";
  static STATUS_DONE      = "5";

  static SCOPE_PROFILE_ID      = "1";
  static SCOPE_ORGANIZATION_ID = "2";
  static SCOPE_PROJECT_ID      = "3";
  static SCOPE_NODE_ID         = "4";

  static SERIES_MODULE = 1;
  static SERIES_ROLE   = 2;
  static SERIES_STATUS = 3;
}