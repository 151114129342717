<template>
  <div class="d-flex justify-content-between align-items-center">
    <div class="d-flex">
      <div class="d-flex align-items-center font-weight-500 pr-16 lh-18">
        {{ parent.name || parent.title }}
      </div>

      <div
        class="d-flex justify-content-center align-items-center border-1 border-radius-8 w-18px h-18px c-pointer base-neutral"
        @click="$emit('handler', root ? null : project.structure_pick)"
      >
        <div class="icon icon-add" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      root   : { type: Boolean, default: () => false },
      project: { type: Object , default: () => {}    },
      parent : { type: Object , default: () => {}    }
    },

    emits: [
      "handler"
    ]
  };
</script>