<template>
  <div class="p-relative">
    <div
      class="p-absolute base-light w-100 h-100 t-0 l-0 border-radius-6"
      :class="{ 'base-light': !selected, 'base-neutral': selected, 'opacity-50': selected }"
    />

    <div class="progress-bar w-100 z-1">
      <span
        :class="{ 'base-highlight': !selected, 'base-neutral': selected }"
        :style="{ width: percent(stages) + '%' }"
      />

      <div class="value">
        {{ complete(stages) }} / {{ stages.length }}
      </div>

      <div class="users">
        <div v-for="stage in stages" :key="stage.state_id">
          <img v-if="stage.avatar && stage.state_status"
            class="item object-fit-cover"
            :src="url(stage.user_id)"
          >

          <div v-else-if="!stage.avatar && stage.state_status"
            class="item"
            :class="{ 'base-selection': stage.state_status == 'history', 'base-highlight': stage.state_status == 'current' }"
          >
            {{ title(stage.worker) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";

  import Config from "../../../../../../config.js";
  import Server from "../../../../../../system/server/server.js";

  export default {
    props: {
      stages  : { type: Array  , default: () => []    },
      selected: { type: Boolean, default: () => false }
    },

    setup(props) {
      const api = ref(Config.API);

      let title = (user) => {
        if(user) {
          let short = user.split(" ");
          return short[0].charAt(0) + short[1].charAt(0);
        }
      };

      let complete = () => {
        let value = 0;
        if (props.stages.length) {
          props.stages.forEach(stage => {
            value += stage.state_status == 'history' ? 1 : 0;
          });
        }
        return value;
      };

      let percent = () => {
        return props.stages.length ? (complete() * 100 / props.stages.length) : 0;
      };

      return {
        title,
        complete,
        percent,

        url: (id) => api.value + '/api/profile/download?id=' + id + '&token=' + Server.token()
      };
    }
  };
</script>