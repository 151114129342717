<template>
  <div class="d-flex justify-content-between align-items-center flex-wrap base-neutral border-radius-8 mb-16 p-8">
    <div class="font-title">
      {{ $t("Organization") }}
    </div>

    <widget-tabs
      :tabs="tabs"
      @activate="activate"
    />
  </div>
</template>

<script>
  import { reactive  } from "vue";
  import { useRouter } from "vue-router";

  import WidgetTabs from "../../../../visual/widgets/tabs/Tabs.vue";

  export default {
    components: {
      WidgetTabs
    },

    props: {
      id    : { type: String, default: () => "" },
      active: { type: Number, default: () => 0  }
    },

    setup(props) {
      const router = useRouter();

      const tabs = reactive([
        { id: 1, name: "Details" , active: false                 },
        { id: 2, name: "Modules" , active: false, url: "modules" },
        { id: 3, name: "Roles"   , active: false, url: "roles"   },
        { id: 4, name: "Types"   , active: false, url: "chains"  },
      ]);

      tabs[props.active].active = true;

      return { tabs,
        activate: (tab)  => {
          tabs.map(function(tab) { tab.active = false; });
          tab.active = tab.id;

          let path = tab.url &&
            "/spaces/" + props.id + "/" + tab.url ||
            "/spaces/" + props.id
          ;

          router.push({ path: path });
        }
      };
    }
  };
</script>