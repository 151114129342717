export default class Tracker {
  constructor(response) {
    this.status   = [];
    this.active   = [];
    this.assignee = [];
    this.excuses  = [];
    this.follow   = 0;

    if (response) {
      if (response.status) {
        this.status = response.status;
      }

      if (response.data) {
        this.active   = response.data.active;
        this.assignee = response.data.assignee;
        this.excuses  = response.data.excuses;
        this.follow   = +response.data.active.timing_id;
      }
    }
  }
}