import Server from "../../../../system/server/server";
import Entity from "../entities/Timing";

export default class Timing {
  static async launch(nodes_id, role_state_id) {
    return new Entity(await Server.post("timing/launch", {
      nodes_id     : nodes_id,
      role_state_id: role_state_id
    }));
  }

  static async finish(nodes_id, role_state_id) {
    return new Entity(await Server.post("timing/finish", {
      nodes_id     : nodes_id,
      role_state_id: role_state_id
    }));
  }
}