<template>
  <div
    ref="canvas"
    class="charts-canvas z-20"
    draggable="true"

    :style="location"

    @dragstart="drag"
    @dragend="drop"
    @dragover.prevent="over"
    @dragenter.prevent

    @wheel.exact="over_y"
    @wheel.shift.prevent="over_x"
  >
    <svg class="charts-canvas-links">
      <path
        v-for="link in links"
        :key="link.entity.id"

        :class="'link link-' + link.entity.id"
        :d="layout.links && layout.links[link.entity.id]"

        fill="none"
        stroke="#0CAEFF"
        stroke-width="2"
      />
    </svg>

    <div v-if="mode == 'roadmap'">
      <charts-roadmap
        v-for="node in nodes"
        :key="node.entity.id"
        :class="status(node, links)"

        :selected="selected == node.entity.id"

        :mode="mode"
        :root="root"
        :node="node"
        :links="links"
        :layout="layout"
        :elements="elements"
        :progress="progress[node.entity.id]"

        @canvas-menu="teleport"
        @canvas-fold="option => $emit('chart-fold', option)"
        @node-deadline="(id, deadline) => $emit('chart-deadline', id, deadline)"
        @node-complexity="(id, complexity) => $emit('chart-complexity', id, complexity)"

        @keyup.ctrl.left.exact="fold(node.option, 1)"
        @keyup.ctrl.right.exact="fold(node.option, 0)"

        @keyup.left.exact="move_left()"
        @keyup.right.exact="move_right()"
        @keyup.up.exact="move_up()"
        @keyup.down.exact="move_down()"
        @keyup.enter.exact="$emit('chart-select', node)"
        @keyup.esc.exact="$emit('chart-cancel')"

        @keydown.ctrl.c="copy_node(node.entity.id)"
        @keydown.ctrl.x="cut_node(node.entity.id)"
        @keydown.ctrl.v="insert_node(node.entity.id)"

        @click.exact="$emit('chart-select', node)"
        @click.shift.exact="highlight(node.entity.code)"
        @click.ctrl.exact="redirect(node.entity.code)"

        @keyup.insert="insert_child(node.entity.id)"
        @keyup.ctrl.enter="insert_sibling(node.entity.id)"

        @dragstart.stop="drag($event, node.entity.id)"
        @dragover.stop="over($event, node.entity.id)"
        @dragend.stop="drop(node.entity.id)"
      />
    </div>

    <div v-else>
      <charts-node
        v-for="node in nodes"
        :key="node.entity.id"
        :class="status(node, links)"

        :selected="selected == node.entity.id"

        :mode="mode"
        :root="root"
        :node="node"
        :links="links"
        :layout="layout"
        :elements="elements"

        @canvas-menu="teleport"
        @canvas-fold="option => $emit('chart-fold', option)"
        @keyup.ctrl.left.exact="fold(node.option, 1)"
        @keyup.ctrl.right.exact="fold(node.option, 0)"

        @keyup.left.exact="move_left()"
        @keyup.right.exact="move_right()"
        @keyup.up.exact="move_up()"
        @keyup.down.exact="move_down()"
        @keyup.enter.exact="$emit('chart-select', node)"
        @keyup.esc.exact="$emit('chart-cancel')"

        @keydown.ctrl.c="copy_node(node.entity.id)"
        @keydown.ctrl.x="cut_node(node.entity.id)"
        @keydown.ctrl.v="insert_node(node.entity.id)"

        @click.exact="$emit('chart-select', node)"
        @click.shift.exact="highlight(node.entity.code)"
        @click.ctrl.exact="redirect(node.entity.code)"

        @keyup.insert="insert_child(node.entity.id)"
        @keyup.ctrl.enter="insert_sibling(node.entity.id)"

        @dragstart.stop="drag($event, node.entity.id)"
        @dragover.stop="over($event, node.entity.id)"
        @dragend.stop="drop(node.entity.id)"
      />
    </div>

    <charts-placeholder
      v-for="node in nodes"
      :key="node.entity.id"

      :node="node"
      :links="links"
      :layout="layout"
      :elements="elements"

      @canvas-child="bundle => $emit('chart-child', bundle)"
      @canvas-sibling="bundle => $emit('chart-sibling', bundle)"
      @canvas-cancel="$emit('chart-cancel-placeholder')"
    />

    <charts-target
      :source="motion.node && motion.node.dataset.id"
      :target="states.target"

      @canvas-move="bundle => $emit('chart-move', bundle)"
    />

    <div v-if="actions.position"
      class="d-flex flex-column p-absolute border-radius-16 t-0 r-0 min-w-150px max-w-290px p-5 base-neutral box-shadow-bottom"
      :style="actions.position + ' z-index: 10000000'"
      @mouseleave="teleport_clear"
    >
      <div v-for="action in actions.list" :key="action">
        <div
          class="d-flex justify-content-between my-5 p-5 border-radius-5 hover-base-light c-pointer"
          @click.stop="teleport_handler(action)"
        >
          <div>{{ $t(action.title) }}</div>

          <div v-if="action.hotkey" class="font-light">
            {{ $t(action.hotkey) }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    ref,
    reactive,
    computed,
    watch
  } from "vue";

  import Motion from "../Motion.js";

  import ChartsRoadmap     from "./elements/Roadmap.vue";
  import ChartsNode        from "./elements/Node.vue";
  import ChartsPlaceholder from "./elements/Placeholder.vue";
  import ChartsTarget      from "./elements/Target.vue";

  export default {
    components: {
      ChartsRoadmap,
      ChartsNode,
      ChartsPlaceholder,
      ChartsTarget,
    },

    props: {
      mode    : { type: String, default: () => "" },
      root    : { type: Number, default: () => 0  },
      nodes   : { type: Array , default: () => [] },
      links   : { type: Array , default: () => [] },
      layout  : { type: Object, default: () => {} },
      active  : { type: Object, default: () => {} },
      selected: { type: Object, default: () => {} },
      progress: { type: Array , default: () => {} },
      region  : { type: Object, default: () => {} },
      viewport: { type: Object, default: () => {} },
    },

    emits: [
      "chart-update",
      "chart-deadline",
      "chart-complexity",
      "chart-move",
      "chart-child",
      "chart-child-placeholder",
      "chart-sibling",
      "chart-sibling-placeholder",
      "chart-cancel-placeholder",
      "chart-remove",
      "chart-fold",
      "chart-select",
      "chart-focused",
      "chart-cancel",
      "chart-crumb",
      "chart-insert",
      "location"
    ],

    setup(props, context) {
      /**
       * Canvas DOM node.
       */
      const canvas = ref(null);

      /**
       * List of nodes.
       * Required by Charts component to calculate node sizes. 
       */
      const elements = ref({});

      /**
       * Section: selected node and drop target node.
       */
      const states = reactive({
        target  : undefined, // Drop target node.
        selected: {}, // Selected node.
      });

      const show = function (target, node, emit) {
        states[target] = node;

        if (emit) {
          context.emit(emit, node);
        }
      };

      const hide = function (target) {
        states[target] = target == "target" ? undefined : {};
      };

      /**
       * Status classes of the node.
       */
      const status = function (node, links) {
        return {
          focused : states.target == node.entity.id,
          complex : links.some(link => link.entity.nodes_source_id == node.entity.id),
          selected: props.selected == node.entity.id
        };
      };

      /**
       * Section: drag and drop for canvas and node.
       */
      const cursor = reactive({
        x: 0,
        y: 0,
      });

      const region = reactive({
        width : props.region.width,
        height: props.region.height
      });

      watch(props.region, size => {
        region.width  = size.width;
        region.height = size.height;
      });

      const viewport = reactive({
        top   : props.viewport.top,
        left  : props.viewport.left,
        width : props.viewport.width,
        height: props.viewport.height,
      });

      watch(props.viewport, location => { 
        viewport.top    = location.top;
        viewport.left   = location.left;
        viewport.width  = location.width;
        viewport.height = location.height;
      });

      const location = computed(() => ({
        top : viewport.top  + "px",
        left: viewport.left + "px",

        width : region.width  + "px",
        height: region.height + "px",
      }));

      /**
       * We need to track which exactly element type / DOM node is in motion.
       * If we don't track this some artifacts (other elements moving, unpredictable
       * jumps and so on) are possible.
       */
      const motion = reactive({
        id  : undefined,
        node: undefined,
        type: undefined,
      });

      const drag = function (event, id) {
        if (motion.node) { return; }

        cursor.x = event.x;
        cursor.y = event.y;

        motion.id   = id;
        motion.node = elements.value[id];
        motion.type = id ? MOTION_TYPE_NODE : MOTION_TYPE_CANVAS;

        if (motion.type === MOTION_TYPE_CANVAS) {
          Motion.launch(event, canvas.value);
        }

        if (motion.node && motion.node.dataset && motion.node.dataset.id) {
          event.dataTransfer.setData("schema_node_id", motion.node.dataset.id);
        }
      };

      const over = function (event, id) {
        if (props.mode == "full" || props.mode == "roadmap" || (props.mode == "wbs" && id != props.root)) {
          if (motion.type === MOTION_TYPE_NODE) {
            //... Highlight drop targets
            if (id && id !== motion.id) { // For some reason event is bubbling, even we cancel it.  ????? && id != states.target
              states.target = id;
              context.emit("chart-update", props.nodes, props.links); // Redraw only if changing drop target
            }
          }
          else if (motion.type === MOTION_TYPE_CANVAS) {
            let position = Motion.handle(viewport, cursor, event, {
              left: -(region.width  - viewport.width),
              top : -(region.height - viewport.height),
            });

            context.emit("location", position.left, position.top);
          }

          cursor.x = event.x;
          cursor.y = event.y;
        }
      };

      const over_x = function (event) {
        viewport.left = viewport.left + (event.deltaY > 0 ? -20 : 20);
        context.emit("location", viewport.left, viewport.top);
      };

      const over_y = function (event) {
        viewport.top = viewport.top + (event.deltaY > 0 ? -20 : 20);
        context.emit("location", viewport.left, viewport.top);
      };

      const drop = (id) => {
        if (!motion.node) { return; }

        if (motion.type === MOTION_TYPE_NODE) {
          // ... Drop somewhere request. Layout will be rebuilt
          motion.node.style = props.layout.nodes[id]; // @TODO: Split left and top in LAYOUT to have an ability to get them separately
          hide('target');
          // context.emit("chart-update", props.nodes, props.links) // Redraw only if changing drop target
        }
        else if (motion.type === MOTION_TYPE_CANVAS) {
          Motion.finish(canvas.value);
        }

        motion.id   = undefined;
        motion.node = undefined;       
        motion.type = undefined;

        context.emit("chart-update", props.nodes, props.links); // Redraw only if changing drop target
      };
      /**
       * End of Section: drag and drop
       */

      const fold = (option, value) => {
        option.folded = value;
        context.emit("chart-fold", option);
      };

      const move_left = () => {
        let parent = props.links.filter(link => link.entity.nodes_target_id == props.active.entity.id).map(link => { return link.entity.nodes_source_id; });
        let target = props.nodes.filter(node => parent.includes(node.entity.id));
        move_update(target[0]);
      };

      const move_right = () => {
        let children = props.links.filter(link => link.entity.nodes_source_id == props.active.entity.id).map(link => { return link.entity.nodes_target_id; });
        let target = props.nodes.filter(node => children.includes(node.entity.id) && node.option.offset == 0);
        move_update(target[0]);
      };

      const move_up = () => {
        let parent = props.links.filter(link => link.entity.nodes_target_id == props.active.entity.id).map(link => { return link.entity.nodes_source_id; });
        let children = props.links.filter(link => link.entity.nodes_source_id == parent[0]).map(link => { return link.entity.nodes_target_id; });
        let target = props.nodes.filter(node => children.includes(node.entity.id) && node.option.offset == props.active.option.offset - 1);
        move_update(target[0]);
      };

      const move_down = () => {
        let parent = props.links.filter(link => link.entity.nodes_target_id == props.active.entity.id).map(link => { return link.entity.nodes_source_id; });
        let children = props.links.filter(link => link.entity.nodes_source_id == parent[0]).map(link => { return link.entity.nodes_target_id; });
        let target = props.nodes.filter(node => children.includes(node.entity.id) && node.option.offset == props.active.option.offset + 1);
        move_update(target[0]);
      };

      const move_update = (node) => {
        if (node && node.entity) {
          context.emit("chart-focused", node);
          document.querySelector("[data-id='" + node.entity.id + "']").focus();
        }
      };

      const redirect_node   = (id) => document.location.href = "/node/" + id;
      const redirect_schema = (id) => document.location.href = "/schema/" + id;

      let copy_node = (target_id) => {
        localStorage.setItem("id", target_id);
        localStorage.setItem("action", "copy");
      };
      let cut_node = (target_id) => {
        localStorage.setItem("id", target_id);
        localStorage.setItem("action", "cut");
      };
      let insert_node = (source_id) => {
        let id = localStorage.getItem("id");
        if (id) {
          context.emit("chart-insert", source_id, id, localStorage.getItem("action"));
          localStorage.removeItem("id");
          localStorage.removeItem("action");
        }
      };

      const fold_menu = (option) => {
        option.folded = option.folded ? 0 : 1;
        context.emit("chart-fold", option);
      };

      const actions = reactive({ id: 0, list: [], position: ""});

      const teleport = (id, code, position, option) => {
        actions.id = id;
        actions.code = code;
        actions.position = position;
        actions.list = {};

        if (props.mode == "wbs") {
          if (id != props.root) {
            actions.list[1] = { title: 'details', handler:(id, code) => redirect_node(code), hotkey: 'Ctrl + Click' };
            actions.list[6] = { title: 'remove', handler:(id) => context.emit('chart-remove', id) };
          }

          if (props.links.some(link => link.entity.nodes_target_id == id) && id != props.root) {
            actions.list[2] = { title: 'highlight', handler:(id, code) => redirect_schema(code), hotkey: 'Shift + Click' };
            actions.list[4] = { title: 'sibling', handler:(id) => context.emit('chart-sibling-placeholder', id), hotkey: 'Ctrl + Enter' };
          }

          if (props.nodes.some(node => node.entity.id == id && node.option.level < 3)) {
            actions.list[3] = { title: 'child', handler:(id) => context.emit('chart-child-placeholder', id), hotkey: 'Insert' };
          }

          if (props.links.some(link => link.entity.nodes_source_id == id) && id != props.root) {
            actions.list[5] = { title: option.folded ? 'expand' : 'collapse', handler:() => fold_menu(option), hotkey: option.folded ? 'Ctrl + Right' : 'Ctrl + Left' };
          }
        }
        else {
          actions.list[1] = { title: 'details', handler:(id, code) => redirect_node(code), hotkey: 'Ctrl + Click' };
          actions.list[3] = { title: 'child', handler:(id) => context.emit('chart-child-placeholder', id), hotkey: 'Insert' };
          actions.list[6] = { title: 'copy', handler:(id) => copy_node(id), hotkey: 'Ctrl + C' };
          actions.list[7] = { title: 'cut', handler:(id) => cut_node(id), hotkey: 'Ctrl + X' };
          actions.list[8] = { title: 'insert', handler:(id) => insert_node(id), hotkey: 'Ctrl + V' };
          actions.list[9] = { title: 'remove', handler:(id) => context.emit('chart-remove', id) };

          if (props.links.some(link => link.entity.nodes_target_id == id)) {
            actions.list[2] = { title: 'highlight', handler:(id) => context.emit('chart-crumb', id), hotkey: 'Shift + Click' };
            actions.list[4] = { title: 'sibling', handler:(id) => context.emit('chart-sibling-placeholder', id), hotkey: 'Ctrl + Enter' };
          }

          if (props.links.some(link => link.entity.nodes_source_id == id)) {
            actions.list[5] = { title: option.folded ? 'expand' : 'collapse', handler:() => fold_menu(option), hotkey: option.folded ? 'Ctrl + Right' : 'Ctrl + Left' };
          }
        }
      };

      const teleport_clear = () => {
        actions.id = 0;
        actions.code = "";
        actions.position = "";
        actions.list = [];
      };

      const teleport_handler = (action) => {
        action.handler(actions.id, actions.code);
        teleport_clear();
      };

      return {
        elements,
        canvas,
        motion,
        states,
        location,
        drag,
        over,
        over_y,
        over_x,
        drop,
        show,
        hide,
        status,
        fold,
        move_left,
        move_right,
        move_up,
        move_down,
        redirect_node,
        redirect_schema,
        actions,
        teleport,
        teleport_clear,
        teleport_handler,
        copy_node,
        cut_node,
        insert_node,

        insert_child: (id) => {
          if (props.mode == "wbs") {
            if(props.nodes.some(node => node.entity.id == id && node.option.level < 3)) {
              context.emit("chart-child-placeholder", id);
            }
          }
          else {
            context.emit("chart-child-placeholder", id);
          }
        },

        insert_sibling: (id) => {
          if (props.mode == "wbs") {
            if (props.links.some(link => link.entity.nodes_target_id == id) && id != props.root) {
              context.emit("chart-sibling-placeholder", id);
            }
          }
          else {
            context.emit("chart-sibling-placeholder", id);
          }
        },

        redirect: (id) => {
          if (id != 0 && id != props.root) { redirect_node(id); }
        },

        highlight: (id) => {
          if (id != 0 && id != props.root) {
            props.mode == 'wbs' ? redirect_schema(id) : context.emit("chart-crumb", id);
          }
        }
      };
    },
  };

  const MOTION_TYPE_NODE   = "node";
  const MOTION_TYPE_CANVAS = "canvas";
</script>


<style lang="scss">
  div.charts-canvas {
    position: absolute;

    .charts-canvas-links{
      width: 100%;
      height: 100%;
      position:absolute;
    }
  }
</style>