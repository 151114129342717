<template>
  <div class="d-flex">
    <div class="card simple card-wrapper box-shadow-card p-8">
      <div class="d-flex justify-content-between align-items-center">
        {{ item.name || item.title }}

        <div
          class="icon icon-close"
          @click="show_popup_remove"
        />
      </div>
  </div>

    <!-- Popup: Remove item -->
    <popup-base v-if="popup.remove">
      <template #header>
        <div>{{ $t("Remove") }}</div>
        <div class="icon icon-close c-pointer" @click="hide_popup_remove" />
      </template>

      <template #content>
        {{ $t("do_you_really_want_to_delete_this_element") }} - {{ item.name }} ?
      </template>

      <template #footer>
        <div class="button primary" @click="remove(item.id)">
          Ok
        </div>
      </template>
    </popup-base>
  </div>
</template>
  
<script>
  import {
    reactive
  } from "vue";

  import PopupBase from "../../../popups/base/Base.vue";

  export default {
    components: {
      PopupBase
    },

    props: {
      item: { type: Object, default: () => {} }
    },

    emits: [
      "remove"
    ],

    setup (props, context) {
      const popup = reactive({ remove: false});

      let show_popup_remove = function() { popup.remove = true;  };
      let hide_popup_remove = function() { popup.remove = false; };

      return {
        popup,

        show_popup_remove,
        hide_popup_remove,

        remove: (id) => {
          context.emit("remove", id);
          hide_popup_remove();
        }
      };
    }
  };
</script>