export default {
  ru: {
    Employee         : "Сотрудник",
    Employees        : "Сотрудники",
    employees        : "сотрудники",
    add_employee     : "Добавит сотрудника",
    search_employee  : "Поиск сотрудника",
    insert_employee  : "Укажите должность",
    employee_is_empty: "Пустая должность",
  },
  en: {
    Employee         : "Employee",
    Employees        : "Employees",
    employees        : "employees",
    add_employee     : "Add employee",
    search_employee  : "Search employee",
    insert_employee  : "Insert Employee",
    employee_is_empty: "Employee is empty",
  }
};