<template>
  <div class="d-flex align-items-center h-100 mr-8">
    <div v-if="folded" @click.stop="$emit('handler')">
      <div class="icon small" :class="{ 'icon-plus': !selected, 'icon-plus-hover': selected }" />
    </div>

    <div v-if="!folded" @click.stop="$emit('handler')">
      <div class="icon small" :class="{ 'icon-minus': !selected, 'icon-minus-hover': selected }" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      folded  : { type: Boolean, default: () => false },
      selected: { type: Boolean, default: () => false }
    },

    emits: [
      "handler"
    ]
  };
</script>