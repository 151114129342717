<template>
  <div :data-id="item.id || 0">
    <div class="d-flex min-w-200px">
      <widget-card-milestone-roadmap
        class="structure-item my-4 base-neutral min-w-200px"
        :class="{ 'base-soft': item.shipped}"

        :selected="project.roadmap_pick == item.id"

        :item="item"
        :menu="['details', 'strucrure', 'remove']"
        :partials="['code', 'menu', 'shipped', 'progress-period-simple', 'progress-completed', 'progress-estimate']"

        @keyup.up.exact="$emit('move_up', item)"
        @keyup.down.exact="$emit('move_down', item)"

        @keyup.enter.exact="$emit('pick', item)"
        @shipped="(milestones_id) => $emit('shipped', milestones_id)"

        @remove="$emit('remove', item.milestones_id)"

        @click.stop="$emit('pick', item)"
        @period="(start, finish) => $emit('period', item.milestones_id, start, finish)"
        @complexity="(node_id, complexity) => $emit('complexity', node_id, complexity)"
      />
    </div>
  </div>
</template>

<script>
  import WidgetCardMilestoneRoadmap from "../../../../visual/widgets/cards/milestone/Roadmap.vue";

  export default {
    components: {
      WidgetCardMilestoneRoadmap
    },

    props: {
      project: { type: Object , default: () => {} },
      item   : { type: Object , default: () => {} }
    },

    emits: [
      "pick",
      "remove",
      "complexity",
      "period",
      "shipped",
      "move_up",
      "move_down"
    ],

    setup() {
      return { };
    }
  };
</script>