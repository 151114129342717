import Server  from "../../system/server/server";
import Element from "./entity/Element";

export default {
  computed(items) {
    let bundle = 1;
    let result = [];

    items && items.forEach(item => {
      if (item.target == TARGET_TOOLBAR) {
        if (bundle < item.bundle) {
          result.push(new Element({type : "divider"}));
          bundle++;
        }
  
        item.icon = "toolbar-" + item.name.toLowerCase();
        result.push(new Element(item));
      }
    });

    return result;
  },

  async tutorial(id) {
    await Server.post("profile/tutorial", { id: id });
  },
};

const TARGET_TOOLBAR = "toolbar";