<template>
  <div class="d-flex w-100 c-pointer" @click.stop="$emit('choose', 'period')">
    <div class="p-relative w-32px h-32px">
      <div class="icon large" :class="icon_percent()" />
      <div class="icon small p-absolute t-9px l-9px" :class="icon_status()" />
    </div>

    <div
      class="d-flex w-100 h-32px border-top-1 border-right-1 border-bottom-1 border-radius-0-16-16-0"
      :class="style()"
    >
      <div class="d-flex flex-column justify-content-center align-items-center pl-2 pr-8">
        <div class="font-section" :class="style()">
          {{ format_date(finish) }}
        </div>

        <div class="font-soft">
          {{ format_date(start) }}
        </div>
      </div>

      <div
        :ref="(element) => { input = element }"
        class="diagram-edit d-flex align-items-center base-neutral font-dark h-30px my-1 border-radius-16"
        :class="{ 'transition-100': edit, 'd-none': !edit }"
        :style="{ 'opacity': 0 }"
      >
        <field-input
          v-model="fields.start"
          :class="{ 'ml-0': !edit, 'mr-0': !edit, 'ml-16': edit, 'mr-8': edit }"
          :autofocus="true"
          @keyup.stop
          @keyup.esc.stop="hide_form"
          @keyup.enter.stop="set_start"
        />

        <field-input
          v-model="fields.finish"
          :class="{ 'mr-0': !edit, 'mr-16': edit }"
          @keyup.stop
          @keyup.esc.stop="hide_form"
          @keyup.enter.stop="set_finish"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import {
    ref,
    reactive,
    onMounted,
    watch
  } from "vue";
  
  import moment from "moment";

  import FieldInput from "../../../../../fields/input/Input.vue";

  export default {
    components: {
      FieldInput
    },

    props: {
      start  : { type: String , default: () => ""    },
      finish : { type: String , default: () => ""    },
      shipped: { type: String , default: () => ""    },
      edit   : { type: Boolean, default: () => false }
    },

    emits: [
      "clear",
      "choose",
      "submit"
    ],

    setup(props, context) {
      const input = ref();

      const percent = ref(0);
      const fields  = reactive({ start: moment(props.start).format("YYYY-MM-DD"), finish: moment(props.finish).format("YYYY-MM-DD") });

      onMounted(() => recount(props.start, props.finish));
      watch([() => props.start, () => props.finish], ([start, finish]) => recount(start, finish));

      let recount = (start, finish) => {
        fields.start  = moment(start).format("YYYY-MM-DD");
        fields.finish = moment(finish).format("YYYY-MM-DD");

        let summary = moment(finish).diff(moment(start), 'days');
        let current = !props.shipped ? moment().diff(moment(start), 'days') : moment(props.shipped).diff(moment(start), 'days');

        if (moment() <= moment(start)) { percent.value = 0; }
        else { percent.value = summary == 0 ? 0 : Math.ceil((current / summary * 100) / 5) * 5; }
      };

      let icon_percent = () => {
        if (percent.value > 200) { return "progress-percent-200"; }
        else { return "progress-percent-" + percent.value; }
      };

      let icon_status = () => {
        if      (percent.value == 0)                       { return "progress-calendar-empty";    }
        else if (percent.value > 0 && percent.value < 100) { return "progress-calendar-progress"; }
        else if (percent.value == 100)                     { return "progress-calendar-full";     }
        else if (percent.value > 100)                      { return "progress-calendar-denial";   }
      };

      let style = () => {
        if      (percent.value == 0)                       { return "color-border-soft font-soft";           }
        else if (percent.value > 0 && percent.value < 100) { return "color-border-highlight font-highlight"; }
        else if (percent.value == 100)                     { return "color-border-selection font-selection"; }
        else if (percent.value > 100)                      { return "color-border-denial font-denial";       }
      };

      let format_date = (date) => {
        return moment(date).format("DD/MM");
      };

      let hide_form = () => {
        context.emit("clear");

        fields.start  = moment(props.start).format("YYYY-MM-DD");
        fields.finish = moment(props.finish).format("YYYY-MM-DD");
      };

      return {
        input,
        fields,

        icon_percent,
        icon_status,
        style,

        format_date,
        hide_form,

        choose: () => {
          context.emit("choose", "period");
        },

        set_start : () => {
          if (fields.start && moment(fields.start).isValid()) {
            context.emit("submit", moment(fields.start).format("YYYY-MM-DD"), moment(props.finish).format("YYYY-MM-DD"));
          }
          else {
            hide_form();
          }
        },

        set_finish: () => {
          if (fields.finish && moment(fields.finish).isValid()) {
            context.emit("submit", moment(props.start).format("YYYY-MM-DD"), moment(fields.finish).format("YYYY-MM-DD"));
          }

          hide_form();
        }
      };
    }

  };
</script>