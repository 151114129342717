<template>
  <div class="navbar">
    <ul class="d-none-no-important d-lg-flex m-0 p-0">
      <li
        v-for="tab in tabs"
        :key="tab.id"
        class="tab d-flex align-items-center"
        :class="{ active: tab.active }"
        @click="$emit('activate', tab)"
      >
        <div class="item d-flex flex-grow align-items-center justify-content-center c-pointer border-radius-5">
          {{ $t(tab.name) }}
        </div>
      </li>
    </ul>

    <div class="d-lg-none p-relative">
      <div
        class="icon large menu-list c-pointer"
        @click="state.dropdown = !state.dropdown"
      />

      <ul v-if="state.dropdown"
        class="dropdown d-flex flex-column m-0 p-0 p-absolute r-0 t-0 z-110"
        @mouseleave="state.dropdown = !state.dropdown"
      >
        <li
          v-for="tab in tabs"
          :key="tab.id"
          class="tab d-flex align-items-center"
          :class="{ active: tab.active }"
          @click="handle(tab)"
        >
          <div class="item d-flex flex-grow align-items-center justify-content-center c-pointer border-radius-5">
            {{ $t(tab.name) }}
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  import { reactive } from "vue";

  export default {
    props: {
      tabs: { type: Array, default: () => [] }
    },

    emits: [
      "activate"
    ],

    setup(props, context) {
      const state = reactive({ dropdown: false });

      return { state,
        handle: (tab) => {
          state.dropdown = !state.dropdown;
          context.emit("activate", tab);
        }
      };
    }
  };
</script>