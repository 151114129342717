import SYSTEM from "../system.js";

export default class Roster {
  constructor(response) {
    this.access        = false;
    this.errors        = {};
    this.status        = {};
    this.projects      = [];
    this.units         = [];
    this.roots         = [];
    this.sprints       = [];
    this.sprints_nodes = [];
    this.schema        = [];
    this.progress      = [];

    if (response) {
      if (response.access) {
        this.access = Object.values(response.access).includes(SYSTEM.MODULE);
      }

      if (response.errors) {
        this.errors = response.errors;
      }

      if (response.status) {
        this.status = response.status;
      }

      if (response.data) {
        this.projects      = response.data.projects;
        this.units         = response.data.units;
        this.roots         = response.data.roots;
        this.sprints       = response.data.sprints;
        this.sprints_nodes = response.data.sprints_nodes;
        this.schema        = response.data.schema;
        this.progress      = response.data.schema_progress;
      }
    }
  }
}