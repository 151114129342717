<template>
  <div class="d-flex overflow-y-hidden h-100 pl-60 ">
    <div v-if="!expand"
      class="w-60px min-w-60px h-100 p-absolute t-0 l-0 z-110"
      @mouseenter="expand = true"
    >
      <web-toolbar :expand="expand" :items="source.status" />
    </div>

    <div v-if="expand"
      class="w-200px h-100 p-absolute t-0 l-0 z-110"
      @mouseleave="expand = false" 
    >
      <web-toolbar :expand="expand" :items="source.status" />
    </div>

    <div class="d-flex flex-column flex-grow overflow-x-hidden">
      <div class="d-flex base-neutral w-100 h-60px min-h-60px max-h-60px z-109 box-shadow">
        <web-header :user="source.person" :items="source.status" />
      </div>

      <div class="d-flex flex-grow overflow-auto">
        <div class="d-flex flex-grow overflow-y-scroll scrollbar-disable base-light min-h-100--60px">
          <div class="d-flex flex-column flex-grow w-100" :class="{ 'p-8': !fullscreen }">
            <slot :user="source.person" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    ref,
    reactive,
    onMounted
  } from "vue";

  import WebToolbar from "./web/Toolbar.vue";
  import WebHeader  from "./web/Header.vue";

  import Web from "./Web.js";

  export default {
    components: {
      WebToolbar,
      WebHeader
    },

    props: {
      fullscreen: { type: Boolean, default: () => false }
    },

    setup () {
      const source = reactive({
        person: {},
        status: []
      });

      const expand = ref(false);

      onMounted( async () => ({ person: source.person, status: source.status } = await Web.status()) );

      return {
        source,
        expand
      };
    }
  };
</script>