<template>
  <div class="charts-mindmap-helper p-absolute r-17px b-17px z-20">
    <div
      class="icon icon-question toggle"
      :class="{ invert: value }"
      @click="$emit('handler')"
    />

    <div v-if="value" class="content">
      <div class="text">
        Фокусировка на холсте:
        <ul>
          <li>Кликните мышкой по рабочей области</li>
          <!-- <li>Ctrl + "Space"</li> -->
        </ul>

        Переместить холст:
        <ul>
          <li>Перетащите его мышкой</li>
          <!-- <li>Shift + Стрелки</li> -->
        </ul>

        Фокусировка на объекте:
        <ul>
          <li>Кликните мышкой по объекту</li>
          <!-- <li>Стрелка "&lt;" - двигаться влево</li>
          <li>Стрелка "/\" - двигаться вверх</li>
          <li>Стрелка "&gt;" - двигаться вправо</li>
          <li>Стрелка "\/" - двигаться вниз</li> -->
        </ul>

        Приближение / Выбор ветки:
        <ul>
          <li>Клавиша "Shift" + "Клик мыши" по объекту</li>
        </ul>

        Прейти на полное описание задачи:
        <ul>
          <li>Клавиша "Ctrl" + "Клик мыши"</li>
        </ul>

        Свернуть / Развернуть объект:
        <ul>
          <li>"Клик мыши" по "+"/"-" у объекта</li>
          или
          <li>Клавиша "Ctrl" + Клавиша "&#8592;" / "&#8594;"</li>
        </ul>

        Создать вложенный объект:
        <ul>
          <li>Контекстное меню <div class="d-inline-block icon icon-hierarchy" /></li>
          или
          <li>Клавиша "Insert"</li>
        </ul>

        Создать объект на том же уровне:
        <ul>
          <li>Контекстное меню <div class="d-inline-block icon icon-sibling" /></li>
          или
          <li>Клавиши Ctrl + "Enter"</li>
        </ul>

        Изменить объект:
        <ul>
          <li>"Двойной щелчок" мышью</li>
          или
          <li>Клавиша "Enter"</li>
        </ul>

        Перетащить:
        <ol>
          <li>Начните перетаскивание</li>
          <li>Переместить в целевой объект</li>
          <li>Бросить объект в заполнитель</li>
        </ol>

        Удалить объект со всеми вложенными элементами:
        <ul>
          <li>Контекстное меню <div class="d-inline-block icon icon-trash" /></li>
          <!-- или
          <li>Клавиша "Delete"</li> -->
        </ul>

        Помощь / Легенда:
        <ul>
          <li>Клавиша "H"</li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value: Boolean
    },

    emits: [
      "handler"
    ]
  };
</script>

<style lang="scss">
  div.charts-mindmap-helper {
    .toggle {
      width: 24px;
      height: 24px;
      cursor: pointer;

      &.invert {
        filter: invert(1);
      }
    }

    .content {
      position: absolute;
      right: 0;
      bottom: 24px;
      width: 300px;
      border: 1px solid #E4E4E4;
      z-index: 10;

      &.hidden {
        display: none;
      }

      .text {
        &::-webkit-scrollbar { width: 4px; }
        &::-webkit-scrollbar-track { background: #f1f1f1; }
        &::-webkit-scrollbar-thumb { background: #CCCCCC; }
        &::-webkit-scrollbar-thumb:hover { background: #AAAAAA; }

        height: 500px;
        padding: 5px;
        overflow-y: scroll;
        background: #FFFFFF;
      }
    }
  }
</style>