import SYSTEM from "../system.js";

export default class Roster {
  constructor(response) {
    this.access   = false;
    this.errors   = {};
    this.status   = {};
    this.nodes    = [];
    this.launch   = [];
    this.projects = [];

    if (response) {
      if (response.access) {
        this.access = Object.values(response.access).includes(SYSTEM.MODULE);
      }

      if (response.errors) {
        this.errors = response.errors;
      }

      if (response.status) {
        this.status = response.status;
      }

      if (response.data) {
        this.nodes    = response.data.nodes;
        this.launch   = response.data.launch;
        this.projects = response.data.projects;
      }
    }
  }
}