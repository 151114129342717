<template>
  <div v-if="source.access">
    <div v-if="source.chain.readonly"
      class="font-section font-title mb-16"
    >
      {{ source.chain.name }}
    </div>

    <field-input-combo v-else
      class="mb-20"
      :value="source.chain.name"
      :placeholder="$t('please_insert_title')"
      @submit="update"
    />

    <div class="d-flex flex-column">
      <chains-role
        :routes="source.routes"
        :units="source.units"
        @create="unit_create"
        @update="unit_update"
        @remove="unit_delete"
      />
    </div>
  </div>

  <div v-else-if="!source.access && mounted" class="failure">
    {{ $t("access_is_closed") }}
  </div>
</template>

<script>
  import {
    ref,
    reactive,
    onMounted
  } from "vue";

  import ChainsRole from "./chains/Role.vue";

  import FieldInputCombo from "../../../../visual/fields/input/combo/Combo.vue";

  import DetailEntity  from "../../entities/Detail.js";
  import DetailService from "../../services/Detail.js";
  import UnitService   from "../../services/detail/Unit.js";

  export default {
    components: {
      ChainsRole,
      FieldInputCombo
    },

    props: {
      spaces_id: { type: String, default: () => "" },
      chain_id: { type: String, default: () => "" }
    },

    setup(props) {
      const source = reactive(new DetailEntity);

      const mounted = ref(false);
      onMounted(async () => {
        Object.assign(source, await DetailService.load(props.spaces_id, props.chain_id));
        mounted.value = true;
      });

      return {
        source,
        mounted,

        update: async (value) => Object.assign(
          source,
          await DetailService.update(props.chain_id, {
            spaces_id: props.spaces_id,
            value    : value,
            field    : "name"
          })
        ),

        unit_create: async (unit_id, step, weight, is_default) => Object.assign(
          source,
          await UnitService.create({
            spaces_id                : props.spaces_id,
            chains_id                : props.chain_id,
            unit_id                  : unit_id,
            step                     : step,
            weight                   : weight,
            default                  : is_default,
            chains_points_previous_id: source.routes.length > 0 ? source.routes[source.routes.length-1].chains_points_id : null
          })
        ),

        unit_update: async (route_id, unit_id, step, weight, is_default) => Object.assign(
          source,
          await UnitService.update(route_id, {
            spaces_id: props.spaces_id,
            unit_id  : unit_id,
            step     : step,
            weight   : weight,
            default  : is_default
          })
        ),

        unit_delete: async (id) => Object.assign(source, await UnitService.delete(id))
      };
    }
  };
</script>