export default {
  ru: {
    Role    : "Роль",
    Roles   : "Роли",
    roles   : "роли",
    add_role: "Создать роль",
  },
  en: {
    Role    : "Role",
    Roles   : "Roles",
    roles   : "roles",
    add_role: "Add role",
  }
};