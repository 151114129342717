export default class File {
    constructor(response) {
      this.files = [];
  
      if (response) {
        if (response.data) {
          this.files = response.data.files;
        }
      }
    }
  }