<template>
  <div
    :ref="(element) => { dom.base = element }"
    class="tracker d-flex p-relative w-100 h-100 align-items-center"
  >
    <div
      class="content d-flex w-100"
      :class="{'pause': source.follow}"
    >
      <!-- <div
        v-if="aux.node.id"
        class="toogle icon icon-exclamation mx-10 c-pointer"
        @click="switch_list('excuses')"
      /> -->

      <div v-if="source.follow" class="d-flex align-self-center launch icon icon-pause c-pointer" @click="follow()" />
      <div v-else class="d-flex align-self-center launch icon icon-play c-pointer" @click="follow()" />

      <div class="time d-flex align-items-center">
        <div class="title">
          {{ aux.node.spent }}
        </div>
      </div>

      <div class="node">
        <router-link v-if="aux.node.id > 0"
          :to="'/node/' + aux.node.code"
          class="title text-decoration-none"
        >
          {{ aux.node.title }}
        </router-link>

        <div v-else
          class="title text-decoration-none"
        >
          {{ aux.node.title }}
        </div>

        <div class="path">
          {{ aux.node.path }}
        </div>
      </div>

      <div
        class="d-flex align-self-center toogle icon menu-list c-pointer"
        @click="switch_list('list')"
      />
    </div>

    <div
      :ref="(element) => { dom.details = element }"
      class="details p-absolute"
      :class="{'visible': aux.show_list}"
    >
      <div class="list box-shadow-bottom overflow-y-scroll max-h-500px scrollbar">
        <div class="font-memo font-soft">
          {{ $t('Tasks_available_today_tomorrow') }}
        </div>

        <div v-for="node in aux.list"
          :key="node"
          class="node d-flex c-pointer"
          @click="activate(node)"
        >
          <div class="name flex-grow">
            <div class="title">
              <div class="d-flex">
                <div class="short-title">
                  {{ node.title }}
                </div>

                <div v-if="node.type" class="font-light ml-5">
                  ({{ node.time }})
                </div>
              </div>

              <div v-if="node.type" class="d-flex font-light font-memo">
                <div>{{ $t(node.type) }}</div>

                <div v-if="node.deadline" class="ml-5">
                  ( {{ node.deadline }} )
                </div>
              </div>
            </div>

            <div class="path">
              {{ node.path }}
            </div>
          </div>

          <div class="time">
            <div class="title">
              {{ node.spent }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      :ref="(element) => { dom.excuses = element }"
      class="details p-absolute"
      :class="{'visible': aux.show_excuses}"
    >
      <div class="list">
        <div v-for="excuse in source.excuses"
          :key="excuse"
          class="node d-flex c-pointer"
          @click="capture(excuse)"
        >
          <div class="title">
            {{ excuse.title }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    ref,
    reactive,
    onMounted
  } from "vue";

  import Mediator from "../../../system/mediator/Mediator";

  import TrackerEntity   from "./entities/Tracker.js";
  import NodeEntity      from "./entities/Node.js";
  import TrackerService  from "./services/Tracker.js";
  import TimingService   from "./services/Timing.js";
  import CapturesService from "../../../app/excuses/services/Captures.js";

  const SECONDS_IN_MINUTE      = 60;
  const MILLISECONDS_IN_MINUTE = 60000;
  const RELOAD_TIMER_INTERVAL  = 10;

  export default {
    setup() {
      const source = reactive(new TrackerEntity);

      //@INFO: Refs to DOM elements
      const dom = reactive({
        base   : ref(),
        details: ref(),
        excuses: ref()
      });

      //@INFO: Auxiliary data
      const aux = reactive({
        node        : {},
        list        : [],
        show_list   : false,
        show_excuses: false,
        interval    : 0
      });

      let reload = async () => {
        aux.node = new NodeEntity(source.active);
        aux.list = source.assignee.map(node => new NodeEntity(node));

        if (source.follow) {
          setTimeout(update, MILLISECONDS_IN_MINUTE);
        }
      };

      onMounted(async () => {
        Mediator.define("tracker:reload", async () => {
          Object.assign(source, await TrackerService.assignee());
          reload();
        });

        Object.assign(source, await TrackerService.assignee());
        reload();
      });

      let update = async () => {
        if (source.follow) {
          if (aux.interval === RELOAD_TIMER_INTERVAL) {
            aux.interval = 0;

            Object.assign(source, await TrackerService.assignee());
            reload();
          }
          else {
            aux.interval++;

            source.active.timing_duration = +source.active.timing_duration + SECONDS_IN_MINUTE;
            aux.node = new NodeEntity(source.active);

            setTimeout(update, MILLISECONDS_IN_MINUTE);
          }
        }
      };

      let switch_list = (type) => {
        if (type == "list") {
          aux.show_list    = !aux.show_list;
          aux.show_excuses = false;
        }
        else {
          aux.show_list    = false;
          aux.show_excuses = !aux.show_excuses;
        }
      };

      return { source, dom, aux,
        switch_list,

        follow: async () => {
          if (source.active && source.active.id !== undefined) {
            if (source.follow) {
              await TimingService.finish(source.active.id, source.active.role_state_id);
            }
            else {
              await TimingService.launch(source.active.id, source.active.role_state_id);
            }

            Object.assign(source, await TrackerService.assignee());
            reload();
          }
        },

        activate: async (element) => {
          aux.show_list = !aux.show_list;

          let node = source.assignee.find( node => +node.id === +element.id );

          Object.assign(source, await TrackerService.activate(node.status_state_id));

          reload();
        },

        capture: async (excuse) => {
          let response = await CapturesService.create({
            excuses_id: excuse.id,
            nodes_id  : aux.node.id
          });

          window.location.href = "/excuses/captures/" + response.captures[response.captures.length - 1].id;
        }
      };
    }
  };
</script>