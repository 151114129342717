<template>
  <layout-web v-slot="slotProps" fullscreen>
    <partial-filter>
      <template #content>
        <widget-projects
          :projects="filter.projects"
          @activate="project_active"
          @priority="priority"
        />

        <div class="d-flex align-items-center">
          <widget-filter-status
            name="ideas"
            icon="idea"
            :item="{ id: 'filter_ideas', status: source.filters.ideas }"
            @handler="filter_status"
          />

          <widget-filter-status
            class="ml-8"
            name="tasks"
            icon="work"
            :item="{ id: 'filter_tasks', status: source.filters.tasks }"
            @handler="filter_status"
          />

          <widget-filter-status
            class="ml-8"
            name="completed"
            icon="finish"
            :item="{ id: 'filter_archived', status: source.filters.archived }"
            @handler="filter_status"
          />

          <field-search
            class="max-w-400px ml-8"
            :value="filter.search"
            @search="search"
          />

          <widget-filter-structure v-if="filter.active"
            class="ml-8"
            :item="{ id: filter.active.id, status: filter.active.branch }"
            @handler="structure_show"
          />

          <widget-filter-sort v-if="filter.active"
            class="ml-8"
            :item="{ id: filter.active.id, sort: filter.active.sort }"
            @handler="nodes_sort"
          />

          <widget-filter
            class="ml-8"
            :workers="{ selected: source.filters.workers, users: source.users }"
            :projects="source.projects"
            @workers_handler="filter_workers"
            @projects_handler="project_show"
          />
        </div>
      </template>
    </partial-filter>

    <partial-content :project="filter.active" :complex="true" :background="false">
      <template #content>
        <content-partial
          :project="filter.active"
          :parent="source.parent"
          :nodes="source.nodes"
          :cycles="source.cycles"
          :periods="source.periods"
          :selected="selected"

          @structure="structure"
          @structure_fold="structure_fold"
          @structure_pick="structure_pick"
          @nodes_sort="nodes_sort"

          @create="create"
          @create_bundle="create_bundle"

          @set_accesses="set_accesses"

          @remove="remove_node"
          @finish="finish_node"
          @insert="insert_node"

          @onward="onward"
          @period="period"
          @detail="show_popups_detail"
          @cancel="hide_popups_detail"
          @dragstart="dragstart"
          @dragdrop="dragdrop"
        />
      </template>
    </partial-content>

    <!-- Popup: Node details -->
    <popup-sidebar v-if="popups.detail">
      <template #header>
        <div
          class="font-title c-pointer"
          @click="redirect(popups.detail.code)"
        >
          {{ popups.detail.header }}
        </div>

        <div
          class="font-highlight lh-16 c-pointer"
          @click="hide_popups_detail"
        >
          {{ $t("close") }}
        </div>
      </template>

      <template #content>
        <node-plugin
          :node_id="popups.detail.id"
          :code_id="popups.detail.code"
          :user_id="slotProps.user.id"
        />
      </template>

      <template #footer />
    </popup-sidebar>
  </layout-web>
</template>

<script>
  import {
    ref,
    reactive,
    onMounted,
    defineAsyncComponent
  } from "vue";

  import Mediator from "../../../system/mediator/Mediator";

  import LayoutWeb      from "../../../layouts/Web.vue";
  import ContentPartial from "./partials/Content.vue";

  import PartialFilter  from "../../../visual/partials/Filter.vue";
  import PartialContent from "../../../visual/partials/Content.vue";

  import PopupSidebar from "../../../visual/popups/sidebar/Sidebar.vue";

  import FieldSearch from "../../../visual/fields/search/Search.vue";

  import WidgetFilterStatus    from "../../../visual/widgets/filter/Status.vue";
  import WidgetFilter          from "../../../visual/widgets/filter/Filter.vue";
  import WidgetFilterSort      from "../../../visual/widgets/filter/Sort.vue";
  import WidgetFilterStructure from "../../../visual/widgets/filter/Structure.vue";
  import WidgetProjects        from "../../../visual/widgets/projects/Projects.vue";

  import RosterEntity  from "../entities/Roster.js";
  import RosterService from "../services/Roster.js";

  export default {
    components: {
      LayoutWeb,
      ContentPartial,
      PartialFilter,
      PartialContent,
      PopupSidebar,
      FieldSearch,
      WidgetFilterStatus,
      WidgetFilterSort,
      WidgetFilterStructure,
      WidgetProjects,
      WidgetFilter,
      NodePlugin: defineAsyncComponent(() => import('../../node/plugins/roster/Roster.vue'))
    },

    setup() {
      const source = reactive(new RosterEntity);
      const popups = reactive({ detail: false });
      const filter = reactive({ search: "", projects: [], active: {} });

      const selected = ref(0);

      onMounted(async () => {
        Object.assign(source, await RosterService.load());
        refresh();

        Mediator.define("tasks:reload", async () => {
          Object.assign(source, await RosterService.load());
          refresh();
        });
      });

      let refresh = () => {
        filter.projects = source.projects.filter(item => item.hidden != 1);
        filter.active   = filter.projects.find(item => item.hidden != 1 && item.active);
      };

      let show_popups_detail = (data) => {
        popups.detail = data;
        selected.value = popups.detail.id;
      };

      let hide_popups_detail = () => {
        if (popups.detail.id) {
          popups.detail = false;
          selected.value = 0;
        }
        else {
          let element = document.querySelector("[data-id='" + (filter.active.structure_pick || 0) + "']");
          let item = element && element.childNodes.length && element.getElementsByClassName("structure-item")[0];
          if (item) {
            item.focus();
          }
        }
      };

      let dragstart = (event, project, node) => {
        event.dataTransfer.dropEffect    = "move";
        event.dataTransfer.effectAllowed = "move";
        event.dataTransfer.setData("project_id", project.id );
        event.dataTransfer.setData("node_id", node.id );
      };

      let redirect = (id) => window.location.href = "/node/" + id;

      return {
        source,
        popups,
        filter,

        selected,

        show_popups_detail,
        hide_popups_detail,

        redirect,

        create: async (project_id, root_id, title) => {
          Object.assign(source, await RosterService.create(project_id, root_id, title, filter.search));
          refresh();
        },

        create_bundle: async (project_id, root_id, title) => {
          Object.assign(source, await RosterService.create_bundle(project_id, root_id, title, filter.search));
          refresh();
        },

        remove_node: async (node_id) => {
          Object.assign(source, await RosterService.remove(node_id));
          refresh();
        },

        finish_node: async (node_id) => {
          Object.assign(source, await RosterService.finish(node_id));
          refresh();
        },

        insert_node: async (source_id, target_id, action, project_id = null) => {
          if (action == "copy") {
            Object.assign(source, await RosterService.insert({ source_id: source_id, target_id: target_id, project_id: project_id, keyword: filter.search}));
          }
          else if (action == "cut") {
            Object.assign(source, await RosterService.move({ target: target_id, source: source_id, keyword: filter.search }));
          }
          refresh();
        },

        onward: async (data) => {
          Object.assign(source, await RosterService.onward({ node_id: data.node_id, cycle_id: data.cycle_id, keyword: filter.search }));
          refresh();
        },

        dragstart,
        dragdrop: async (event, project, node) => {
          if ((event.dataTransfer.getData("node_id") != (node && node.id)) && event.dataTransfer.getData("project_id") == project.id) {
            Object.assign(source, await RosterService.move({ target: event.dataTransfer.getData("node_id"), source: node && node.id, keyword: filter.search }));
            refresh();
          }
        },

        project_active: async (project) => {
          Object.assign(source, await RosterService.states(project.id, "project_active", project.id, filter.search));
          refresh();
        },

        project_show: async (data) => {
          Object.assign(source, await RosterService.states(data.id, "project_show", data.status, filter.search));
          refresh();
        },

        structure_show: async (data) => {
          Object.assign(source, await RosterService.states(data.id, "structure_show", +!data.status, filter.search));
          refresh();
        },

        structure_fold: async (id, node) => {
          Object.assign(source, await RosterService.states(id, "structure_fold", node.id, filter.search));
          refresh();
        },

        structure_pick: async (id, node, activate = false) => {
          await Object.assign(source, await RosterService.states(id, "structure_pick", node.id, filter.search));
          refresh();

          if (activate) {
            let item = document.querySelector("[data-offset='1']");
            if (item) { item.focus(); }
          }
        },

        filter_status: async (status, value) => {
          Object.assign(source, await RosterService.states(null, status, value, filter.search));
          refresh();
        },

        filter_workers: async (value) => {
          Object.assign(source, await RosterService.states(null, "filter_workers", value, filter.search));
          refresh();
        },

        nodes_sort: async (id, value) => {
          Object.assign(source, await RosterService.states(id, "sort", value, filter.search));
          refresh();
        },

        priority: async (event) => {
          if (event.moved) {
            Object.assign(source, await RosterService.priority(filter.projects.map(project => { return { id: project.id }; }), filter.search));
            refresh();
          }
        },

        structure: async (id, target_id, source_id, offset) => {
          Object.assign(source, await RosterService.structure(id, target_id, source_id, offset, filter.search));
          refresh();
        },

        period: async (data) => {
          Object.assign(source, await RosterService.period({
            project_id: filter.active.id,
            node_id   : data.node_id,
            period_id : data.period_id,
          }));
          refresh();
        },

        search: async (keyword) => {
          filter.search = keyword;
          Object.assign(source, await RosterService.search(keyword));
          refresh();
        },

        set_accesses: async (id, type) => { Object.assign(source, await RosterService.access(id, type)); },
      };
    }
  };
</script>