import SYSTEM from "../system.js";

export default class Roster {
  constructor(response) {
    this.access  = false;
    this.errors  = {};
    this.profile = {};

    if (response) {
      if (response.access) {
        this.access = Object.values(response.access).includes(SYSTEM.MODULE);
      }

      if (response.errors) {
        this.errors = response.errors;
      }

      if (response.data) {
        this.profile = response.data.profile;
      }
    }
  }
}