<template>
  <div class="d-flex w-100" @click.stop>
    <div class="p-relative w-32px h-32px">
      <div class="icon large" :class="icon_percent()" />
      <div class="icon small p-absolute t-9px l-9px" :class="icon_status()" />
    </div>

    <div
      class="d-flex w-100 h-32px border-top-1 border-right-1 border-bottom-1 border-radius-0-16-16-0"
      :class="style()"
    >
      <div class="d-flex flex-column justify-content-center align-items-center pl-2 pr-8">
        <div class="font-section" :class="style()">
          {{ complete || 0 }}
        </div>

        <div class="font-soft">
          {{ tasks || 0 }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    ref,
    onMounted
  } from "vue";
  
  export default {
    props: {
      tasks   : { type: Number, default: () => 0  },
      complete: { type: Number, default: () => 0  }
    },

    setup(props) {
      const percent = ref(0);

      onMounted(() => {
        if (props.tasks) { percent.value = Math.ceil((props.complete / props.tasks * 100) / 5) * 5; }
      });

      let icon_percent = () => {
        if (percent.value > 200) { return "progress-percent-200"; }
        else { return "progress-percent-" + percent.value; }
      };

      let icon_status = () => {
        if      (percent.value == 0)                       { return "progress-completed-empty";    }
        else if (percent.value > 0 && percent.value < 100) { return "progress-completed-progress"; }
        else if (percent.value == 100)                     { return "progress-completed-full";     }
        else if (percent.value > 100)                      { return "progress-completed-denial";   }
      };

      let style = () => {
        if      (percent.value == 0)                       { return "color-border-soft font-soft";           }
        else if (percent.value > 0 && percent.value < 100) { return "color-border-highlight font-highlight"; }
        else if (percent.value == 100)                     { return "color-border-selection font-selection"; }
        else if (percent.value > 100)                      { return "color-border-denial font-denial";       }
      };

      return {
        icon_percent,
        icon_status,
        style
      };
    }
  };
</script>