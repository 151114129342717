export default {
  ru: {
    Project            : "Проект",
    Projects           : "Проекты",
    Details            : "Детали",
    add_project        : "Создать проект",
    insert_name        : "Укажите имя",
    name_is_empty      : "Пустое имя",
    insert_organization: "Укажите Организацию",
    code               : "Код",
    code_required      : "Код *",
    code_is_empty      : "Пустой код",
    max_15             : "Максимум 15 символов",
  },
  en: {
    Project            : "Project",
    Projects           : "Projects",
    Details            : "Details",
    add_project        : "Add Project",
    insert_name        : "Insert Name",
    name_is_empty      : "Name is empty",
    insert_organization: "Insert Organization",
    code               : "Code",
    code_required      : "Code *",
    code_is_empty      : "Code is empty",
    max_15             : "Max_15_characters",
  }
};