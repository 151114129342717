<template>
  <div class="font-memo short-text">
    {{ $t("type") }}: {{ $t(type) }}
  </div>
</template>

<script>
  export default {
    props: {
      type: { type: String, default: () => "" }
    }
  };
</script>