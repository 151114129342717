<template>
  <layout-web v-slot="slotProps" :fullscreen="true">
    <div class="d-flex min-h-40px m-8">
      <div class="charts-plugin-crumbs d-flex flex-grow align-items-center" />
      <div class="charts-plugin-filter d-flex align-items-center" />
    </div>

    <div class="d-flex flex-grow flex-nowrap base-neutral">
      <div class="overflow-hidden flex-grow d-flex flex-column p-8">
        <chart-mindmap
          :nodes="source.nodes"
          :links="source.links"
          :root="+filter.root"
          :path="source.path"

          :active="filter.active"
          :selected="selected"

          :plugin_helper="'.charts-plugin-helper'"
          :plugin_helper_opened="popups.helper"
          :plugin_crumbs="'.charts-plugin-crumbs'"
          :plugin_filter="'.charts-plugin-filter'"
          :plugin_filter_values="filter.filters"

          @move="move_node"
          @child="create_node_child"
          @sibling="create_node_sibling"
          @remove="delete_bundle"
          @fold="update_node_option"

          @select="show_popups_detail"
          @focused="focused"
          @cancel="hide_popups_detail"
          @insert="insert_node"

          @child_placeholder="child_placeholder"
          @sibling_placeholder="sibling_placeholder"
          @cancel_placeholder="cancel_placeholder"

          @plugin_helper_handler="popups.helper = !popups.helper"
          @plugin_crumb_handler="update_root"
          @plugin_filter_handler="reload_filters"
        />
      </div>

      <!-- Popup: Node details -->
      <popup-sidebar v-if="popups.detail.entity && popups.detail.entity.id">
        <template #header>
          <div
            class="font-title c-pointer"
            @click="redirect(popups.detail.entity.code)"
          >
            #{{ popups.detail.entity.id }}
          </div>

          <div>
            <div v-if="popups.detail.entity.state_id && popups.detail.entity.state_routes_id"
              class="button disabled status-success"
            >
              {{ $t("in_work") }}
            </div>

            <div v-else-if="popups.detail.entity.state_id && !popups.detail.entity.state_routes_id"
              class="button disabled status-completed"
            >
              {{ $t("completed") }}
            </div>

            <div v-else-if="source.leaves.includes(popups.detail.entity.id) && popups.detail.entity.cycles_id"
              class="button status-neutral hover"
              @click="onward_node"
            >
              {{ $t("to_work") }}
            </div>
          </div>

          <div
            class="font-highlight lh-16 c-pointer"
            @click="hide_popups_detail"
          >
            {{ $t("close") }}
          </div>
        </template>

        <template #content>
          <node-plugin
            type="schema"
            :node_id="popups.detail.entity.id"
            :code_id="popups.detail.entity.code"
            :user_id="slotProps.user.id"
          />

          <chart-plugin-picker
            @handler="update_node_style"
          />
        </template>

        <template #footer />
      </popup-sidebar>
    </div>
  </layout-web>
</template>

<script>
  import {
    ref,
    reactive,
    onMounted,
    defineAsyncComponent
  } from "vue";

  import Mediator from "../../../system/mediator/Mediator";

  import Config from "../../../config.js";

  import ChartMindmap      from "../../../system/chart/Chart.vue";
  import ChartPluginPicker from "../../../system/chart/plugin/Picker.vue";
  import ChartEntityBundle from "../../../system/chart/entities/Bundle.js";

  import LayoutWeb from "../../../layouts/Web.vue";

  import PopupSidebar from "../../../visual/popups/sidebar/Sidebar.vue";

  import DetailEntity  from "../entities/Detail.js";
  import DetailService from "../services/Detail.js";

  export default {
    components: {
      LayoutWeb,
      PopupSidebar,
      ChartMindmap,
      ChartPluginPicker,
      NodePlugin: defineAsyncComponent(() => import('../../node/plugins/roster/Roster.vue'))
    },

    props: {
      code: { type: String, default: () => "" }
    },

    setup(props) {
      const scope  = ref(Config.SCOPE_NODE_ID);
      const count  = ref(0);
      const source = reactive(new DetailEntity);
      const popups = reactive({ detail: false, helper: false });
      const filter = reactive({
        active : { entity: {} },
        root   : 0,
        filters: { progress: 1, archived: 1 }
      });

      const selected = ref(0);

      onMounted(async () => {
        Object.assign(source, await DetailService.load(props.code, filter.filters));

        filter.root = source.nodes[0].entity.id;

        Mediator.define("schema:reload", async () => {
          let root = source.nodes.find(node => node.entity.id = filter.root);
          Object.assign(source, await DetailService.load(root.entity.code, filter.filters));
          refresh();
        });
      });

      let refresh = () => {
        let node = source.nodes.find(node => node.entity.id == popups.detail.entity.id);
        popups.detail.entity = node.entity;
      };

      let show_popups_detail = (node) => {
        popups.detail = node;
        selected.value = popups.detail.entity.id;

        filter.active = node;
        document.querySelector("[data-id='" + filter.active.entity.id + "']").focus();
      };

      let hide_popups_detail = () => {
        popups.detail = false;
        selected.value = 0;
      };

      let redirect = (id) => window.location.href = "/node/" + id;

      function child_placeholder(target) {
        let source_link = source.links.find(link => link.entity.nodes_target_id == target);
        let source_node = source.nodes.find(node => node.entity.id == target);

        source.links = source.links.filter(link => link.entity.id != 0);
        source.nodes = source.nodes.filter(node => node.entity.id != 0);

        let bundle = reactive(new ChartEntityBundle);

        bundle.link.entity.nodes_root_id   = source_link && source_link.entity.nodes_root_id || target;
        bundle.link.entity.nodes_source_id = target;
        bundle.node.entity.target          = target;
        bundle.node.entity.type            = "child";
        bundle.node.option.level           = source_node.option.level + 1;
        bundle.node.option.offset          = Date.now();

        source.links.push(bundle.link);
        source.nodes.push(bundle.node);
      }

      function sibling_placeholder(target) {
        let source_link = source.links.find(link => link.entity.nodes_target_id == target);
        let source_node = source.nodes.find(node => node.entity.id == target);

        source.links = source.links.filter(link => link.entity.id != 0);
        source.nodes = source.nodes.filter(node => node.entity.id != 0);

        let bundle = reactive(new ChartEntityBundle);

        bundle.link.entity.nodes_root_id   = source_link && source_link.entity.nodes_root_id || target;
        bundle.link.entity.nodes_source_id = source_link && source_link.entity.nodes_source_id || null;
        bundle.node.entity.target          = target;
        bundle.node.entity.type            = "sibling";
        bundle.node.option.level           = source_node.option.level;
        bundle.node.option.offset          = source_node.option.offset + 0.5 + count.value;

        source.links.push(bundle.link);
        source.nodes.push(bundle.node);
      }

      function cancel_placeholder() {
        count.value = 0;
        source.links = source.links.filter(link => link.entity.id != 0);
        source.nodes = source.nodes.filter(node => node.entity.id != 0);

        if (filter.active.entity.id) {
          document.querySelector("[data-id='" + filter.active.entity.id + "']").focus();
        }
      }

      return {
        source,
        popups,
        filter,
        scope,

        selected,

        show_popups_detail,
        hide_popups_detail,

        redirect,

        child_placeholder,
        sibling_placeholder,
        cancel_placeholder,

        focused: async (node) => { filter.active = node; },

        create_node_child: async (target) => {
          Object.assign(source, await DetailService.create_node_child(filter.root, filter.filters, target));
          child_placeholder(target.id);
        },

        create_node_sibling: async (target) => {
          let count_old = source.nodes.length;
          Object.assign(source, await DetailService.create_node_sibling(filter.root, filter.filters, target));
          let count_new = source.nodes.length;

          if (count_old != count_new + 1) {
            count.value++;
          }

          sibling_placeholder(target.id);
        },

        update_node_option: async (option) => {
          Object.assign(source, await DetailService.update_node_option(filter.root, filter.filters, option));
        },

        update_node_style: async (style) => {
          style.node_id = filter.active.entity.id;
          Object.assign(source, await DetailService.update_node_style(filter.root, filter.filters, style));
        },

        delete_bundle: async (id) => {
          if (filter.root == id) {
            await DetailService.delete_bundle(filter.root, filter.filters, id);
            history.back();
            window.location.href = document.referrer + '?date=' + new Date().valueOf();
          }
          else {
            Object.assign(source, await DetailService.delete_bundle(filter.root, filter.filters, id));
          }
        },

        move_node: async (data) => {
          Object.assign(source, await DetailService.move_node(filter.root, filter.filters, data.node_id, data.target_id, data.location));
        },

        onward_node: async () => {
          Object.assign(source, await DetailService.onward_node(filter.root, filter.filters, filter.active.entity));
        },

        insert_node: async (source_id, target_id, action) => {
          if (action == "copy") {
            Object.assign(source, await DetailService.insert(filter.root, source_id, target_id));
          }
          else if (action == "cut") {
            Object.assign(source, await DetailService.move_node(filter.root, filter.filters, target_id, source_id, "LOCATION_CHILD"));
          }
          refresh();
        },

        update_root: async (root) => {
          Object.assign(source, await DetailService.update_root(root, filter.filters));
          filter.root = source.nodes[0].entity.id;
        },

        reload_filters: async (filters) => {
          filter.filters = filters;

          let root = source.nodes.find(node => node.entity.id = filter.root);
          Object.assign(source, await DetailService.load(root.entity.code, filters));
        }
      };
    }
  };
</script>