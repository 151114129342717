<template>
  <div class="d-flex flex-column justify-content-center align-items-center w-100" @keydown="submit">
    <field-input
      v-model="name"
      class="mb-10"
      :placeholder="$t('name')"
      :message="$t('name_is_empty')"
      :failure="failures.name"
    />

    <field-input
      v-model="lastname"
      class="mb-10"
      :placeholder="$t('lastname')"
      :message="$t('lastname_is_empty')"
      :failure="failures.lastname"
    />

    <field-input
      v-model="email"
      class="mb-10"
      :placeholder="$t('email')"
      :message="$t('email_is_empty')"
      :failure="failures.email"
      @update="updateEmail"
    />

    <field-input
      v-model="password"
      class="mb-10"
      type="password"
      :placeholder="$t('password')"
      :message="$t('password_is_empty')"
      :failure="failures.password"
    />

    <field-confirm
      v-model="confirm"
      type="password"
      :placeholder="$t('confirm_password')"
      :message="$t('password_not_confirmed')"
      :source="password"
      :failure="failures.confirm"
    />

    <div class="button primary my-10 w-100--16px" @click="submit">
      {{ $t("create_new_account") }}
    </div>
  </div>
</template>

<script>
  import { reactive, ref } from "vue";

  import FieldInput   from "../../../../visual/fields/input/Input.vue";
  import FieldConfirm from "../../../../visual/fields/confirm/Confirm.vue";

  export default {
    components: {
      FieldInput,
      FieldConfirm
    },

    emits: [
      "registration"
    ],

    setup (props, context) {
      const name     = ref("");
      const lastname = ref("");
      const email    = ref("");
      const password = ref("");
      const confirm  = ref("");

      const failures = reactive({
        name    : false,
        lastname: false,
        email   : false,
        password: false,
        confirm : false
      });

      const EVENT_CLICK   = ref("click");
      const KEYCODE_ENTER = ref(13);

      return {
        name,
        lastname,
        email,
        password,
        confirm,
        failures,

        submit: (event) => {
          if (event.type == EVENT_CLICK.value || event.keyCode === KEYCODE_ENTER.value) {
            if (name.value != "" && lastname.value != "" && email.value != "" && password.value != "" && confirm.value  == password.value) {
              failures.name     = false;
              failures.lastname = false;
              failures.email    = false;
              failures.password = false;
              failures.confirm  = false;

              context.emit("registration", {
                name    : name.value,
                lastname: lastname.value,
                email   : email.value,
                password: password.value
              });

              localStorage.setItem("prompt", true);
            } else {
              failures.name     = name.value     == ""             ? true : false;
              failures.lastname = lastname.value == ""             ? true : false;
              failures.email    = email.value    == ""             ? true : false;
              failures.password = password.value == ""             ? true : false;
              failures.confirm  = confirm.value  != password.value ? true : false;
            }
          }
        }
      };
    }
  };
</script>