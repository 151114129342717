<template>
  <div class="d-flex">
    <div class="icon mr-8" :class="{ 'icon-deadline': !selected, 'icon-deadline-hover': selected }" />

    <div class="lh-18" :class="{ 'font-soft': !selected, 'font-neutral': selected }">
      {{ format(date) }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      date    : { type: String, default: () => "" },
      selected: { type: Boolean, default: () => false }
    },

    setup() {
      return {
        format: (date) => {
          return new Date(date).toLocaleDateString("ru-RU", { dateStyle: "long" });
        }
      };
    }
  };
</script>