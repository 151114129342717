<template>
  <div class="p-absolute t--7px r--7px z-105">
    <div
      class="menu box-shadow-bottom z-105"
      @click.stop="dropdown = !dropdown"
    >
      <div class="icon menu-context" />
    </div>

    <div v-if="dropdown"
      class="d-flex flex-column p-absolute border-radius-16 t-0 r-0 min-w-150px max-w-200px p-5 base-neutral box-shadow-bottom z-105"
      @mouseleave="dropdown = !dropdown"
    >
      <div v-for="action in actions" :key="action">
        <div
          class="d-flex justify-content-between my-5 p-5 border-radius-5 hover-base-light c-pointer"
          @click.stop="handler(action)"
        >
          <div>{{ $t(action.title) }}</div> 

          <div v-if="action.hotkey" class="font-light">
            {{ $t(action.hotkey) }}
          </div> 
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";

  export default {
    props: {
      item   : { type: Object, default: () => {} },
      actions: { type: Array , default: () => [] }
    },

    setup(props) {
      const dropdown = ref(false);

      return {
        dropdown,

        handler: (action) => {
          action.handler(props.item);
          dropdown.value = !dropdown.value;
        }
      };
    }
  };
</script>

<style lang="scss" scoped>
  .card-wrapper {
    .menu {
      display: none;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      border: 1px solid;
      border-color: #FFFFFF;
      background-size: auto;
      background-color: #FFFFFF;
      cursor: pointer;

      &:hover {
        border-color: #DEDEDE;
      }
    }

    &:hover {
      .menu { display: flex; }
    }
  }
</style>