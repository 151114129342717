<template>
  <div
    class="short-text"
    :class="{
      'font-dark': !selected,
      'font-neutral': selected
    }"
  >
    {{ project }}
  </div>
</template>

<script>
  export default {
    props: {
      project : { type: String , default: () => ""    },
      selected: { type: Boolean, default: () => false }
    }
  };
</script>