<template>
  <div
    class="overview z-101 base-neutral"
    :style="overview"

    @dragover.prevent="over"
    @dragenter.prevent
  >
    <svg class="overview-link-canvas">
      <path v-for="link in links"
        :key="link.entity.id"
        :class="'overview-link overview-link-' + link.entity.id"

        :d="layout.links && layout.links[link.entity.id]"

        fill="none"
        stroke="black"
        stroke-width="1"
      />
    </svg>

    <charts-node v-for="node in nodes"
      :key="node.entity.id"
      :node="node"
      :links="links"
      :layout="layout"
    />

    <div
      ref="window"

      class="viewport"
      draggable="true"

      :style="location"

      @dragend="drop"
      @dragstart="drag"
      @dragover.prevent="over"
      @dragenter.prevent
    />
  </div>
</template>

<script>
  import {
    ref,
    reactive,
    computed,
    watch
  } from "vue";

  import Motion     from "../Motion.js";
  import ChartsNode from "./elements/Node.vue";

  export default {
    components: {
      ChartsNode,
    },

    props: {
      mode    : { type: String, default: () => "left" },
      nodes   : { type: Array , default: () => []     },
      links   : { type: Array , default: () => []     },
      layout  : { type: Object, default: () => {}     },
      region  : { type: Object, default: () => {}     },
      viewport: { type: Object, default: () => {}     }
    },

    emits: [
      "location"
    ],

    setup(props, context) {
      const window = ref();

      /**
       * Section: drag and drop
       */
      const cursor = reactive({
        x: 0,
        y: 0,
      });

      const region = reactive({
        width : props.region.width,
        height: props.region.height
      });

      watch(props.region, size => {
        region.width  = size.width;
        region.height = size.height;
      });

      const overview = computed(() => ({
        width : region.width  + "px",
        height: region.height + "px",
      }));

      const viewport = reactive({
        left  : props.viewport.left,
        top   : props.viewport.top,
        width : props.viewport.width,
        height: props.viewport.height,
      });

      watch(props.viewport, location => { 
        viewport.left   = -location.left;
        viewport.top    = -location.top;
        viewport.width  = location.width;
        viewport.height = location.height;
      });

      const location = computed(() => ({
        left  : viewport.left   + "px",
        top   : viewport.top    + "px",
        width : viewport.width  + "px",
        height: viewport.height + "px",
      }));

      const drag = function (event) {
        cursor.x = event.x;
        cursor.y = event.y;

        Motion.launch(event, window.value);
      };

      const over = function (event) {
        const limits = {
          right : region.width  - viewport.width,
          bottom: region.height - viewport.height,
        };

        let location = Motion.handle(
          viewport, cursor, event, limits, SCALE
        );

        cursor.x = event.x;
        cursor.y = event.y;

        context.emit("location", -location.left, -location.top);
      };

      const drop = function () {
        Motion.finish(window.value);
      };
      /**
       * End of Section: drag and drop
       */

      return { overview, location, window, drag, over, drop };
    },
  };

  const SCALE = 10;
</script>

<style lang="scss" scoped>
  .overview {
    left: 10px;
    bottom: 10px;

    position: absolute;
    border  : 10px solid #E5E5E5;

    transform       : scale(0.1, 0.1);
    transform-origin: bottom left;
  }

  .viewport {
    border  : 5px solid #353B3D;
    position: absolute;
  }
</style>