import "regenerator-runtime"; // https://github.com/adi518/vue-facebook-login/issues/17#issuecomment-544247970

import Server from "../../../system/server/server";

export default {
  async logout() {
    await Server.get("logout");
    document.cookie = AUTH_TOKEN + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT";
    document.location.replace(URL_HOME);
  }
};

const URL_HOME = "/";
const AUTH_TOKEN = "auth-token";