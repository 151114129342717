export default {
  ru: {
    Excuse           : "Оправдание",
    Excuses          : "Оправдания",
    Progress         : "Прогресс",
    Tracker          : "Трекер",
    Captures         : "Наблюдения",
    add_excuse       : "Добавить оправдание",
    insert_title     : "Укажите название",
    insert_solution  : "Укажите решение",
    title_is_empty   : "Пустое название",
    solution_is_empty: "Пустое решение",
    solution         : "Решение",
    solution_required: "Решение*",
    content          : "Содержимое",
    content_required : "Содержимое*",
    reason           : "Причина",
    reason_required  : "Причина*",
    color            : "Цвет",
    color_required   : "Цвет*",
  },
  en: {
    Excuse           : "Excuse",
    Excuses          : "Excuses",
    Progress         : "Progress",
    Tracker          : "Tracker",
    Captures         : "Captures",
    add_excuse       : "Add excuse",
    insert_title     : "Insert title",
    insert_solution  : "Insert solution",
    title_is_empty   : "Title is empty",
    solution_is_empty: "Solution is empty",
    solution         : "Solution",
    solution_required: "Solution*",
    content          : "Content",
    content_required : "Content*",
    reason           : "Reason",
    reason_required  : "Reason*",
    color            : "Color",
    color_required   : "Color*",
  }
};