<template>
  <div class="popup popup-sidebar box-shadow-bottom">
    <div class="popup-body h-100 overflow-y-scroll scrollbar-disable">
      <div class="popup-header d-flex justify-content-between">
        <slot name="header" />
      </div>

      <div class="popup-content">
        <slot name="content" />
      </div>

      <div class="popup-footer d-flex justify-content-end">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    setup () { return {}; }
  };
</script>