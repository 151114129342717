<template>
  <div
    class="card simple card-wrapper box-shadow-card"
    tabindex="1"
    :class="{ 'selected': selected }"
    @keydown.ctrl.c="copy_node"
    @keydown.ctrl.x="cut_node"
    @keydown.ctrl.v="insert_node"
    @click.ctrl.exact="item.id && redirect_node"
    @click.shift.exact="item.id && redirect_schema"
  >
    <div class="header p-8 c-pointer border-bottom-1 border-color-transparent">
      <div class="d-flex">
        <cards-partial-fold v-if="partials.includes('fold') && folded.access"
          :folded="folded.status"
          :selected="selected"
          @handler="$emit('fold')"
        />

        <div
          class="title short-text font-weight-500"
          :class="{
            'font-dark': !selected,
            'font-neutral': selected,
            'pl-24': partials.includes('fold') && !folded.access
          }"
        >
          {{ item.name || item.title }}
        </div>
      </div>

      <cards-partial-id v-if="partials.includes('id') && item.id"
        :id="item.id"
        :selected="selected"
      />

      <cards-partial-code v-if="partials.includes('code') && (item.id || item.code)"
        :code="item.code || item.id"
        :selected="selected"
      />
    </div>

    <div class="content p-8 pt-0" :class="{ 'pl-32': partials.includes('fold') }">
      <cards-partial-description v-if="partials.includes('description') && item.description"
        :description="item.description"
        :selected="selected"
      />

      <cards-partial-project v-if="partials.includes('project') && item.project"
        :project="item.project"
        :selected="selected"
      />

      <cards-partial-type v-if="partials.includes('type') && item.type"
        :type="item.type"
      />

      <cards-partial-path v-if="partials.includes('path') && item.path"
        :path="item.path"
        @handler="redirect_schema_code"
      />

      <div class="d-flex">
        <cards-partial-idea v-if="partials.includes('idea')"
          class="mr-8 lh-18"
          :count="item.drafts"
          :selected="selected"
        />

        <cards-partial-work v-if="partials.includes('work')"
          class="mr-8 lh-18"
          :count="item.nodes"
          :selected="selected"
        />

        <div class="d-flex align-items-center ml-8 w-100">
          <cards-partial-progress-complete v-if="partials.includes('progress-complete')"
            class="w-100"
            :complete="item.complete"
            :selected="selected"
          />
        </div>
      </div>
    </div>

    <cards-partial-menu v-if="partials.includes('menu') && menu"
      :item_id="item.id"
      :actions="actions.list"
    />

    <cards-partial-create v-if="partials.includes('create')"
      :class="{
        'r-67px-imp': partials.includes('period') && partials.includes('launch'),
        'r-30px-imp': partials.includes('period') || partials.includes('launch')
      }"
      :item="item"
      @handler="(item) => $emit('create', item)"
    />

    <cards-partial-finish v-if="partials.includes('finish')"
      :class="{'r-30px-imp': partials.includes('close')}"
      :item="item"
      @handler="(data) => $emit('finish', data)"
    />

    <cards-partial-close v-if="partials.includes('close')"
      :item="item"
      @handler="(data) => $emit('close', data)"
    />

    <cards-partial-launch v-if="partials.includes('launch') && cycles"
      :class="{'r-30px-imp': partials.includes('period')}"
      :item="item"
      :cycles="cycles"
      @handler="(data) => $emit('onward', data)"
    />

    <cards-partial-period v-if="partials.includes('period') && periods"
      :item="item"
      :periods="periods"
      @handler="(data) => $emit('period', data)"
    />

    <!-- Popup: Remove item -->
    <popup-base v-if="popup.remove">
      <template #header>
        <div>{{ $t("Remove") }}</div>
        <div class="icon icon-close c-pointer" @click="hide_popup_remove" />
      </template>

      <template #content>
        {{ $t("do_you_really_want_to_delete_this_element") }} - {{ item.name || item.title }} ?
      </template>

      <template #footer>
        <div class="button primary" @click="remove(item.id)">
          Ok
        </div>
      </template>
    </popup-base>
  </div>
</template>

<script>
  import {
    reactive,
    onMounted,
    watch
  } from "vue";

  import CardsPartialDescription      from "../partials/description/Description.vue";
  import CardsPartialProject          from "../partials/project/Project.vue";
  import CardsPartialId               from "../partials/id/Id.vue";
  import CardsPartialCode             from "../partials/code/Code.vue";
  import CardsPartialFold             from "../partials/fold/Fold.vue";
  import CardsPartialIdea             from "../partials/idea/Idea.vue";
  import CardsPartialWork             from "../partials/work/Work.vue";
  import CardsPartialType             from "../partials/type/Type.vue";
  import CardsPartialMenu             from "../partials/menu/Menu.vue";
  import CardsPartialPath             from "../partials/path/Path.vue";
  import CardsPartialProgressComplete from "../partials/progress/complete/Progress.vue";
  import CardsPartialCreate           from "../partials/create/Create.vue";
  import CardsPartialClose            from "../partials/close/Close.vue";
  import CardsPartialLaunch           from "../partials/launch/Launch.vue";
  import CardsPartialPeriod           from "../partials/period/Period.vue";
  import CardsPartialFinish           from "../partials/finish/Finish.vue";

  import PopupBase from "../../../popups/base/Base.vue";

  export default {
    components: {
      CardsPartialDescription,
      CardsPartialProject,
      CardsPartialId,
      CardsPartialCode,
      CardsPartialFold,
      CardsPartialIdea,
      CardsPartialWork,
      CardsPartialType,
      CardsPartialMenu,
      CardsPartialPath,
      CardsPartialProgressComplete,
      CardsPartialCreate,
      CardsPartialClose,
      CardsPartialLaunch,
      CardsPartialPeriod,
      CardsPartialFinish,
      PopupBase
    },

    props: {
      item    : { type: Object , default: () => {}    },
      menu    : { type: Array  , default: () => []    },
      folded  : { type: Object , default: () => {}    },
      partials: { type: Array  , default: () => []    },
      cycles  : { type: Array  , default: () => []    },
      periods : { type: Array  , default: () => []    },
      selected: { type: Boolean, default: () => false }
    },

    emits: [
      "fold",
      "close",
      "create",
      "onward",
      "finish",
      "period",
      "remove",
      "insert",
      "accesses"
    ],

    setup(props, context) {
      const actions = reactive({ list: []});
      const popup   = reactive({ remove: false });

      onMounted(() => init_menu(props.menu));
      watch(() => props.menu, async (menu) => init_menu(menu));

      let init_menu = (menu) => {
        actions.list = [];

        menu.forEach((item) => {
          if      (item === "details")   { actions.list.push({ title: "Details"  , handler: redirect_node  , hotkey: "Ctrl + Click"  }); }
          else if (item === "strucrure") { actions.list.push({ title: "Structure", handler: redirect_schema, hotkey: "Shift + Click" }); }
          else if (item === "remove")    { actions.list.push({ title: "Remove"   , handler: show_popup_remove                        }); }
          else if (item === "copy")      { actions.list.push({ title: "copy"     , handler: copy_node      , hotkey: "Click + C"     }); }
          else if (item === "cut")       { actions.list.push({ title: "cut"      , handler: cut_node       , hotkey: "Click + X"     }); }
          else if (item === "insert")    { actions.list.push({ title: "insert"   , handler: insert_node    , hotkey: "Click + V"     }); }
          else if (item === "limited")   { actions.list.push({ title: "Limited"  , handler: set_limited                              }); }
          else if (item === "shared")    { actions.list.push({ title: "Shared"   , handler: set_shared                               }); }
          else                           { actions.list.push(item);                                                                      }
        });
      };

      let redirect_node   = () => window.location.href = "/node/"   + props.item.code;
      let redirect_schema = () => window.location.href = "/schema/" + props.item.code;

      let show_popup_remove = function() { popup.remove = true;  };
      let hide_popup_remove = function() { popup.remove = false; };

      let copy_node = () => {
        localStorage.setItem("id", props.item.id);
        localStorage.setItem("action", "copy");
      };
      let cut_node = () => {
        localStorage.setItem("id", props.item.id);
        localStorage.setItem("action", "cut");
      };
      let insert_node = () => {
        let id = localStorage.getItem("id");
        if (id) {
          context.emit("insert", props.item.id, id, localStorage.getItem("action"));
          localStorage.removeItem("id");
          localStorage.removeItem("action");
        }
      };

      let set_limited = () => { context.emit("accesses", props.item.id, 2); };
      let set_shared  = () => { context.emit("accesses", props.item.id, 3); };

      let redirect_schema_code = (code) => window.location.href = "/schema/" + code;

      return {
        actions,
        popup,

        redirect_node,
        redirect_schema,
        redirect_schema_code,

        show_popup_remove,
        hide_popup_remove,
        remove: (id) => {
          context.emit("remove", id);
          hide_popup_remove();
        },

        copy_node,
        cut_node,
        insert_node,
        set_limited,
        set_shared
      };
    }
  };
</script>