export default {
  ru: {
    Profile                  : "Профиль",
    lastname                 : "Фамилия",
    lastname_required        : "Фамилия *",
    email                    : "Электронная почта",
    email_required           : "Электронная почта *",
    phone                    : "Телефон",
    phone_required           : "Телефон *",
    password                 : "Пароль",
    password_required        : "Пароль *",
    confirm_password         : "Подтверждение пароля",
    confirm_password_required: "Подтверждение пароля *",
    disable_tutorial_mode    : "Отключить обучающий режим",
    rotate                   : "Повернуть",
    preview                  : "Предварительный просмотр",
    confirm                  : "Подтвердить",
  },
  en: {
    Profile                  : "Profile",
    lastname                 : "lastname",
    lastname_required        : "lastname *",
    email                    : "Email",
    email_required           : "Email *",
    phone                    : "Phone",
    phone_required           : "Phone *",
    password                 : "Password",
    password_required        : "Password *",
    confirm_password         : "Confirm password",
    confirm_password_required: "Confirm password *",
    disable_tutorial_mode    : "Disable tutorial mode",
    rotate                   : "Rotate",
    preview                  : "Preview",
    confirm                  : "Confirm",
  }
};