<template>
  <div class="d-flex w-100 h-80 min-h-80 max-h-80 color-base-light">
    <div
      id="header-widget-tracker"
      class="d-flex justify-content-center align-items-center flex-grow p-relative p-8"
    >
      <widget-tracker />
    </div>

    <div class="d-flex justify-content-end w-20 max-w-300px">
      <web-header-menu
        :user="user"
        :items="actions"
      />
    </div>
  </div>
</template>

<script>
  import { computed } from "vue";

  import WebHeaderMenu from "./header/Menu.vue";

  import WidgetTracker from "../../visual/widgets/tracker/Tracker.vue";

  import Header from "./Header.js";

  export default {
    components: {
      WebHeaderMenu,
      WidgetTracker
    },

    props: {
      user : { type: Object, default: () => {} },
      items: { type: Array , default: () => [] }
    },

    setup (props) {
      const actions = computed(() => Header.computed(props.items));

      return { actions,
        redirect: (url) => document.location.href = url
      };
    }
  };
</script>