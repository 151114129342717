<template>
  <!-- eslint-disable-next-line vue/no-mutating-props vue/max-attributes-per-line -->
  <div v-if="node.entity.id != 0" :ref="element => { elements[node.entity.id] = element }"
    :class="'node node-' + node.entity.id + (node.entity.id == root ? ' w-100px ' : ' w-300px ') + 'base-neutral border-radius-4 box-shadow-bottom hint-wrapper'"
    :data-id="node.entity.id"
    :style="style()"
    draggable="true"
    tabindex="1"
  >
    <div class="content">
      <div v-if="node.entity.id == root" class="d-flex p-8">
        <cards-partial-code v-if="node.entity.id || node.entity.code"
          :code="node.entity.code || node.entity.id"
        />
      </div>

      <div v-else class="d-flex flex-column w-100">
        <div class="d-flex justify-content-between c-pointer p-8">
          <div class="title short-text font-weight-500">
            {{ node.entity.name || node.entity.title }}
          </div>

          <cards-partial-code v-if="node.entity.id || node.entity.code"
            class="text-right"
            :code="node.entity.code || node.entity.id"
          />
        </div>

        <div class="p-8 pt-0 overflow-hidden">
          <div
            :ref="(element) => { container = element }"
            class="container d-flex justify-content-between transition-100"
            :class="{
              'w-100': factors.deadline != true && factors.estimate != true,
              'w-332': factors.deadline == true || factors.estimate == true,
              'ml--116p': factors.estimate == true
            }"
          >
            <div class="hint-target w-30">
              <cards-partial-diagram-deadline
                :created="node.entity.created"
                :deadline="node.entity.deadline"

                :edit="factors.deadline"
                @clear="factors_clear"
                @choose="factor_choose"

                @mouseover="hint_over(1)"
                @mouseleave="hint_leave(1)"

                @submit="deadline => $emit('nodeDeadline', node.entity.id, deadline)"
              />
            </div>

            <div class="hint-target w-30">
              <cards-partial-diagram-estimate v-if="node.entity.branch"
                :actually="node.entity.actual_complexity"
                :complexity="node.entity.complexity"

                :edit="factors.estimate"
                @clear="factors_clear"
                @choose="factor_choose"

                @mouseover="hint_over(2)"
                @mouseleave="hint_leave(2)"

                @submit="complexity => $emit('nodeComplexity', node.entity.id, complexity)"
              />

              <cards-partial-diagram-estimate v-else
                :actually="format_minute(node.entity.timing)"
                :complexity="node.entity.complexity"

                :edit="factors.estimate"
                @clear="factors_clear"
                @choose="factor_choose"

                @mouseover="hint_over(2)"
                @mouseleave="hint_leave(2)"

                @submit="complexity => $emit('nodeComplexity', node.entity.id, complexity)"
              />
            </div>

            <div class="hint-target w-30">
              <cards-partial-diagram-completed v-if="node.entity.branch"
                :tasks="node.entity.total"
                :complete="node.entity.done"

                @mouseover="hint_over(3)"
                @mouseleave="hint_leave(3)"
              />

              <cards-partial-diagram-progress v-else
                :stages="progress"

                @mouseover="hint_over(3)"
                @mouseleave="hint_leave(3)"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="hint hint-1 bottom left">
        {{ $t('Deadline') }}
      </div>

      <div class="hint hint-2 bottom center">
        {{ $t('Estimate') }}
      </div>

      <div v-if="node.entity.branch" class="hint hint-3 bottom right">
        {{ $t('Completed_tasks_to_total') }}
      </div>

      <div v-else class="hint hint-3 bottom right">
        {{ $t('Progress') }}
      </div>

      <div v-if="links.some(link => link.entity.nodes_source_id == node.entity.id)"
        class="fold box-shadow-bottom"
        :class="{ 'base-dark': node.option.folded, 'base-highlight': !node.option.folded }"
        @click.stop="fold(node.option)"
      >
        <div
          class="icon small"
          :class="{ 'icon-plus-hover': node.option.folded, 'icon-minus-hover': !node.option.folded }"
        />
      </div>
    </div>

    <div class="p-absolute t--7px r--7px z-105">
      <div
        class="menu box-shadow-bottom z-105"
        @click.stop="$emit('canvasMenu', node.entity.id, node.entity.code, layout.nodes && layout.nodes[node.entity.id] || '', node.option)"
      >
        <div class="icon menu-context" />
      </div>
    </div>
  </div>
</template>

<script>
  import {
    ref,
    reactive,
    watch,
    onMounted
  } from "vue";

  import CardsPartialDiagramProgress  from "../../../../visual/widgets/cards/partials/diagram/Progress.vue";
  import CardsPartialDiagramCompleted from "../../../../visual/widgets/cards/partials/diagram/Completed.vue";
  import CardsPartialDiagramEstimate  from "../../../../visual/widgets/cards/partials/diagram/Estimate.vue";
  import CardsPartialDiagramDeadline  from "../../../../visual/widgets/cards/partials/diagram/Deadline.vue";
  import CardsPartialCode             from "../../../../visual/widgets/cards/partials/code/Code.vue";

  export default {
    components: {
      CardsPartialDiagramProgress,
      CardsPartialDiagramCompleted,
      CardsPartialDiagramEstimate,
      CardsPartialDiagramDeadline,
      CardsPartialCode
    },

    props: {
      selected: { type: Boolean, default: () => false },
      root    : { type: Number , default: () => 0     },
      elements: { type: Object , default: () => {}    },
      node    : { type: Object , default: () => {}    },
      links   : { type: Array  , default: () => []    },
      layout  : { type: Object , default: () => {}    },
      progress: { type: Array  , default: () => []    }
    },

    emits: [
      "canvasFold",
      "canvasMenu",
      "nodeDeadline",
      "nodeComplexity"
    ],

    setup(props, context) {
      const PER_MINUTE = 60;

      const elements  = ref(props.elements || []);
      const options   = ref({});
      const container = ref();

      const factors = reactive({ deadline: false, estimate: false, progress: false });

      onMounted(() => {
        options.value = props.node.option.style && JSON.parse(props.node.option.style);
        factors_refresh();
      });

      watch([ () => props.node ], async ([node]) => {
        options.value = node.option.style && JSON.parse(node.option.style);
        factors_refresh();
      });

      let factors_refresh = () => {
        let elements = container.value && container.value.querySelectorAll(".diagram-edit");

        if (elements) {
          elements.forEach((element) => { element.style.opacity = 0; });

          container.value.addEventListener("transitionend", function (event) {
            if (factors.deadline == true || factors.estimate == true) {
              event.target.querySelectorAll(".diagram-edit").forEach((element) => { element.style.opacity = 1; });
            }
          });
        }
      };

      let factors_clear = (name) => {
        factors.deadline = false;
        factors.estimate = false;

        if (name) { factors[name] = true; }
        else { container.value.querySelectorAll(".diagram-edit").forEach((element) => { element.style.opacity = 0; }); }
      };

      let hint_over = (index) => elements.value[props.node.entity.id].querySelector(".hint-" + index).classList.add("active");
      let hint_leave = (index) => elements.value[props.node.entity.id].querySelector(".hint-" + index).classList.remove("active");

      let format_minute = (time) => { return parseInt(time / PER_MINUTE); };

      return {
        // eslint-disable-next-line vue/no-dupe-keys
        elements,
        options,
        container,

        factors,
        factors_clear,
        factor_choose: (name) => factors_clear(name),

        hint_over,
        hint_leave,

        format_minute,

        fold: (option) => {
          option.folded = option.folded ? 0 : 1;
          context.emit("canvasFold", option);
        },

        style: () => {
          return "z-index: " + (10000000 - (props.node.option.level * 1000) - (props.node.option.parent_offset * 25) - props.node.option.offset) + "; " +
            (props.layout.nodes && props.layout.nodes[props.node.entity.id] || "opacity: 0; ") +
            ((options.value && options.value.borderColor) ? "border-color: " + options.value.borderColor + "; " : "")
          ;
        },
      };
    }
  };
</script>

<style lang="scss" scoped>
  .node {
    position: absolute;
    border: 1px solid transparent;
    border-radius: 5px;

    &:focus-visible {
      outline: none;
      border-color: #0CAEFF !important;
    }

    &.selected {
      border-color: #0CAEFF !important;
    }

    &.focused {
      padding: 30px 50px 30px 0;
    }

    & > .content {
      display: flex;
      align-items: center;
      position: relative;

      & .icons {
        background-repeat: no-repeat;
        width: 0;
        height: 17px;
      }

      & .id {
        display: flex;
        border: 2px solid transparent;
        border-radius: 4px;
      }

      & .text {
        display: flex;
        border: 2px solid transparent;
        border-radius: 4px;
        padding-right: 8px;
        padding-left: 8px;
        min-height: 20px;
      }

      & .fold {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        right: -25px;
        width: 16px;
        height: 16px;
        text-align: center;
        border: 1px solid transparent;
        border-radius: 15px;
      }
    }

    .menu {
      display: none;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 8px;
      border: 1px solid;
      border-color: #FFFFFF;
      background-size: auto;
      background-color: #FFFFFF;
      cursor: pointer;

      &:hover {
        border-color: #DEDEDE;
      }
    }

    &:hover {
      .menu { display: flex; }
    }
  }
</style>