import Server from "../../../system/server/server";
import Entity from "../entities/Detail";

export default class Detail {
  static async load(code) {
    return new Entity(await Server.get("node/" + code));
  }

  static async create(projects_id, root_id, title) {
    return new Entity(await Server.post("node/create", {
      title      : title,
      projects_id: projects_id,
      root_id    : root_id
    }));
  }

  static async remove(node_id) {
    return new Entity(await Server.post("node/remove", {
      node_id: node_id
    }));
  }

  static async finish(node_id) {
    return new Entity(await Server.post("node/finish", {
      node_id: node_id
    }));
  }

  static async onward(node_id, status_state_id) {
    return new Entity(await Server.post("node/onward", {
      node_id        : node_id,
      status_state_id: status_state_id
    }));
  }

  static async backward(node_id) {
    return new Entity(await Server.post("node/backward", {
      node_id: node_id
    }));
  }

  static async update(node_id, field, value) {
    return new Entity(await Server.post("node/update", {
      node_id: node_id,
      field  : field,
      value  : value
    }));
  }

  static async timing(manual_timing, reason, created, node_id, states_id) {
    return new Entity(await Server.post("node/manual", {
      node_id       : node_id,
      manual_timing : manual_timing,
      reason        : reason,
      created       : created,
      states_id     : states_id
    }));
  }

  static async complexities(node_id, value, route_id) {
    return new Entity(await Server.post("node/complexities", {
      node_id : node_id,
      value   : value,
      route_id: route_id
    }));
  }

  static async workers(node_id, route_id, worker_id) {
    return new Entity(await Server.post("node/workers", {
      node_id  : node_id,
      route_id : route_id,
      worker_id: worker_id
    }));
  }

  static async watcher(node_id, watcher) {
    return new Entity(await Server.post("node/watcher", {
      node_id: node_id,
      watcher: watcher
    }));
  }
}