<template>
  <div class="widget d-flex flex-column">
    <div v-if="label" class="font-soft font-memo mb-8">
      {{ label }}
    </div>

    <div class="input d-flex justify-content-center border-1-dashed color-border-option border-radius-4">
      <label
        class="d-flex c-pointer p-relative text-center py-16 font-light"
        @dragenter.prevent
        @dragover.prevent="dragover"
        @dragleave.prevent="dragleave"
        @drop.prevent="upload($event, true)"
      >
        {{ $t('Select_or_drag_and_drop_file') }}

        <input
          class="p-absolute d-none"
          type="file"
          multiple
          @change="upload"
        >
      </label>
    </div>

    <div class="border-1 border-radius-5 color-border-light">
      <div
        v-for="file in source.files"
        :key="file.id"
        class="p-5"
      >
        <div class="file d-flex justify-content-between w-100">
          <a
            class="font-dark text-decoration-none c-pointer"
            :href="url(file.id)"
            :download="file.name"
            target="_blank"
          >
            {{ file.name }}
          </a>

          <div
            class="icon icon-remove icon-hover-dark c-pointer"
            @click="remove(file.id)"
          />
        </div>
      </div>
    </div>

    <div v-if="description" class="description font-memo">
      {{ description }}
    </div>
  </div>
</template>

<script>
  import {
    ref,
    reactive,
    onMounted,
    watch
  } from "vue";

  import Config from "../../../../config.js";
  import Server from "../../../../system/server/server.js";

  import FileEntity  from "./entities/File.js";
  import FileService from "./services/File.js";

  export default {
    props: {
      scope_id   : { type: String, default: () => "" },
      entity_id  : { type: Number, default: () => 0  },
      label      : { type: String, default: () => "" },
      files      : { type: Array , default: () => [] },
      user_id    : { type: Number, default: () => 0  },
      description: { type: String, default: () => "" }
    },

    setup(props) {
      const source = reactive(new FileEntity);

      const api = ref(Config.API);

      onMounted(async () => {
        Object.assign(source, await FileService.load(props.scope_id, props.entity_id));
      });

      watch(async () => props.entity_id, (selection) => { 
        selection.then(async (id) => Object.assign(
          source,
          await FileService.load(props.scope_id, id)
        ));
      });

      let dragover  = (event) => {
        event.target.closest(".input").classList.add("color-border-highlight");
        event.target.closest(".input").classList.remove("color-border-option");
      };

      let dragleave = (event) => {
        event.target.closest(".input").classList.add("color-border-option");
        event.target.closest(".input").classList.remove("color-border-highlight");
      };

      return {
        source,

        dragover,
        dragleave,

        url: (id) => api.value + '/api/files/download?id=' + id + '&token=' + Server.token(),

        upload: async (event, drop = false) => {
          let data = new FormData();
          data.append("scopes_id"       , props.scope_id );
          data.append("scopes_entity_id", props.entity_id);

          let files = drop ? event.dataTransfer.files : event.target.files;

          for (let i = 0; i < files.length; i++) {
            data.append("files[]", files[i]);
          }

          if (files.length > 0) {
            Object.assign(
              source,
              await FileService.upload(data)
            );
          }

          dragleave(event);
          event.target.value = "";
        },

        remove: async (id) => Object.assign(
          source,
          await FileService.delete(id, props.scope_id, props.entity_id)
        )
      };
    }
  };
</script>
