export default {
  ru: {
    Spaces          : "Организации",

    Organization    : "Организация",
    Organizations   : "Организации",
    Modules         : "Модули",
    add_organization: "Создать организацию",
  },
  en: {
    Spaces          : "Spaces",

    Organization    : "Organization",
    Organizations   : "Organizations",
    Modules         : "Modules",
    add_organization: "Add organization",
  }
};