<template>
  <layout-flat>
    <div>Page Not Found</div>
  </layout-flat>
</template>

<script>
  import LayoutFlat from "../../layouts/Flat.vue";

  export default {
    components: {
      LayoutFlat
    },

    setup () {
      return {};
    }
  };
</script>