<template>
  <layout-web>
    <div>
      <partial-header
        :id="source.profile.id"
        :active="0"
      />

      <div class="base-neutral border-radius-8 p-8">
        <VueAvatarUpload
          v-show="show.avatar"
          :avatar="fields.avatar"
          :i18="{
            title: '',
            changeAvatar: $t('edit'),
            rotate: $t('rotate'),
            preview: $t('preview'),
            cancel: $t('cancel'),
            confirm: $t('confirm')
          }"
          @custom-request="upload"
          @close="show.avatar = false"
        />

        <div class="form">
          <div class="d-flex">
            <div class="d-flex flex-column flex-grow">
              <field-input
                v-model="fields.name"
                class="mb-10"
                :label="$t('name_required')"
                :placeholder="$t('name')"
                :message="errors.name"
                :failure="errors.name"
              />

              <field-input
                v-model="fields.lastname"
                class="mb-10"
                :label="$t('lastname_required')"
                :placeholder="$t('lastname')"
                :message="errors.lastname"
                :failure="errors.lastname"
              />

              <field-input
                v-model="fields.email"
                class="mb-10"
                :label="$t('email_required')"
                :placeholder="$t('email')"
                :message="errors.email"
                :failure="errors.email"
              />
            </div>

            <div class="d-flex">
              <div
                class="w-200px h-200px ml-16 c-pointer"
                @click="show.avatar = true"
              >
                <img v-if="source.profile.avatar"
                  :src="url(source.profile.id)"
                  class="w-100 h-100 border-radius-100"
                >

                <div v-else-if="source.profile.id && !source.profile.avatar"
                  class="d-flex justify-content-center align-items-center w-100 h-100 border-radius-100 base-highlight font-avatar font-neutral"
                >
                  {{ title(source.profile) }}
                </div>
              </div>
            </div>
          </div>

          <field-input
            v-model="fields.phone"
            class="mb-10"
            :label="$t('phone_required')"
            :placeholder="$t('phone')"
            :message="errors.phone"
            :failure="errors.phone"
          />

          <field-input
            v-model="fields.password"
            class="mb-10"
            type="password"
            :label="$t('password_required')"
            :placeholder="$t('password')"
            :message="errors.password"
            :failure="errors.password"
          />

          <field-confirm
            v-model="fields.password_confirmation"
            class="mb-10"
            type="password"
            :source="fields.password"
            :label="$t('confirm_password_required')"
            :placeholder="$t('confirm_password')"
          />

          <div class="d-flex">
            <div class="button primary" @click="submit">
              {{ $t("save") }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </layout-web>
</template>

<script>
  import {
    ref,
    reactive,
    onMounted
  } from "vue";

  import Config from "../../../config.js";
  import Server from "../../../system/server/server.js";

  import VueAvatarUpload from "vue-avatar-upload";

  import LayoutWeb     from "../../../layouts/Web.vue";
  import PartialHeader from "./partials/Header.vue";
  import FieldInput    from "../../../visual/fields/input/Input.vue";
  import FieldConfirm  from "../../../visual/fields/confirm/Confirm.vue";

  import RosterEntity  from "../entities/Roster.js";
  import RosterService from "../services/Roster.js";

  export default {
    components: {
      VueAvatarUpload,
      LayoutWeb,
      PartialHeader,
      FieldInput,
      FieldConfirm
    },

    setup() {
      const source = reactive(new RosterEntity);

      const fields = reactive({
        name                  : "",
        lastname              : "",
        email                 : "",
        phone                 : "",
        password              : "",
        password_confirmation : ""
      });

      const errors = reactive({
        name                  : "",
        lastname              : "",
        email                 : "",
        phone                 : "",
        password              : "",
        password_confirmation : ""
      });

      const show = reactive({ avatar: false});

      const api = ref(Config.API);

      onMounted(async () => {
        Object.assign(source, await RosterService.load());
        fields.name     = source.profile.name;
        fields.lastname = source.profile.lastname;
        fields.email    = source.profile.email;
        fields.phone    = source.profile.phone;
      });

      let remove_errors = () => {
        errors.name                  = "";
        errors.lastname              = "";
        errors.email                 = "";
        errors.phone                 = "";
        errors.password              = "";
        errors.password_confirmation = "";
      };

      let title = (user) => {
        return user.name ? user.name.charAt(0) + user.lastname.charAt(0) : "";
      };

      return {
        source,
        fields,
        errors,

        show,

        title,

        url: (id) => api.value + '/api/profile/download?id=' + id + '&token=' + Server.token(),

        upload: async (file) => {
          let data = new FormData();
          data.append("file", file);

          Object.assign( source, await RosterService.upload(data) );
          show.avatar = false;

          window.location.reload();
        },

        submit: async () => {
          remove_errors();

          let profile = {
            name    : (fields.name     !== undefined) ? fields.name     : source.profile.name,
            lastname: (fields.lastname !== undefined) ? fields.lastname : source.profile.lastname,
            email   : (fields.email    !== undefined) ? fields.email    : source.profile.email,
            phone   : (fields.phone    !== undefined) ? fields.phone    : source.profile.phone
          };

          if (fields.password)              { profile.password               = fields.password;              }
          if (fields.password_confirmation) { profile.password_confirmation  = fields.password_confirmation; }

          Object.assign(source, await RosterService.update(profile));

          if (source.errors) {
            for (const [key, value] of Object.entries(source.errors)) {
              errors[key] = value[0];
            }
          }
        }
      };
    }
  };
</script>