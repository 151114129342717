<template>
  <div class="overflow-y-scroll scrollbar-disable w-300px min-w-300px pr-8 pb-8 pt-10 mt--10">
    <widget-draganddrop-vertical
      :array="units"
      @change="(target, source, offset) => $emit('order', target, source, offset)"
    >
      <template #item="element">
        <milestone-partial
          :project="project"
          :item="element.item"

          @pick="(node) => $emit('pick', node)"
          @remove="(milestones_id) => $emit('remove', milestones_id)"
          @complexity="(node_id, complexity) => $emit('complexity', node_id, complexity)"
          @period="(milestones_id, start, finish) => $emit('period', milestones_id, start, finish)"
          @shipped="(milestones_id) => $emit('shipped', milestones_id)"

          @move_up="move_structure_up"
          @move_down="move_structure_down"
        />
      </template>
    </widget-draganddrop-vertical>

    <widget-card-milestone-roadmap-placeholder v-if="form"
      class="my-4 base-neutral min-w-200px"
      :items="roots"
      :partials="['progress-period-simple', 'progress-completed', 'progress-estimate']"
      @create="create"
    />

    <div v-else
      class="d-flex justify-content-center mt-10 c-pointer border-1 border-radius-8 color-border-light hover-border-highlight base-neutral p-8"
      @click="form = true"
    >
      {{ $t("add_step") }}
    </div>
  </div>
</template>

<script>
  import {
    ref
  } from "vue";

  import MilestonePartial from "./Milestone.vue";

  import WidgetCardMilestoneRoadmapPlaceholder from "../../../../visual/widgets/cards/milestone/roadmap/Placeholder.vue";
  import WidgetDraganddropVertical             from "../../../../visual/widgets/draganddrop/Vertical.vue";

  export default {
    components: {
      MilestonePartial,
      WidgetCardMilestoneRoadmapPlaceholder,
      WidgetDraganddropVertical,
    },

    props: {
      project: { type: Object, default: () => {} },
      units  : { type: Array , default: () => [] },
      roots  : { type: Array , default: () => [] }
    },

    emits: [
      "pick",
      "create",
      "remove",
      "order",
      "complexity",
      "period",
      "shipped"
    ],

    setup(props, context) {
      const form = ref(false);

      let move_structure_up = (node) => {
        let element = document.querySelector("[data-id='" + node.id + "']").previousSibling;
        move_structure(element);
      };

      let move_structure_down = (node) => {
        let element = document.querySelector("[data-id='" + (node.id || 0) + "']").nextSibling;
        move_structure(element);
      };

      let move_structure = (element) => {
        let item = element && element.childNodes.length && element.getElementsByClassName("structure-item")[0];
        if (item) { item.focus(); }
      };

      return {
        form,

        move_structure_up,
        move_structure_down,

        create: (item) => {
          if (item.name) { context.emit("create", item); }
          else { form.value = false; }
        }
      };
    }
  };
</script>