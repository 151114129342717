import Server from "../../../system/server/server";
import Entity from "../entities/Roster";

export default class Roster {
  static async load(spaces_id) {
    return new Entity(await Server.get("roles?spaces_id=" + spaces_id));
  }

  static async create(role) {
    return new Entity(await Server.post("roles", role));
  }

  static async delete(role_id) {
    return new Entity(await Server.delete("roles/" + role_id));
  }
}