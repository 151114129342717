import Server from "../../../system/server/server";
import Entity from "../entities/Detail";

export default class Detail {
  static async load(offset, keyword = "") {
    return new Entity(await Server.post("stream", {
      offset : offset,
      keyword: keyword
    }));
  }

  static async states(project_id, option, value, offset, keyword = "") {
    return new Entity(await Server.post("stream/states", {
      id     : project_id,
      option : option,
      value  : value,
      offset : offset,
      keyword: keyword
    }));
  }

  static async priority(projects, offset, keyword = "") {
    return new Entity(await Server.post("stream/priority", {
      list   : projects,
      offset : offset,
      keyword: keyword
    }));
  }
}