<template>
  <div class="flex-grow h-100 border-1 border-color-transparent min-w-15">
    <div class="d-flex base-neutral justify-content-between border-radius-8 p-8">
      {{ $t(period.name) }}
    </div>

    <div
      class="overflow-y-scroll scrollbar h-100--40px mt-8 px-8"
      @dragover.prevent
      @dragenter.prevent
      @dragleave.prevent
      @drop="$emit('emit-dragdrop', $event, period.id, false)"
    >
      <div v-for="node in nodes" :key="node.id" class="d-flex">
        <div v-if="node.blank"
          class="blank w-100 h-10px"
          @dragover.prevent
          @drop.stop="$emit('emit-dragdrop', $event, period.id, node)"
          @dragenter="$emit('emit-dragenter', $event)"
          @dragleave="$emit('emit-dragleave', $event)"
        />

        <widget-card-node-main v-if="!node.type && !node.blank"
          class="base-neutral"
          :class="{'alarm': node.alarm}"
          :item="node"
          :menu="['details', 'strucrure']"
          :partials="['code', 'description', 'complexity', 'schedule', 'deadline', 'period-value', 'menu', 'finish','path', 'progress-stages']"
          :selected="selected == node.id"
          draggable="true"
          @dragstart="$emit('emit-dragstart', $event, node, period.id)"
          @dragend="$emit('emit-dragend', $event)"
          @click="$emit('detail', { id: node.id, code: node.code, header: '#' + node.code })"
          @finish="(data) => $emit('emit-finish', data)"
        />

        <widget-card-node-main v-if="node.type && !node.blank"
          class="muted"
          :class="{'alarm': node.alarm}"
          :item="node"
          :menu="['details', 'strucrure']"
          :partials="['id', 'description', 'complexity', 'deadline', 'menu', 'path', 'progress-stages']"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import WidgetCardNodeMain from "../../../../visual/widgets/cards/node/Main.vue";

  export default {
    components: {
      WidgetCardNodeMain
    },

    props: {
      nodes   : { type: Array , default: () => [] },
      period  : { type: Object, default: () => {} },
      index   : { type: Number, default: () => 1  },
      selected: { type: Number, default: () => 0  }
    },

    emits: [
      "emit-dragdrop",
      "emit-dragstart",
      "emit-dragend",
      "emit-dragenter",
      "emit-dragleave",
      "emit-finish",
      "detail"
    ]
  };
</script>