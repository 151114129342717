<template>
  <div class="widget-draganddrop-vertical">
    <div
      v-for="item in array"
      :key="item.id"
      class="p-relative"

      :data-id="item.id"

      draggable="true"
      @dragenter.prevent
      @dragleave.prevent
      @dragover.prevent
      @dragend="dragend"
      @dragstart="dragstart($event, item.id)"
      @click.prevent
    >
      <div v-if="location.target && location.target != item.id" class="p-absolute w-100 h-100 z-10">
        <div v-if="item.id"
          class="w-100 h-33"
          @dragenter.prevent
          @dragleave.prevent
          @dragover="location_before(item.id)"
          @drop.stop="drop(item.id, -0.5)"
        />

        <div v-if="item.id"
          class="w-100 h-33"
          @dragenter.prevent
          @dragleave.prevent
          @dragover="location_after(item.id)"
          @drop.stop="drop(item.id, 0.5)"
        />
      </div>

      <div class="d-flex p-absolute w-100 h-1px">
        <div class="w-100" :class="{'border-top-1-dashed': location.items[item.id] && location.items[item.id] == 'before'}" />
      </div>

      <slot name="item" :item="item" />

      <div class="d-flex p-absolute w-100 h-1px">
        <div class="w-100" :class="{'border-top-1-dashed': location.items[item.id] && location.items[item.id] == 'after'}" />
      </div>
    </div>
  </div>
</template>

<script>
  import { reactive } from "vue";

  export default {
    props: {
      array: { type: Array, default: () => [] }
    },

    emits: [
      "change"
    ],

    setup (props, context) {
      const location = reactive({ items: [], target: 0 });

      let location_none = () => {
        location.items  = [];
      };

      return {
        location,

        location_none,
        location_before: (id) => { location_none(); location.items[id] = "before"; },
        location_after : (id) => { location_none(); location.items[id] = "after" ; },

        dragstart: (event, id) => {
          location.target = id;

          event.dataTransfer.dropEffect    = "move";
          event.dataTransfer.effectAllowed = "move";

          event.target.style.opacity = "0.5";
        },

        drop: (source, offset) => {
          if (location.target) {
            context.emit("change", location.target, source, offset);
          }
        },

        dragend: () => {
          location_none();
        
          document
            .querySelector(".widget-draganddrop-vertical [data-id='"  + location.target + "']")
            .closest(".p-relative")
            .style.opacity = "1"
          ;

          location.target = 0;
        }
      };
    }
  };
</script>