<template>
  <layout-web>
    <div>
      <partial-header
        :id="$route.params.id"
        :active="3"
      />

      <div class="base-neutral border-radius-8 p-8">
        <chain-detail
          :spaces_id="id"
          :chain_id="$route.params.chain_id"
        />
      </div>
    </div>
  </layout-web>
</template>

<script>
  import LayoutWeb     from "../../../../layouts/Web.vue";
  import PartialHeader from "../partials/Header.vue";
  import ChainDetail   from "../../../chains/plugins/detail/Detail.vue";

  export default {
    components: {
      LayoutWeb,
      PartialHeader,
      ChainDetail
    },

    props: {
      id: { type: String, default: () => "" }
    }
  };
</script>