<template>
  <div class="widget w-100">
    <div v-if="label" class="font-soft font-memo mb-8">
      {{ label }}
    </div>

    <div v-if="!form.edit"
      class="d-flex justify-content-between c-crosshair"
      @click="form.edit = true"
    >
      <div>
        {{ format(value) }}
      </div>
    </div>

    <div v-if="form.edit">
      <input
        :ref="(element) => { created = element }"
        type="text"
        class="w-100 mb-5"
        :value="current"
        placeholder="YYYY-MM-DD HH:mm:ss"
        @keydown="handle"
      >

      <input
        :ref="(element) => { manual = element }"
        type="number"
        class="mb-5"
        :placeholder="$t('number_of_minutes')"
      >

      <input
        :ref="(element) => { reason = element }"
        type="text"
        class="mb-5"
        :placeholder="$t('add_reason')"
      >

      <div class="d-flex justify-content-end">
        <div
          class="font-denial c-pointer"
          @click="clear"
        >
          {{ $t("cancel") }}
        </div>

        <div
          class="font-highlight ml-8 c-pointer"
          @click="submit"
        >
          {{ $t("save") }}
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
  import {
    ref,
    reactive
  } from "vue";

  import moment from "moment";

  export default {
    props: {
      label: { type: String , default: () => "" },
      value: { type: String , default: () => "" },
    },

    emits: [
      "submit"
    ],

    setup(props, context) {
      const PER_HOUR   = 3600;
      const PER_MINUTE = 60;

      let current = ref(moment().format("YYYY-MM-DD HH:mm:ss"));

      const created = ref();
      const manual = ref();
      const reason = ref();

      const form = reactive({ edit: false });

      let format = (time) => {
        let hour   = parseInt(time / PER_HOUR);
        time       = time - (hour * PER_HOUR);
        let minute = parseInt(time / PER_MINUTE);

        return format_time_value(hour) + ":" + format_time_value(minute);
      };

      let format_time_value = (value) => {
        return String(value).length < 2 && ('0' + String(value)) || value;
      };

      return {
        current,
        created,
        manual,
        reason,
        form,

        format,

        clear: () => {
          form.edit = false;
        },

        submit: () => {
          context.emit("submit", manual.value.value, reason.value.value, created.value.value);
          form.edit = false;
        }
      };
    }
  };
</script>