<template>
  <div class="landing overflow-hidden h-100">
    <slot />
  </div>
</template>

<script>
  export default {
    setup () {
      return {};
    }
  };
</script>