<template>
  <div
    class="d-flex flex-column align-items-center h-70px hover-base-highlight c-pointer"
    :class="{ 'base-highlight': value }"
    @click="$emit('handler')"
  >
    <div class="d-flex align-items-center w-100--28px h-100 py-8 c-pointer">
      <div class="icon large my-8 toolbar-question" />

      <div v-if="expand"
        class="font-neutral font-memo ml-8"
      >
        {{ $t("Help") }}
      </div>
    </div>

    <div v-if="value"
      class="d-flex flex-column base-dark font-neutral p-absolute min-w-200px p-20 h-30px b-0 l-200px border-1 color-border-dark z-110 c-pointer"
    >
      <!-- <div class="p-5 hover-base-highlight" @click="tutorial(1)">
        {{ $t("quick_start_simple") }}
      </div>

      <div class="p-5 hover-base-highlight" @click="tutorial(2)">
        {{ $t("quick_start_complex") }}
      </div> -->

      <!-- <hr> -->

      <!-- <div class="p-5 hover-base-highlight" @click="tutorial(0)">
        {{ $t("reset_tutorial") }}
      </div> -->

      <!-- <hr> -->

      <div class="p-5 hover-base-highlight" @click="redirect('/prompt')">
        {{ $t("more") }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      value : { type: Boolean, default: () => {}    },
      expand: { type: Boolean, default: () => false }
    },

    emits: [
      "handler"
    ],

    setup() {
      return {
        redirect: (url) => window.location.href = url
      };
    }
  };
</script>