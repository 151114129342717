export default class Response {
  constructor(access, data, status, errors, headers, token) {
    this.access  = access;
    this.data    = data;
    this.status  = status;
    this.errors  = errors;
    this.headers = headers;
    this.token   = token;
  }
}
