import Server   from "../../../system/server/server";
import Entity   from "../entities/Excuses";
import Excuse   from "../entities/Excuse";
import Progress from "../entities/Progress";

export default class Excuses {
  static async load() {
    return new Entity(await Server.get("excuses"));
  }

  static async create(excuse) {
    return new Entity(await Server.post("excuses", excuse));
  }

  static async delete(excuse) {
    return new Entity(await Server.delete("excuses" + excuse.id));
  }

  static async item(id) {
    return new Excuse(await Server.get("excuses/" + id));
  }

  static async update(excuse) {
    return new Excuse(await Server.put("excuses/" + excuse.id, excuse));
  }

  static async progress() {
    return new Progress(await Server.get("excuses/progress"));
  }
}