export default class Motion {
  static launch(event, node) {
    let image = document.createElement("div");
    image.classList.add('ghost-image');
    image.style.width = 0;
    image.style.height = 0;
    node.appendChild(image);

    event.dataTransfer.dropEffect = "move";
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setDragImage(image, 0, 0);
  }

  static finish(node) {
    let ghost = node.querySelector('.ghost-image');
    if (ghost) {
      ghost.remove();
    }
  }

  static handle(location, source, target, limits, scale = 1) {
    let offset = {
      top : target.y - source.y,
      left: target.x - source.x,
    };

    let position = {
      top : location.top  + offset.top  * scale,
      left: location.left + offset.left * scale,
    };

    if (limits) {
      limits.top  = limits.top  || 0;
      limits.left = limits.left || 0;

      limits.right  = limits.right  || 0;
      limits.bottom = limits.bottom || 0;

      position.top = position.top  < limits.top    ? limits.top    : position.top;
      position.top = position.top  > limits.bottom ? limits.bottom : position.top;

      position.left = position.left < limits.left   ? limits.left   : position.left;
      position.left = position.left > limits.right  ? limits.right  : position.left;
    }

    return position;
  }
}