import Parser from "./layout/Parser";
import Region from "./layout/Region";

export default class Layout {
  static detect(nodes, links, roots, layout) {
    let locations =
      detect_locations(nodes, links, roots, layout)
    ;

    return {
      scene: detect_scene_location(locations),
      nodes: detect_nodes_location(locations),
      links: detect_links_location(links, locations),
    };
  }
}

function detect_locations(nodes, links, roots, layout) {
  let parser = new Parser(nodes, links, roots);
  let region = new Region(function () {});

  let locations = {};

  parser.append_nested();

  while (!parser.finish()) {
    let node   = parser.last();
    let nested = parser.detect_nested(node);

    if (nested.length) {
      // Node has children.
      parser.append_nested(nested, node);
      region.create_nested(parser.level(), layout[node.entity.id].width);
    }
    else {
      // Node has NO children.
      locations[node.entity.id] = {
        left: region.detect_left(parser.level()),
        top : region.detect_next_top(parser.level(), layout[node.entity.id].height),

        width : layout[node.entity.id].width,
        height: layout[node.entity.id].height,
      };

      parser.mark_node_as_done();

      while (parser.return_to_parent_branch()) {
        // We go to level upper (nested level is processed completely).
        node = parser.last();

        parser.mark_node_as_done();
        parser.move_to_parent();

        locations[node.entity.id] = {
          left: region.detect_left(parser.level()),
          top : region.detect_next_top(parser.level(), layout[node.entity.id].height),

          width : layout[node.entity.id].width,
          height: layout[node.entity.id].height,
        };

        let difference = region.parent_bigger_than_nested(
          parser.level(), layout[node.entity.id].height
        );

        if (difference > 0) {
          // Parent bigger than nested.
          parser.nested(node.entity.id).forEach(nested => {
            // Increase nested node top to half of difference between levels.
            locations[nested.entity.id].top += difference;
          });
        }
        else if (difference < 0) {
          // Parent less than nested.
          region.equate_parent_height_to_nested_height(parser.level());

          // We need to align parent node to the vertical center of nested node group.
          locations[node.entity.id].top = region.detect_middle_top(parser.level() + 1, layout[node.entity.id].height);
        }
      }
    }
  }

  return locations;
}

function detect_scene_location(locations) {
  let size = {
    width : 0,
    height: 0,
  };

  Object.values(locations).forEach(location => {
    size.width  = Math.max(size.width, location.left + location.width);
    size.height = Math.max(size.height, location.top + location.height);
  });

  return size;
}

function detect_nodes_location(locations) {
  let layout = {};

  for (var id in locations) {
    layout[id] = 'left:' + locations[id].left + 'px; top:' + locations[id].top + 'px;';
  }

  return layout;
}

function detect_links_location(links, locations) {
  let layout = {};

  links.forEach(link => {
    let source = locations[link.entity.nodes_source_id];
    let target = locations[link.entity.nodes_target_id];

    if (target) {
      if ((source.top + source.height / 2) == (target.top + target.height / 2)) {
        layout[link.entity.id] =
          "M " +
            (source.left + source.width) + ", " + (source.top  + source.height / 2) + " " +
          "L " +
            (source.left + source.width + 30) + ", " + (target.top  + target.height / 2)
        ;
      }
      else {
        layout[link.entity.id] =
          "M " +
            (source.left + source.width) + ", " + (source.top  + source.height / 2) + " " +
          "L " +
            (source.left + source.width + 5) + ", " + (source.top  + source.height / 2) + " " +
          "Q " +
            (source.left + source.width + 15) + ", " + (source.top  + source.height / 2) + " " +
            (source.left + source.width + 15) + ", " + (source.top  + (source.height / 2) + ((source.top + source.height / 2) > (target.top + target.height / 2) ? -10 : 10)) + " " +
          "L " +
            (source.left + source.width + 15) + ", " + (target.top  + (target.height / 2) + ((source.top + source.height / 2) < (target.top + target.height / 2) ? -10 : 10)) + " " +
          "Q " +
            (source.left + source.width + 15) + ", " + (target.top  + target.height / 2) + " " +
            (source.left + source.width + 25) + ", " + (target.top  + target.height / 2) + " " +
          "L " +
            (source.left + source.width + 30) + ", " + (target.top  + target.height / 2)
          // https://svg-path-visualizer.netlify.app/
          // "L " +
          //   (source.left + source.width + 26) + ", " + (target.top  + target.height / 2 + 4) + " " +
          // "L " +
          //   (source.left + source.width + 30) + ", " + (target.top  + target.height / 2) + " " +
          // "L " +
          //   (source.left + source.width + 26) + ", " + (target.top  + target.height / 2 - 4)
        ;
      }
    }
  });

  return layout;
}