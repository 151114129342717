<template>
  <div class="widget field">
    <div v-if="label" class="font-soft font-memo mb-8">
      {{ label }}
    </div>

    <div v-if="!form.edit"
      class="d-flex c-crosshair"
      @click="form.edit = true"
    >
      <div v-if="fields.value" class="font-section font-title word-break-all w-100">
        {{ complexity.value }} 
      </div>

      <div v-else class="placeholder font-memo font-italic">
        {{ placeholder }}
      </div>
    </div>

    <div v-if="form.edit" class="d-flex">
      <select
        :value="fields.route"
        @change="fields.route = $event.target.value"
      >
        <option value="" :selected="!fields.route">
          {{ $t("total") }}
        </option>

        <option
          v-for="route in routes"
          :key="route.id"
          :value="route.id"
          :selected="route.id == fields.route"
        >
          {{ route.name || route.title }} ({{ route.weight }}%)
        </option>
      </select>

      <input
        class="ml-8"
        type="text"
        :autofocus="form.edit"
        :placeholder="placeholder"
        :value="fields.value"
        @keyup.esc="clear"
        @keyup.enter="submit($event.target.value)"
      >
    </div>

    <div v-if="description" class="description font-memo">
      {{ description }}
    </div>
  </div>
</template>

<script>
  import {
    reactive,
    onMounted,
    watch
  } from "vue";

  export default {
    props: {
      label      : { type: String, default: () => "" },
      complexity : { type: Object, default: () => {} },
      routes     : { type: Array , default: () => [] },
      placeholder: { type: String, default: () => "" },
      description: { type: String, default: () => "" }
    },

    emits: [
      "submit",
    ],

    setup(props, context) {
      const form = reactive({ edit: false });
      const fields = reactive({ route: null, value: 0 });

      onMounted(() => {
        fields.route = get_route(props.complexity, props.routes);
        fields.value = get_value(props.complexity, props.routes);
      });

      watch([ () => props.complexity, () => props.routes ], ([complexity, routes]) => { 
        fields.route = get_route(complexity, routes);
        fields.value = get_value(complexity, routes);
      });

      let get_route = (complexity, routes) => {
        if (complexity) {
          return complexity.chains_routes_id;
        }
        else if (routes.length) {
          let default_route = routes.find((route) => route.default);
          return default_route ? default_route.id : null;
        }
        return null;
      };

      let get_value = (complexity, routes) => {
        return complexity && complexity.value * (
          complexity.chains_routes_id ? (routes.find(route => route.id == complexity.chains_routes_id).weight / 100) : 1
        );
      };

      return {
        form,
        fields,

        clear: () => {
          form.edit = false;
        },

        submit: (value) => {
          let route = props.routes.find(route => route.id == fields.route);
          context.emit("submit", (value * 100) / (route ? route.weight : 100) , fields.route);
          form.edit = false;
        }
      };
    }
  };
</script>
