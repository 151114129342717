<template>
  <div class="field w-100">
    <div class="p-relative">
      <input :ref="(element) => { input = element }"
        type="text"
        class="action box-shadow-bottom"
        :placeholder="$t('search')"
        :value="value"
        @keyup.enter="search"
        @keyup.esc="refresh"
      >

      <div
        class="icon icon-search p-absolute c-pointer t-8px r-8px"
        @click="search"
      />
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";

  export default {
    props: {
      value: { type: String, default: () => "" }
    },

    emits: [
      "search"
    ],

    setup(props, context) {
      const input = ref();

      return {
        input,

        search: () => context.emit("search", input.value.value),

        refresh: () => {
          input.value.value = "";
          context.emit("search", "");
        }
      };
    }
  };
</script>