<template>
  <div class="field w-100">
    <div v-if="label" class="font-soft font-memo mb-8">
      {{ label }}
    </div>

    <div v-if="!form.edit"
      class="d-flex c-crosshair"
      @click="form.edit = true"
    >
      <div v-if="value" class="font-section font-title word-break-all w-100">
        {{ value }}
      </div>

      <div v-else class="placeholder font-memo font-italic">
        {{ placeholder }}
      </div>
    </div>

    <div v-if="form.edit">
      <input
        :autofocus="form.edit"
        :type="type"
        :placeholder="placeholder"
        :value="value"
        @keyup.esc="clear"
        @keyup.enter="submit($event.target.value)"
      >
    </div>

    <div v-if="description" class="description font-memo">
      {{ description }}
    </div>

    <div v-if="failure" class="font-denial font-memo">
      {{ message }}
    </div>
  </div>
</template>

<script>
  import { reactive } from "vue";

  export default {
    props: {
      label      : { type: String , default: () => ""     },
      type       : { type: String , default: () => "text" },
      value      : { type: String , default: () => ""     },
      placeholder: { type: String , default: () => ""     },
      description: { type: String , default: () => ""     },
      failure    : { type: Boolean, default: () => false  },
      message    : { type: String , default: () => ""     }
    },

    emits: [
      "submit"
    ],

    setup(props, context) {
      const form = reactive({ edit: false });

      return {
        form,

        clear: () => {
          form.edit = false;
        },

        submit: (value) => {
          context.emit("submit", value);
          form.edit = false;
        }
      };
    }
  };
</script>