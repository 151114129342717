export default {
  ru: {
    Lorida                                   : "Лорида",
    Setting                                  : "Настройки",
    Help                                     : "Помощь",
    logout                                   : "Выход",
    save                                     : "Сохранить",
    copy                                     : "Копировать",
    cut                                      : "Вырезать",
    insert                                   : "Вставить",
    add                                      : "Добавить",
    ok                                       : "Ok",
    finish                                   : "Завершить",
    more                                     : "Подробнее",
    show_more                                : "Показать еще...",
    update                                   : "Изменить",
    Update                                   : "Изменить",
    remove                                   : "Удалить",
    Remove                                   : "Удалить",
    Sort                                     : "Сортировка",
    name                                     : "Имя",
    name_required                            : "Имя *",
    owner                                    : "Владелец",
    search                                   : "Поиск",
    expand                                   : "Развернуть",
    collapse                                 : "Свернуть",
    manage                                   : "управление",
    view                                     : "просмотр",
    close                                    : "Закрыть",
    access                                   : "доступ",
    // "The given data was invalid."            : "Указанные данные недействительны.",
    Deadline                                 : "Интервал времени",
    Estimate                                 : "Оценка",
    Completed_tasks_to_total                 : "Завершенные задачи к общему количеству",
    Files                                    : "Файлы",
    Choose_project                           : "Выберите проект",
    do_you_really_want_to_delete_this_element: "Вы действительно хотите удалить этот елемент",
    Comments                                 : "Комментарии",
    сomment                                  : "Комментарий",
    new_comment                              : "Новый комментарий",
    performer_appointed                      : "Назначен исполнитель",
    stage_change                             : "Смена этапа",
    remove_worker                            : "Снятие исполнителя",
    completed_task                           : "Завершенная задача",
    next                                     : "Далее",
    No_suitable_performer                    : "Нет подходящего исполнителя",
    Performer_not_assigned                   : "Исполнитель не назначен",
    quick_start                              : "Быстрый старт",
    quick_start_simple                       : "Быстрый старт (простой)",
    quick_start_complex                      : "Быстрый старт (комплексный)",
    reset_tutorial                           : "Сбросить шаги обучения",
    tooltip_step_000                         : "Помощь. Здесь вы можете включить обучение по шагам. Во вкладке 'Подробнее' Вы можете ознакомится с видео-уроками.",
    tooltip_step_100                         : "Переходим в раздел 'Идеи'. Идеи будут разбиты по проектам.",
    tooltip_step_101                         : "Один проект по умолчанию уже создан. Нажимаем '+'. Вносим имя во всплывающем окне и нажимаем 'Ок'.",
    tooltip_step_102                         : "Нажимаем на заголовок вновь созданной идеи, чтобы перейти к подробностям идеи.",
    tooltip_step_103                         : "Вносим описание задачи.",
    tooltip_step_104                         : "Возвращаемся на экран 'Идеи'.",
    tooltip_step_105                         : "Запускаем идею в работу нажатием кнопки 'Запуск'. Теперь идея стала задачей и пропала из списка.",
    tooltip_step_106                         : "Переходим на экран 'Планнер'.",
    tooltip_step_107                         : "Задача разместилась в колонке 'Все'.",
    tooltip_step_108                         : "Чтобы начать работу над задачей необходимо перенести ее в колонку 'Сегодня'. Подробнее о механизме планирования можно прочитать <a href='/prompt'>здесь</a>.",
    tooltip_step_109                         : "Теперь задача доступна в трекере.",
    tooltip_step_110                         : "Таймер запускается как только начали работать над задачей. Повторное нажатие останавливает таймер или запускает его.",
    tooltip_step_111                         : "Для завершения задачи переходим на экран задачи, щелкнув мышкой по заголовку задачи в трекере.",
    tooltip_step_112                         : "Убеждаемся, что все условия выполнены и нажимаем кнопку 'Завершить'.",
    tooltip_step_202                         : "Переходим по ссылке в структуру идеи.",
    tooltip_step_203                         : "Правой кнопкой вызываем контекстное меню для создания вложенной идеи.",
    tooltip_step_204                         : "По двойному клику редактируем название идеи. Нажатием на 'Enter' подтверждаем изменение.",
    tooltip_step_205                         : "Вносим описание задачи.",
    Tasks_available_today_tomorrow           : "Задачи становятся доступными в трекере, когда они запланированы на \"Сегодня\" или \"Завтра\""
  },
  en: {
    Lorida                                   : "Lorida",
    Setting                                  : "Setting",
    Help                                     : "Help",
    logout                                   : "Logout",
    save                                     : "Save",
    copy                                     : "Copy",
    cut                                      : "Cut",
    insert                                   : "Insert",
    add                                      : "Add",
    ok                                       : "Ok",
    finish                                   : "Finish",
    more                                     : "More",
    show_more                                : "Show more...",
    update                                   : "Update",
    Update                                   : "Update",
    remove                                   : "Remove",
    Remove                                   : "Remove",
    Sort                                     : "Sort",
    name                                     : "Name",
    name_required                            : "Name *",
    owner                                    : "Owner",
    search                                   : "Search",
    expand                                   : "Expand",
    collapse                                 : "Collapse",
    manage                                   : "manage",
    view                                     : "view",
    close                                    : "Close",
    access                                   : "access",
    // "The given data was invalid."            : "The given data was invalid.",
    Deadline                                 : "Deadline",
    Estimate                                 : "Estimate",
    Completed_tasks_to_total                 : "Completed tasks to total",
    Files                                    : "Files",
    Choose_project                           : "Choose a project",
    do_you_really_want_to_delete_this_element: "Do you really want to delete this element",
    Comments                                 : "Comments",
    сomment                                  : "Сomment",
    new_comment                              : "New comment",
    performer_appointed                      : "Performer appointed",
    stage_change                             : "Stage change",
    remove_worker                            : "Remove_worker",
    completed_task                           : "Completed task",
    next                                     : "Next",
    No_suitable_performer                    : "No suitable performer",
    Performer_not_assigned                   : "Performer not assigned",
    quick_start                              : "Quick start",
    quick_start_simple                       : "Quick start (simple)",
    quick_start_complex                      : "Quick start (complex)",
    reset_tutorial                           : "Reset tutorial",
    tooltip_step_000                         : "Tutorial. Here you can enable step by step learning. In the details tab you can see the video tutorials",
    tooltip_step_100                         : "Go to the 'Ideas' section. Ideas will be broken down into projects.",
    tooltip_step_101                         : "One default project has already been created. Press '+'. Enter a name in the pop-up window and click 'OK'.",
    tooltip_step_102                         : "Click on the title of the newly created idea to go to the details of the idea.",
    tooltip_step_103                         : "Enter a description of the task.",
    tooltip_step_104                         : "We return to the 'Ideas' screen.",
    tooltip_step_105                         : "We launch the idea into work by pressing the 'Launch' button. Now the idea has become a task and disappeared from the list.",
    tooltip_step_106                         : "Go to the 'Schedule' screen.",
    tooltip_step_107                         : "The task is placed in the 'All' column.",
    tooltip_step_108                         : "To start working on a task, you need to move it to the 'Today' column. You can read more about the scheduling mechanism <a href='/prompt'>here</a>.",
    tooltip_step_109                         : "Now the task is available in the tracker.",
    tooltip_step_110                         : "The timer starts as soon as work on the task has started. Pressing again stops the timer or starts it.",
    tooltip_step_111                         : "To complete the task, go to the task screen by clicking on the task title in the tracker.",
    tooltip_step_112                         : "We make sure that all the conditions are met and click the 'Finish' button.",
    tooltip_step_202                         : "We follow the link to the structure.",
    tooltip_step_203                         : "Right-click to call the context menu for creating nested tasks.",
    tooltip_step_204                         : "Double-click to edit the task name. By pressing 'Enter' we confirm the change.",
    tooltip_step_205                         : "Enter a description of the task.",
    Tasks_available_today_tomorrow           : "Tasks available in the tracker scheduled for \"Today\" and \"Tomorrow\""
  }
};