<template>
  <div class="field w-100">
    <div v-if="label" class="font-soft font-memo mb-8">
      {{ label }}
    </div>

    <div v-if="!form.edit"
      class="value d-flex c-crosshair"
      @click="form.edit = true"
    >
      <div class="icon icon-deadline mr-8" />

      <div v-if="value" class="text">
        {{ format(value) }}
      </div>

      <div v-else class="placeholder font-memo font-italic">
        {{ placeholder }}
      </div>
    </div>

    <div v-if="form.edit" class="input p-relative">
      <input
        type="text"
        class="w-100"
        :value="parse_datetime(dateData)"
        placeholder="YYYY-MM-DD"
        @keydown="handle"
      >

      <date-picker
        v-model="dateData"
        locale="ru"
        mode="date"
        is-expanded
      />
    </div>

    <div v-if="description" class="description font-memo">
      {{ description }}
    </div>

    <div v-if="failure" class="font-denial font-memo">
      {{ message }}
    </div>
  </div>
</template>

<script>
  import moment from "moment";

  import {
    ref,
    reactive,
    watch,
    onUpdated
  } from "vue";

  import { DatePicker } from "v-calendar";

  const DATETIME_FORMAT = "YYYY-MM-DD";

  const KEYMAP_ENTER = 13;
  const KEYMAP_ESC   = 27;

  export default {
    components: {
      DatePicker
    },

    props: {
      value      : { type: String , default: () => ""    },
      label      : { type: String , default: () => ""    },
      placeholder: { type: String , default: () => ""    },
      description: { type: String , default: () => ""    },
      failure    : { type: Boolean, default: () => false },
      message    : { type: String , default: () => ""    }
    },

    emits: [
      "submit"
    ],

    setup(props, context) {
      const form = reactive({ edit: false });

      let dateData = ref(props.value);
      watch(() => dateData.value, (value) => { if (props.value != value) { submit_date(value); } });
      onUpdated(() => { dateData.value = props.value; });

      let submit_date = (value) => {
        context.emit("submit", parse_datetime(value));
        form.edit = false;
      };

      let format = (date) => {
        return new Date(date).toLocaleDateString("ru-RU", { dateStyle: "long" });
      };

      let parse_datetime = (datetime) => {
        if (datetime) {
          datetime = moment(datetime, DATETIME_FORMAT);
          return datetime.isValid() ? datetime.format(DATETIME_FORMAT) : "";
        }
        return "";
      };

      return {
        form,
        dateData,

        format,
        parse_datetime,

        handle: (event) => {
          if (event.keyCode == KEYMAP_ENTER) {
            submit_date(event.target.value);
          }
          else if (event.keyCode == KEYMAP_ESC) {
            form.edit = false;
          }
        }
      };
    }
  };
</script>
