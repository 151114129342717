export default {
  ru: {
    prompt                                : "Подсказка",
    video                                 : "Видео",
    creating_and_setting_up_a_new_project : "Создание и настройка нового проекта",
    profile_setup                         : "Настройка профиля",
    create_and_setup_an_organization      : "Создание и настройка организации",
    promoting_a_task_through_a_new_process: "Продвижение задачи по новому процессу",
    structure                             : "Структура",
    task_detail_page                      : "Детальная страница задачи",
  },
  en: {
    prompt                                : "Prompt",
    video                                 : "Video",
    creating_and_setting_up_a_new_project : "Creating and setting up a new project",
    profile_setup                         : "Profile setup",
    create_and_setup_an_organization      : "Create and set up an organization",
    promoting_a_task_through_a_new_process: "Promoting a task through a new process",
    structure                             : "Structure",
    task_detail_page                      : "Task detail page",
  }
};