import SYSTEM from "../system.js";

export default class Roster {
  constructor(response) {
    this.access   = false;
    this.errors   = {};
    this.status   = {};
    this.projects = [];
    this.parent   = {};
    this.nodes    = [];
    this.users    = [];
    this.filters  = {};
    this.cycles   = [];
    this.periods  = [];

    if (response) {
      if (response.access) {
        this.access = Object.values(response.access).includes(SYSTEM.MODULE);
      }

      if (response.errors) {
        this.errors = response.errors;
      }

      if (response.status) {
        this.status = response.status;
      }

      if (response.data) {
        this.projects = response.data.projects;
        this.parent   = response.data.parent;
        this.nodes    = response.data.nodes;
        this.users    = response.data.users;
        this.filters  = response.data.filters;
        this.cycles   = response.data.cycles;
        this.periods  = response.data.periods;
      }
    }
  }
}