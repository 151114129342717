export default {
  ru: {
    copyright                  : "Все права защищены",
    analytics                  : "Аналитика",
    architecture               : "Архитектура",
    management                 : "Менеджмент",
    learn_more                 : "УЗНАТЬ ПОДРОБНЕЕ",
    services                   : "Услуги",
    reviews                    : "Отзывы",
    about                      : "О нас",
    contacts                   : "Контакты",
    consulting                 : "Консалтинг",
    consulting_details         : "Проведем анализ и подготовим подробные рекомендации по повышению качества и производительности Ваших продуктов и организационных процессов",
    seminars                   : "Курсы и семинары",
    seminars_details           : "Организуем уже имеющиеся курсы и семинары или же создадим новые согласно Вашим потребностям",
    development                : "Разработка",
    development_details        : "Разработаем мобильное и/или веб-приложение по предоставленным требованиям",
    shalagin_person            : "Павел Шалагин,<br />Citronium, основатель, генеральный директор",
    shalagin_review            : "Работаю с Олегом с 2012 года, за это время реализовали не один десяток проектов. Олег превосходно показал себя как программист, архитектор, тимлид, наставник для команды разработки. Если в работе вам важны личная вовлеченность, внимание к деталям, надежные поддерживаемые решения, то Олег идеальный кандидат.",
    blakstad_person            : "Оскар Блэкстад,<br />Explorable, основатель, генеральный директор",
    blakstad_review            : "Нанимайте этого парня - перестаньте смотреть! Олег УДИВИТЕЛЬНЫЙ! Он работает на нас много лет. Он очень надежен и действительно хорош в сложных задачах. Он также задает грамотные вопросы, чтобы сформировать отличное решение. Он глубоко разбирается в проблеме, чтобы учесть все детали",
    tatarinov_person           : "Тимофей Татаринов,<br />Мобильные решения для строительства, основатель, генеральный директор",
    tatarinov_review           : "Сотрудничаю с Олегом по вопросам проектирования архитектуры, обучения сотрудников, консалтинга работы компании. Редко встретишь профессионала, который может быстро погрузиться в проблему и выдать качественный результат с четким соблюдением сроков. Хотите быть уверенным в успехе выполнения своей задачи - наймите Олега, однозначно рекомендую.",
    seminars_common            : "Обучение, развитие навыков и умение держать руку на пульсе новейших технологий и трендов — составляющие без которых не добиться серьезных результатов.",
    seminars_details_part_1    : "Это не бонусные факторы, а жизненно важные способности, способствующие динамичному развитию.",
    seminars_details_part_2    : "Мы разработаем и проведем курсы по необходимым технологиям специально под Ваши нужды.",
    courses_skills             : "Знания и навыки участника курса",
    courses_duration           : "Длительность",
    courses_format             : "Формат",
    courses_details            : "Подробнее",
    courses_format_online      : "Онлайн",
    courses_format_your_place  : "Выездной",
    course_logics              : "Логический подход к творческому процессу",
    course_logics_duration     : "6 занятий",
    course_logics_skills_1     : "Умение строить адаптивные конструкции программного обеспечения.",
    course_logics_skills_2     : "Умение выводить шаблоны проектирования (даже не изучая их), а также создавать свои собственные.",
    course_logics_skills_3     : "Знание теоретических основ принципов SOLID и Domain Driven Design, а также практическое их применение.",
    course_logics_skills_4     : "Знание принципов построения гибких и отказоустойчивых решений.",
    course_basics              : "Основы веб-разработки",
    course_basics_duration     : "12 занятий",
    course_basics_skills_1     : "Разрабатывать веб-приложения на связке PHP+MySQL+Javascript",
    course_basics_skills_2     : "Быстро перестраиваться на другие языки программирования",
    course_basics_skills_3     : "Быстро перестраиваться на другие стеки технологий (IIS + MSSQL + .NET или Сервлеты + Java + PostgreSQL)",
    consulting_details_part_1  : "Мы поможем решить Ваши проблемы и сэкономить значительное количество ресурсов",
    analytics_details          : "Мы поможем выявить имеющиеся и потенциальные проблемы продукта, и предложим варианты их устранения",
    analytics_process_step_1   : "Продукт",
    analytics_process_step_2   : "Составные части",
    analytics_process_step_3   : "Уязвимости",
    analytics_process_step_4   : "Отчет + решение",
    architecture_details       : "Мы поможем формализовать бизнес-требования и выстроить гибкую отказоустойчивую архитектуру",
    architecture_process_step_1: "Требования",
    architecture_process_step_2: "Доменная модель",
    architecture_process_step_3: "Архитектура",
    architecture_process_step_4: "Отчет + макет",
    management_details         : "Мы поможем оптимизировать рабочие процессы в Вашей команде",
    management_process_step_1  : "Текущие процессы",
    management_process_step_2  : "Декомпозиция",
    management_process_step_3  : "Структуризация",
    management_process_step_4  : "Рекомендации",
    management_process_step_5  : "Отчет + внедрение",
    innovations                : "Новые технологии",
    innovations_details        : "Мы поможем разработать план и внедрить новые технологии",
    innovations_process_step_1 : "Технология",
    innovations_process_step_2 : "Адаптация под организацию",
    innovations_process_step_3 : "Учебные материалы",
    innovations_process_step_4 : "План внедрения",
    innovations_process_step_5 : "Отчет + внедрение",
    sosorev_alex_person        : "Алексей Сосорев",
    sosorev_alex_review        : "Системный инженерно-конструкторский подход, опыт работы как на крупномасштабных производствах так и самостоятельно, умение качественно выстраивать и автоматизировать технологические процессы, участие в проектах с многомилионными оборотами, а также опыт работы на международном рынке, помогают выстраивать гибкие масштабируемые отказоустойчивые решения",
    sosorev_oleg_person        : "Олег Сосорев",
    sosorev_oleg_review        : "Огромный опыт (свыше 20 лет) в сфере разработки программного обеспечения на всех ролях (от разработчика до технического лидера и менеджера проектов), преподавательская практика в ведущих университетах региона, собственные исследования (более 10 лет) и многолетняя консультативная пратика, а также опыт работы на международной арене, позволяют качественно решать широкий спектр задач в области разработки программного обеспечения"
  },
  en: {
    copyright                  : "All rights reserved",
    analytics                  : "Analytics",
    architecture               : "Architecture",
    management                 : "Management",
    learn_more                 : "LEARN MORE",
    services                   : "Services",
    reviews                    : "Reviews",
    about                      : "About us",
    contacts                   : "Contacts",
    consulting                 : "Consulting",
    consulting_details         : "We will analyze and prepare detailed recommendations how to increase the quality and performance of your products and organizational processes",
    seminars                   : "Courses and Seminars",
    seminars_details           : "We will organize available courses and seminars or create new ones according to your needs",
    development                : "Development",
    development_details        : "We will develop a mobile and / or web application by provided requirements",
    shalagin_person            : "Pavel Shalagin,<br />Citronium, Founder, CEO",
    shalagin_review            : "I've been working with Oleg since 2012, with his help we have already finished 10+ projects. Oleg proven to be a first-class developer, architect, team leader, and tech coach. If high personal involvement, attention to detail, and reliability  is important to you Oleg is the ideal choise.",
    blakstad_person            : "Oskar Blakstad,<br />Explorable, Founder, CEO",
    blakstad_review            : "Hire this guy - stop looking! Oleg is AWESOME! He has worked for us for years. He is highly trustable and really good at complex tasks. He also asks great questions so that he can deliver a great solution. He went on and beyond to fix everything.",
    tatarinov_person           : "Timofey Tatarinov,<br />Mobile Solutions for Construction, Founder, CEO",
    tatarinov_review           : "I work with Oleg on software architecture design, employee training, company consulting. You rarely meet a professional who can quickly immerse himself in the problem and produce a quality result with a clear compliance with the deadlines. Want to be confident in the success of your task - hire Oleg, I definitely recommend.",
    seminars_common            : "Training, development of skills and ability to keep abreast of the latest technologies and trends - the components without which not to achieve significant results.",
    seminars_details_part_1    : "These are not bonus factors, but vital abilities that contribute to the dynamic development",
    seminars_details_part_2    : "We will develop and conduct courses on the necessary technologies specifically tailored to your needs.",
    courses_skills             : "Knowledge and skills of the participant",
    courses_duration           : "Duration",
    courses_format             : "Format",
    courses_details            : "More details",
    courses_format_online      : "Online",
    courses_format_your_place  : "At your place",
    course_logics              : "A Logical Approach to the Creative Process",
    course_logics_duration     : "6 lessons",
    course_logics_skills_1     : "Ability to build adaptive software designs.",
    course_logics_skills_2     : "Ability to deduce design patterns (even without studying them), and create your own.",
    course_logics_skills_3     : "Knowledge of the theoretical foundations of the principles of SOLID and Domain Driven Design and also begin to apply them in practice.",
    course_logics_skills_4     : "Knowledge of the principles of building flexible and fault-tolerant solutions.",
    course_basics              : "Basics of Web Development",
    course_basics_duration     : "32 lessons",
    course_basics_skills_1     : "Develop web applications on a bunch of PHP + MySQL + Javascript",
    course_basics_skills_2     : "Quickly learn new programming languages",
    course_basics_skills_3     : "Quickly change technology stacks (IIS + MSSQL + .NET or Servlets + Java + PostgreSQL)",
    consulting_details_part_1  : "We will help you solve your problems and save a significant amount of resources",
    analytics_details          : "We'll help to detect existing and potential product problems and offer appropriate solutions",
    analytics_process_step_1   : "Product",
    analytics_process_step_2   : "Components",
    analytics_process_step_3   : "Vulnerabilities",
    analytics_process_step_4   : "Report + Solution",
    architecture_details       : "We will help to formalize business requirements and build a flexible, fault-tolerant architecture",
    architecture_process_step_1: "Requirements",
    architecture_process_step_2: "Domain model",
    architecture_process_step_3: "Architecture",
    architecture_process_step_4: "Report + Model",
    management_details         : "We will help to optimize the work processes in your team",
    management_process_step_1  : "Ongoing processes",
    management_process_step_2  : "Decomposition",
    management_process_step_3  : "Structuring",
    management_process_step_4  : "Recommendations",
    management_process_step_5  : "Report + Implementation",
    innovations                : "Innovations",
    innovations_details        : "We will help develop a plan, training materials and introduce new technologies",
    innovations_process_step_1 : "Technology",
    innovations_process_step_2 : "Adaptation for organization",
    innovations_process_step_3 : "Educational materials",
    innovations_process_step_4 : "Implementation Plan",
    innovations_process_step_5 : "Report + Implementation",
    sosorev_alex_person        : "Alexey Sosorev",
    sosorev_alex_review        : "System engineering and design approach, experience both in large-scale production and independently, the ability to build and automate technological processes, participate in projects with multi-million turnover, as well as experience in the international market, help to build flexible scalable fault-tolerant solutions",
    sosorev_oleg_person        : "Oleg Sosorev",
    sosorev_oleg_review        : "Huge experience (over 20 years) in software development on all roles (from developer to technical leader and project manager), teaching practice in leading universities in the region, own research (more than 10 years) and long-term advisory practice, as well as experience in international arena, allow to solve qualitatively a wide range of tasks in the field of software development"
  }
};