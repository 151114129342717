<template>
  <div class="d-flex px-27 py-18">
    <div class="path font-soft">
      <div
        class="member"
        @click.stop="redirect_project(project)"
      >
        {{ project.name }}
      </div>
    </div>

    <div class="path">
      <div v-for="(member, index) in path" :key="index">
        <div
          class="member"
          @click.stop="redirect_schema(index)"
        >
          {{ member }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      project: { type: Object, default: () => {} },
      path   : { type: Array , default: () => [] }
    },

    setup() {
      let redirect_schema  = (code)    => window.location.href = "/schema/"   + code;
      let redirect_project = (project) => window.location.href = "/projects/" + project.id;

      return {
        redirect_schema,
        redirect_project
      };
    }
  };
</script>
