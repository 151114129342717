<template>
  <layout-web>
    <partial-header :active="2" />

    <div
      v-for="item in source.captures"
      :key="item.id"
      class="d-flex"
    >
      <div class="card dark flex-column my-10 px-0 flex-nogrow">
        <div class="d-flex flex-row justify-content-between">
          <router-link
            :to="'/excuses/captures/' + item.id"
            class="text-decoration-none"
          >
            {{ item.content }}
          </router-link>

          <div
            class="icon icon-close icon-hover c-pointer"
            @click="show_popups_remove(item.id)"
          />
        </div>

        <hr class="dark">

        <div class="d-flex mb-5">
          <div class="w-100px">
            {{ $t("reason") }}:
          </div>

          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="item.reason" />
        </div>

        <div class="d-flex mb-5">
          <div class="w-100px">
            {{ $t("solution") }}:
          </div>

          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-html="item.solution" />
        </div>

        <div class="d-flex mb-5">
          <div class="w-100px">
            {{ $t("Excuse") }}:
          </div>

          <div>
            {{ item.excuse.title }}
          </div>
        </div>

        <div class="d-flex mb-5">
          <div class="w-100px">
            {{ $t("Node") }}:
          </div>

          <router-link
            :to="'/node/' + item.code"
            class="text-decoration-none"
          >
            <div class="font-dark">
              {{ item.nodes_title }}
            </div>
          </router-link>
        </div>
      </div>
    </div>

    <!-- Popup: Remove item -->
    <popup-base v-if="popups.remove">
      <template #header>
        <div>{{ $t("remove") }}</div>
        <div class="icon icon-close c-pointer" @click="hide_popups_remove" />
      </template>

      <template #content>
        {{ $t("do_you_really_want_to_delete_this_element") }} - {{ popups.remove }} ?
      </template>

      <template #footer>
        <div class="button primary" @click="remove(popups.remove)">
          {{ $t("ok") }}
        </div>
      </template>
    </popup-base>
  </layout-web>
</template>

<script>
  import {
    reactive,
    onMounted
  } from "vue";

  import LayoutWeb     from "../../../layouts/Web.vue";
  import PartialHeader from "./partials/Header.vue";
  import PopupBase     from "../../../visual/popups/base/Base.vue";

  import CapturesEntity  from "../entities/Captures.js";
  import CapturesService from "../services/Captures.js";

  export default {
    components: {
      LayoutWeb,
      PartialHeader,
      PopupBase
    },

    setup() {
      const source = reactive(new CapturesEntity);

      const popups = reactive({ remove: false });

      const fields = reactive({ nodes_id: "", excuses_id: "", content: "", reason: "", solution: "" });
      const errors = reactive({ nodes_id: false, excuses_id: false, content: false, reason: false, solution: false });

      onMounted(async () => Object.assign(source, await CapturesService.load()));

      let show_popups_remove = function (id) {
        popups.remove = id;
      };

      let hide_popups_remove = function () {
        popups.remove = false;
      };

      return { source, errors, fields, popups,
        show_popups_remove,
        hide_popups_remove,

        remove: async (id) => {
          Object.assign(source, await CapturesService.delete({ id: id }));

          hide_popups_remove();
        }
      };
    }
  };
</script>