import Server from "../../../../../system/server/server";
import Entity from "../entities/File.js";

export default class File {
  static async load(scope_id, entity_id) {
    return new Entity(await Server.post("files", {
      scopes_id       : scope_id,
      scopes_entity_id: entity_id
    }));
  }

  static async upload(data) {
    return new Entity(await Server.upload("files/upload", data));
  }

  static async delete(id, scope_id, entity_id) {
    return new Entity(await Server.post("files/delete", {
      id              : id,
      scopes_id       : scope_id,
      scopes_entity_id: entity_id
    }));
  }
}
