<template>
  <div class="field w-100">
    <div v-if="label" class="font-soft font-memo mb-8">
      {{ label }}
    </div>

    <div v-if="!form.edit"
      class="d-flex overflow-wrap c-crosshair"
      @click="form.edit = true"
    >
      <!-- eslint-disable-next-line vue/no-v-html -->
      <div v-if="value" class="ck-content w-100" v-html="value" />

      <div v-else class="placeholder font-memo font-italic">
        {{ placeholder }}
      </div>
    </div>

    <div v-if="form.edit" class="ckeditor-wrapper">
      <div class="d-flex justify-content-end mb-5">
        <div
          class="font-denial c-pointer"
          @click="clear"
        >
          {{ $t("cancel") }}
        </div>

        <div
          class="font-highlight ml-8 c-pointer"
          @click="submit"
        >
          {{ $t("save") }}
        </div>
      </div>

      <ckeditor
        v-model="editorData"
        :editor="editor"
        :config="editorConfig"
      />
    </div>

    <div v-if="description" class="description font-memo">
      {{ description }}
    </div>

    <div v-if="failure" class="font-denial font-memo">
      {{ message }}
    </div>
  </div>
</template>

<script>
  import {
    ref,
    reactive,
    watch
  } from "vue";

  import Server from "../../../../system/server/server.js";

  import CKEditor      from "@ckeditor/ckeditor5-vue";
  import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

  import FileAdapter from "../adapters/File.js";

  export default {
    components: {
      ckeditor: CKEditor.component
    },

    props: {
      scope_id   : { type: Number , default: () => 4  },
      entity_id  : { type: Number , default: () => 0  },
      value      : { type: String , default: () => "" },
      label      : { type: String , default: () => "" },
      placeholder: { type: String , default: () => "" },
      description: { type: String , default: () => "" },
      failure    : { type: Boolean, default: false    },
      message    : { type: String , default: () => "" }
    },

    emits: [
      "submit"
    ],

    setup(props, context) {
      const form = reactive({ edit: false });

      let editor       = ClassicEditor;
      let editorData   = ref(props.value);
      let editorConfig = {
        language: "ru",
        toolbar: {
          items: [ "heading", "|", "bold", "italic", "link", "bulletedList", "numberedList", "|", "indent", "outdent", "|", "imageUpload", "blockQuote", "mediaEmbed", "undo", "redo" ],
        },
        image: {
          toolbar: ["imageTextAlternative", "imageStyle:full", "imageStyle:side"]
        },
        mediaEmbed: {
          previewsInData: true
        },
        extraPlugins: [(editor) => {
          editor.plugins.get( "FileRepository" ).createUploadAdapter = ( loader ) => {
            return new FileAdapter( loader, Server.token(), props.scope_id, props.entity_id );
          };
        }],
      };

      watch(() => props.value, (value) => { editorData.value = value; });

      return {
        editor,
        editorData,
        editorConfig,

        form,

        clear: () => {
          editorData.value = props.value;
          form.edit = false;
        },

        submit: () => {
          context.emit("submit", editorData.value);
          form.edit = false;
        }
      };
    }
  };
</script>
