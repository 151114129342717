export default {
  ru: {
    Status         : "Статус",
    Tasks          : "Задачи",
    ideas          : "Идеи",
    tasks          : "Задачи",
    Worker         : "Исполнитель",
    Filters        : "Фильтры",
    add_draft      : "Создать идею",
    show_root_ideas: "Показать коренные идеи",
    Structure      : "Структура",
    Sort_by        : "Сортировка по",
    Default        : "По умолчанию",
    By_ID          : "По идентификатору",
    By_name        : "По назывнию",
    By_deadline    : "По сроку",
    Limited        : "Доступ: Ограничен",
    Shared         : "Доступ: Публичный"
  },
  en: {
    Status         : "Status",
    Tasks          : "Tasks",
    ideas          : "Ideas",
    tasks          : "Tasks",
    Worker         : "Worker",
    Filters        : "Filters",
    add_draft      : "Add draft",
    show_root_ideas: "Show root ideas",
    Structure      : "Structure",
    Sort_by        : "Sort by",
    Default        : "Default",
    By_ID          : "By ID",
    By_name        : "By name",
    By_deadline    : "By deadline",
    Limited        : "Access: Limited",
    Shared         : "Access: Shared"
  }
};