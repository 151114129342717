<template>
  <div class="d-flex base-neutral border-radius-16 mx-27 mb-16 px-27 py-18">
    <field-editor-combo
      :scope_id="scope_id"
      :entity_id="entity_id"
      :value="value"
      :placeholder="$t('please_insert_description')"
      @submit="(value) => $emit('update', 'description', value)"
    />
  </div>
</template>

<script>
  import FieldEditorCombo from "../../../../visual/fields/editor/combo/Combo.vue";

  export default {
    components: {
      FieldEditorCombo,
    },

    props: {
      scope_id : { type: Number, default: () => 4  },
      entity_id: { type: Number, default: () => 0  },
      value    : { type: String, default: () => "" }
    },

    emits: [
      "update"
    ]
  };
</script>
