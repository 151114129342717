import Server from "../../../system/server/server";
import Entity from "../entities/Roster";
import Schema from "../entities/Schema";

export default class Roster {
  static async load() {
    return new Entity(await Server.get("roadmap"));
  }

  static async break_sprints(roadmap_id, value) {
    return new Entity(await Server.post("roadmap/sprints", {
      id   : roadmap_id,
      value: value
    }));
  }

  static async create(project_id, node_id, name) {
    return new Entity(await Server.post("roadmap/create", { project_id: project_id, id: node_id, name: name }));
  }

  static async create_sprint(project_id, id) {
    return new Entity(await Server.post("roadmap/create/sprint", { project_id: project_id, id: id }));
  }

  static async remove_milestone(project_id, id) {
    return new Entity(await Server.post("roadmap/remove", { project_id: project_id, id: id }));
  }

  static async move_node(project_id, source, target) {
    return new Entity(await Server.post("roadmap/move", { project_id: project_id, source: source, target: target }));
  }

  static async shipped(project_id, milestones_id) {
    return new Entity(await Server.post("roadmap/shipped", { project_id: project_id, milestones_id: milestones_id }));
  }

  static async period(project_id, milestones_id, start, finish) {
    return new Entity(await Server.post("roadmap/period", { project_id: project_id, milestones_id: milestones_id, start: start, finish: finish }));
  }

  static async order(project_id, target, source, offset) {
    return new Entity(await Server.post("roadmap/order", { project_id: project_id, target: target, source: source, offset: offset }));
  }

  static async deadline(project_id, node_id, deadline) {
    return new Entity(await Server.post("roadmap/deadline", { project_id: project_id, node_id: node_id, deadline: deadline }));
  }

  static async complexity(project_id, node_id, complexity) {
    return new Entity(await Server.post("roadmap/complexity", { project_id: project_id, node_id: node_id, complexity: complexity }));
  }

  static async reset_node_sprint(project_id, node) {
    return new Entity(await Server.post("roadmap/reset", { project_id: project_id, node_id: node.id }));
  }

  static async schema_create_node_child(id, filters, target) {
    return new Schema(await Server.post("schema/child", { id: id, filters: filters, target: target }));
  }

  static async schema_create_node_sibling(id, filters, target) {
    return new Schema(await Server.post("schema/sibling", { id: id, filters: filters, target: target }));
  }

  static async schema_delete_bundle(id, filters, node) {
    return new Schema(await Server.post("schema/remove", { id: id, filters: filters, node: node }));
  }

  static async schema_move_node(id, filters, node_id, nodes_target_id, location) {
    return new Schema(await Server.put("schema/move", { id: id, filters: filters, node_id: node_id, nodes_target_id: nodes_target_id, location: location }));
  }

  static async schema_insert(id, source_id, target_id) {
    return new Schema(await Server.post("schema/insert", { id: id, source_id: source_id, target_id: target_id }));
  }

  static async schema_update_node_option(id, filters, option) {
    return new Schema(await Server.put("schema/option", { id: id, filters: filters, option: option }));
  }

  static async states(project_id, option, value) {
    return new Entity(await Server.post("roadmap/states", {
      id    : project_id,
      option: option,
      value : value
    }));
  }

  static async priority(projects) {
    return new Entity(await Server.post("roadmap/priority", {
      list: projects
    }));
  }
}