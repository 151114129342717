import Server from "../../../../system/server/server";
import Entity from "../entities/Tracker";

export default class Tracker {
  static async assignee() {
    return new Entity(await Server.get("tracker"));
  }

  static async activate(state_id) {
    return new Entity(await Server.post("tracker", { status_state_id: state_id }));
  }
}