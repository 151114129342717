export default {
  ru: {
    Calendar        : "Календарь",
    add_event       : "Создать событие",
    create          : "Создать",
    insert_title    : "Укажите название",
    insert_project  : "Укажите проект",
    insert_cycle    : "Укажите процесс",
    insert_time     : "Укажите время",
    title_is_empty  : "Название не указано",
    project_is_empty: "Проект не указан",
    cycle_is_empty  : "Процесс не указан",
  },
  en: {
    Calendar        : "Calendar",
    add_event       : "Add event",
    create          : "Create",
    insert_title    : "Insert title",
    insert_project  : "Insert project",
    insert_cycle    : "Insert cycle",
    insert_time     : "Insert time",
    title_is_empty  : "Title is empty",
    project_is_empty: "Project is empty",
    cycle_is_empty  : "Cycle is empty",
  }
};