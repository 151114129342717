<template>
  <div
    ref="viewport_node"
    class="p-relative viewport_node flex-grow"
    @wheel.ctrl.prevent="scaling"
  >
    <charts-canvas
      ref="canvas_node"
      tabindex="0"

      :mode="mode"
      :root="root"
      :nodes="nodes"
      :links="links"
      :layout="layout.value"
      :progress="progress"

      :active="active"
      :selected="selected"

      :region="canvas_size"
      :viewport="viewport_size"

      @chart-move="bundle => $emit('move', bundle)"
      @chart-child="bundle => $emit('child', bundle)"
      @chart-child-placeholder="target => $emit('child_placeholder', target)"
      @chart-sibling="bundle => $emit('sibling', bundle)"
      @chart-sibling-placeholder="target => $emit('sibling_placeholder', target)"
      @chart-cancel-placeholder="$emit('cancel_placeholder')"
      @chart-remove="value => $emit('remove', value)"
      @chart-fold="option => $emit('fold', option)"
      @chart-select="value => $emit('select', value)"
      @chart-focused="value => $emit('focused', value)"
      @chart-cancel="value => $emit('cancel', value)"
      @chart-insert="(source_id, target_id, action) => $emit('insert', source_id, target_id, action)"
      @chart-deadline="(id, deadline) => $emit('deadline', id, deadline)"
      @chart-complexity="(id, complexity) => $emit('complexity', id, complexity)"

      @chart-crumb="value => $emit('plugin_crumb_handler', value)"

      @keyup.h.prevent="$emit('plugin_helper_handler')"

      @chart-update="update_layout"
      @location="update_overview_location"
    />

    <charts-overview
      class="base-light"
      :nodes="nodes"
      :links="links"
      :layout="layout.value"
      :region="canvas_size"
      :viewport="viewport_size"

      @location="update_overview_location"
    />

    <teleport v-if="plugin_filter" :to="plugin.filter">
      <charts-plugin-filter
        class="d-flex base-light justify-content-end"
        :values="plugin_filter_values"
        @handler="filters => $emit('plugin_filter_handler', filters)"
      />
    </teleport>

    <teleport v-if="plugin_crumbs" :to="plugin.crumbs">
      <charts-plugin-crumbs
        :root="root"
        :path="path"
        @handler="index => $emit('plugin_crumb_handler', index)"
      />
    </teleport>

    <teleport v-if="plugin_picker" :to="plugin.picker">
      <charts-plugin-picker
        @handler="option => $emit('plugin_picker_handler', option)"
      />
    </teleport>

    <charts-plugin-helper v-if="plugin_helper"
      :value="plugin_helper_opened"
      @handler="() => $emit('plugin_helper_handler')"
    />
  </div>
</template>

<script>
  import {
    ref,
    reactive,
    watch,
    nextTick,
    onMounted
  } from "vue";

  import Layout from "./Layout.js";

  import ChartsCanvas  from "./markup/Canvas.vue";
  import ChartsOverview from "./markup/Overview.vue";

  import ChartsPluginHelper from "./plugin/Helper.vue";
  import ChartsPluginFilter from "./plugin/Filter.vue";
  import ChartsPluginCrumbs from "./plugin/Crumbs.vue";
  import ChartsPluginPicker from "./plugin/Picker.vue";

  export default {
    components: {
      ChartsCanvas,
      ChartsOverview,
      ChartsPluginHelper,
      ChartsPluginFilter,
      ChartsPluginCrumbs,
      ChartsPluginPicker,
    }, 

    props: {
      mode                : { type: String , default: () => "full" },
      root                : { type: Number , default: () => 0      },
      nodes               : { type: Array  , default: () => []     },
      links               : { type: Array  , default: () => []     },
      path                : { type: Object , default: () => {}     },
      active              : { type: Object , default: () => {}     },
      selected            : { type: Number , default: () => 0      },
      progress            : { type: Array  , default: () => []     },
      plugin_crumbs       : { type: String , default: () => ""     },
      plugin_helper       : { type: String , default: () => ""     },
      plugin_helper_opened: { type: Boolean, default: () => false  },
      plugin_filter       : { type: String , default: () => ""     },
      plugin_filter_values: { type: Object , default: () => {}     },
      plugin_picker       : { type: String , default: () => ""     }
    },

    emits: [
      "move",
      "child",
      "sibling",
      "remove",
      "fold",
      "select",
      "focused",
      "cancel",
      "insert",
      "deadline",
      "complexity",
      "child_placeholder",
      "sibling_placeholder",
      "cancel_placeholder",
      "plugin_crumb_handler",
      "plugin_helper_handler",
      "plugin_filter_handler",
      "plugin_picker_handler"
    ],

    setup(props) {
      const canvas_node = ref();

      const canvas_size = reactive({
        width : CANVAS_WIDTH,
        height: CANVAS_HEIGHT
      });

      const viewport_node = ref();

      const viewport_size = reactive({
        left  : 0,
        top   : 0,
        width : 0,
        height: 0,
      });

      // @FIXME: Need to make global hidden div probably or find a way to fix issue with initial teleport 
      const plugin = reactive({
        helper: "body",
        filter: "body",
        crumbs: "body",
        picker: "body"
      });

      onMounted(() => {
        plugin.helper = props.plugin_helper;
        plugin.filter = props.plugin_filter;
        plugin.crumbs = props.plugin_crumbs;
        plugin.picker = props.plugin_picker;

        viewport_size.width  = viewport_node.value.getBoundingClientRect().width;
        viewport_size.height = viewport_node.value.getBoundingClientRect().height;

        update_layout(props.nodes, props.links);
      });

      watch([ () => props.nodes, () => props.links ], async ([nodes, links]) => {
        update_layout(nodes, links);
      });

      function update_overview_location(left, top) {
        viewport_size.top    = top;
        viewport_size.left   = left;
        viewport_size.width  = viewport_node.value.getBoundingClientRect().width;
        viewport_size.height = viewport_node.value.getBoundingClientRect().height;
      }

      const layout = reactive({ value: {} });

      async function update_layout(nodes, links) {
        /**
         * @IMPORTANT!
         * 
         * To recalculate layout we need the latest sizes of nodes in the DOM.
         * That's why we need to have actually rendered DOM.
         * This could be achieved by waiting next cycle: await nextTick();
         * 
         * Example.
         * 
         * When we show drop targets for the node the size is increased.
         * Then we drop the moving node outside targets. Targets are hide, but
         * node size for these targets is still big (added heights of targets).
         * 
         * If we just recalculate layout then it will be broken as node size is
         * still big. So, we need to await until DOM is re-rendered (await nextTick())
         * and only then make a recalculation.
         */
        await nextTick();

        layout.value = {};

        nodes.forEach(element => {
          layout.value[element.entity.id] = JSON.parse(JSON.stringify(
            canvas_node.value.elements[element.entity.id].getBoundingClientRect()
          ));
        });

        layout.value = Layout.detect(nodes, links, [props.root], layout.value);

        canvas_size.width  = Math.max(layout.value.scene.width, 2000);
        canvas_size.height = Math.max(layout.value.scene.height, 1000) + (Math.max(layout.value.scene.height, 1000)* 0.15);
      }

      const scale = ref(1);

      function scaling(event) {
        scale.value += event.deltaY > 0 ? -0.05 : 0.05;
        canvas_node.value.canvas.style.MozTransform = scale.value;
        canvas_node.value.canvas.style.OTransform = scale.value;
        canvas_node.value.canvas.style.zoom = scale.value;
      }

      return {
        canvas_node,
        canvas_size,
        viewport_node,
        viewport_size,
        plugin,
        layout,

        update_overview_location,
        update_layout,
        scaling
      };
    }
  };

  const CANVAS_WIDTH  = 1000;
  const CANVAS_HEIGHT = 1000;
</script>