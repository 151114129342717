import Server from "../../../system/server/server";
import Entity from "../entities/Detail";

export default class Detail {
  static async load(id) {
    return new Entity(await Server.get("projects/" + id));
  }

  static async update(project) {
    return new Entity(await Server.put("projects/" + project.id, project));
  }
}