<template>
  <div
    class="card card-wrapper "
    :style="{ 'border-color': (partials.includes('color') && !!item.color && item.color) }"
  >
    <div class="p-8">
      <div
        class="header"
        :class="{ 'c-pointer': path }"
        @click="redirect"
      >
        <div class="short-text font-weight-500">
          {{ item.name || item.title }}
        </div>

        <cards-partial-id v-if="partials.includes('id') && item.id"
          :id="item.id"
          :selected="selected"
        />

        <cards-partial-code v-if="partials.includes('code') && (item.id || item.code)"
          :code="item.code || item.id"
          :selected="selected"
        />
      </div>

      <div class="content">
        <cards-partial-description v-if="partials.includes('description') && item.description"
          :description="item.description"
        />

        <cards-partial-type v-if="partials.includes('type') && item.type"
          :type="item.type"
        />

        <cards-partial-weight v-if="partials.includes('weight') && item.weight"
          :weight="item.weight"
        />

        <div class="d-flex mt-5">
          <cards-partial-idea v-if="partials.includes('idea')"
            class="mr-8 lh-18"
            :count="item.drafts"
            :selected="selected"
          />

          <cards-partial-work v-if="partials.includes('work')"
            class="mr-8 lh-18"
            :count="item.nodes"
            :selected="selected"
          />

          <div class="d-flex align-items-center ml-8 w-100">
            <cards-partial-progress-complete v-if="partials.includes('progress-complete')"
              class="w-100"
              :complete="item.complete"
              :selected="selected"
            />
          </div>
        </div>
      </div>
    </div>

    <cards-partial-menu v-if="partials.includes('menu') && menu"
      :item="item"
      :actions="actions.list"
    />

    <!-- Popup: Remove item -->
    <popup-base v-if="popup.remove">
      <template #header>
        <div>{{ $t("Remove") }}</div>

        <div
          class="icon icon-close c-pointer"
          @click="popup.remove = false"
        />
      </template>

      <template #content>
        {{ $t("do_you_really_want_to_delete_this_element") }} - {{ item.name }} ?
      </template>

      <template #footer>
        <div class="button primary" @click="remove(item.id)">
          Ok
        </div>
      </template>
    </popup-base>
  </div>
</template>

<script>
  import {
    reactive,
    onMounted,
    watch
  } from "vue";

  import CardsPartialDescription      from "../partials/description/Description.vue";
  import CardsPartialId               from "../partials/id/Id.vue";
  import CardsPartialCode             from "../partials/code/Code.vue";
  import CardsPartialType             from "../partials/type/Type.vue";
  import CardsPartialWeight           from "../partials/weight/Weight.vue";
  import CardsPartialIdea             from "../partials/idea/Idea.vue";
  import CardsPartialWork             from "../partials/work/Work.vue";
  import CardsPartialProgressComplete from "../partials/progress/complete/Progress.vue";
  import CardsPartialMenu             from "../partials/menu/Menu.vue";

  import PopupBase from "../../../popups/base/Base.vue";

  export default {
    components: {
      CardsPartialDescription,
      CardsPartialId,
      CardsPartialCode,
      CardsPartialType,
      CardsPartialWeight,
      CardsPartialIdea,
      CardsPartialWork,
      CardsPartialProgressComplete,
      CardsPartialMenu,
      PopupBase
    },

    props: {
      item    : { type: Object, default: () => {} },
      path    : { type: String, default: () => "" },
      menu    : { type: Array , default: () => [] },
      partials: { type: Array , default: () => [] }
    },

    emits: [
      "remove"
    ],

    setup(props, context) {
      const actions = reactive({ list: []});
      const popup   = reactive({ remove: false });

      onMounted(() => {
        props.menu.forEach((item) => {
          if (item === "details") {
            actions.list.push({ title: "Details", handler: redirect });
          }
          else if (item === "remove") {
            actions.list.push({ title: "Remove", handler: hide });
          }
          else {
            actions.list.push(item);
          }
        });
      });

      watch(() => props.menu, async (menu) => {
        actions.list = [];
        menu.forEach((item) => {
          if (item === "details") {
            actions.list.push({ title: "Details", handler: redirect });
          }
          else if (item === "remove") {
            actions.list.push({ title: "Remove", handler: hide });
          }
          else {
            actions.list.push(item);
          }
        });
      });

      let hide = () => popup.remove = true;

      let redirect = () => {
        if (props.path) { window.location.href = props.path; }
      };

      return {
        actions,
        popup,

        redirect,

        remove: (id) => {
          context.emit("remove", id);
          popup.remove = false;
        },
      };
    }
  };
</script>