<template>
  <div class="p-relative">
    <div
      class="d-flex p-relative justify-content-center align-items-center border-radius-12 box-shadow-bottom w-32px h-32px c-pointer"
      :class="{
        'base-neutral': !dropdown,
        'base-highlight': dropdown
      }"
      @click.stop="dropdown = !dropdown"
    >
      <div
        class="icon"
        :class="{
          'icon-sort': !dropdown,
          'icon-sort-hover': dropdown
        }"
      />

      <div v-if="item.sort && item.sort != 'created_at ASC' && !dropdown"
        class="p-absolute base-highlight w-10px h-10px border-radius-8 t--2px r--2px"
      />
    </div>

    <div v-if="dropdown"
      class="p-absolute t-39px r-0 w-200px p-16 base-neutral z-107 box-shadow-bottom border-radius-16 d-flex flex-column"
    >
      <div>
        <div class="font-memo font-light mb-16 lh-16">
          {{ $t("Sort") }}
        </div>

        <div v-for="element in
          [
            { id: 'created_at ASC', name: $t('Default') },
            { id: 'id ASC', name: $t('By_ID') + ' ↑' },
            { id: 'id DESC', name: $t('By_ID') + ' ↓' },
            { id: 'title ASC', name: $t('By_name') + ' ↑' },
            { id: 'title DESC', name: $t('By_name') + ' ↓' },
            { id: 'deadline ASC', name: $t('By_deadline') + ' ↑' },
            { id: 'deadline DESC', name: $t('By_deadline') + ' ↓' }
          ]"
          :key="element.id"
        >
          <div
            class="c-pointer border-radius-4 p-4"
            :class="{ 'base-highlight': element.id == item.sort }"
            @click="$emit('handler', item.id, element.id )" 
          >
            {{ element.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";

  export default {
    props: {
      item: { type: Object, default: () => {} }
    },

    emits: [
      "handler"
    ],

    setup() {
      const dropdown = ref(false);

      return {
        dropdown
      };
    }
  };
</script>