<template>
  <div class="p-absolute r--7px b--7px z-104">
    <div
      class="finish box-shadow-bottom z-104"
      @click.stop="finish"
    >
      <div class="icon icon-finish" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      item: { type: Object, default: () => {} }
    },

    emits: [
      "handler"
    ],

    setup(props, context) {
      return {
        finish: () => {
          context.emit("handler", { id: props.item.id, status_state_id: props.item.status_states_id });
        }
      };
    }
  };
</script>

<style lang="scss" scoped>
  .card-wrapper {
    .finish { display: none; }
    &:hover {
      .finish { display: flex; }
    }
  }
</style>