<template>
  <div class="p-absolute r--7px b--7px z-104">
    <div v-if="item.chains_cycles_id"
      class="launch box-shadow-bottom z-104"
      @click.stop="launch(item.chains_cycles_id)"
    >
      <div class="icon icon-launch" />
    </div>

    <div v-else-if="cycles.length == 1"
      class="launch box-shadow-bottom z-104"
      @click.stop="launch(cycles[0].id)"
    >
      <div class="icon icon-launch" />
    </div>

    <div v-else
      class="launch box-shadow-bottom z-104"
      @click.stop="dropdown = !dropdown"
    >
      <div class="icon icon-launch" />
    </div>

    <div v-if="dropdown"
      class="d-flex flex-column p-absolute border-radius-16 t-0 r-0 w-200px p-5 base-neutral box-shadow-bottom z-104"
      @mouseleave="dropdown = !dropdown"
    >
      <div
        v-for="cycle in cycles"
        :key="cycle.id"
        class="my-5 p-5 border-radius-5 hover-base-light c-pointer short-title"
        @click.stop="launch(cycle.id)"
      >
        {{ cycle.name }}
      </div>
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";

  export default {
    props: {
      item  : { type: Object, default: () => {} },
      cycles: { type: Array , default: () => [] }
    },

    emits: [
      "handler"
    ],

    setup(props, context) {
      const dropdown = ref(false);

      return {
        dropdown,

        launch: (id) => {
          context.emit("handler", { node_id: props.item.id, cycle_id: id });
        }
      };
    }
  };
</script>

<style lang="scss" scoped>
  .card-wrapper {
    .launch { display: none; }
    &:hover {
      .launch { display: flex; }
    }
  }
</style>