<template>
  <div class="d-flex base-neutral border-radius-16 mx-27 mb-16 px-27 py-18">
    <widget-users-workers v-if="workers.length"
      :workers="workers"
      @submit="(route, value) => $emit('update', route, value)"
    />
  </div>
</template>

<script>
  import WidgetUsersWorkers from "../../../../visual/widgets/users/workers/Workers.vue";

  export default {
    components: {
      WidgetUsersWorkers,
    },

    props: {
      workers: { type: Array, default: () => [] }
    },

    emits: [
      "update"
    ],

    setup() {
      return {

      };
    }
  };
</script>
