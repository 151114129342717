<template>
  <div class="p-absolute r--7px b--7px z-104">
    <div
      class="create box-shadow-bottom z-104"
      @click.stop="$emit('handler', item)"
    >
      <div class="icon icon-plus" />
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      item: { type: Object, default: () => {} }
    },

    emits: [
      "handler"
    ]
  };
</script>

<style lang="scss" scoped>
  .card-wrapper {
    .create { display: none; }
    &:hover {
      .create { display: flex; }
    }
  }
</style>