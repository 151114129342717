<template>
  <layout-web>
    <partial-header :active="2" />

    <div class="form">
      <field-input
        v-model="fields.content"
        type="text"
        :description="$t('content_required')"
        :placeholder="$t('content')"
        :message="errors.content"
        :failure="errors.content"
      />

      <field-editor
        v-model="fields.reason"
        :description="$t('reason_required')"
        :placeholder="$t('reason')"
        :message="errors.reason"
        :failure="errors.reason"
      />

      <field-editor
        v-model="fields.solution"
        :description="$t('solution_required')"
        :placeholder="$t('solution')"
        :message="errors.solution"
        :failure="errors.solution"
      />

      <div class="d-flex">
        <div class="button primary" @click="submit">
          {{ $t("save") }}
        </div>
      </div>
    </div>
  </layout-web>
</template>

<script>
  import {
    reactive,
    onMounted
  } from "vue";

  import LayoutWeb     from "../../../../layouts/Web.vue";
  import PartialHeader from "../partials/Header.vue";
  import FieldInput    from "../../../../visual/fields/input/Input.vue";
  import FieldEditor   from "../../../../visual/fields/editor/Editor.vue";

  import DetailEntity    from "../../entities/Detail";
  import CapturesService from "../../services/Captures.js";

  export default {
    components: {
      LayoutWeb,
      PartialHeader,
      FieldInput,
      FieldEditor
    },

    props: {
      id: { type: String, default: () => "" }
    },

    setup(props) {
      const source = reactive(new DetailEntity);

      const fields = reactive({ content: "", reason: "", solution: "" });
      const errors = reactive({ content: false, reason: false, solution: false });

      onMounted(async () => {
        Object.assign(
          source,
          await CapturesService.item(props.id)
        );

        fields.content  = source.capture.content;
        fields.reason   = source.capture.reason;
        fields.solution = source.capture.solution;
      });

      let remove_errors = function () {
        errors.content    = false;
        errors.reason     = false;
        errors.solution   = false;
      };

      return { source, fields, errors,
        submit: async () => {
          remove_errors();

          Object.assign(
            source,
            await CapturesService.update(Object.assign(fields, {
              id: props.id
            }))
          );

          if (source.errors.length) {
            for (const [key, value] of Object.entries(source.errors)) {
              errors[key] = value[0];
            }
          }
          else {
            window.location.href = "/excuses/captures/";
          }
        }
      };
    }
  };
</script>