<template>
  <div
    class="short-text"
    :class="{ 'font-dark': !selected, 'font-neutral': selected }"
  >
    &#9878; {{ complexity }}
  </div>
</template>

<script>
  export default {
    props: {
      complexity: { type: String , default: () => ""    },
      selected   : { type: Boolean, default: () => false }
    }
  };
</script>