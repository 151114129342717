import Element from "./entity/Element";

export default {
  computed(items) {
    let bundle = 1;
    let result = [];

    items && items.forEach(item => {
      if (item.target == TARGET_MENU) {
        if (bundle < item.bundle) {
          result.push(new Element({type : "divider"}));
          bundle++;
        }
  
        result.push(new Element(item));
      }
    });

    return result;
  }
};

const TARGET_MENU = "menu";