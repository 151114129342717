export default class Comment {
  constructor(response) {
    this.status   = {};
    this.comments = [];

    if (response) {
      if (response.status) {
        this.status = response.status;
      }

      if (response.data) {
        this.comments = response.data.comments;
      }
    }
  }
}