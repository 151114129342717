import { createApp } from "vue";

import { createI18n } from "vue-i18n";

import {
  createRouter,
  createWebHistory
} from 'vue-router';

import LayouApp from './layouts/App.vue';

import * as definitions_routes0 from "./app/calendar/routes.js"; import * as definitions_routes1 from "./app/excuses/routes.js"; import * as definitions_routes2 from "./app/node/routes.js"; import * as definitions_routes3 from "./app/people/routes.js"; import * as definitions_routes4 from "./app/profile/routes.js"; import * as definitions_routes5 from "./app/projects/routes.js"; import * as definitions_routes6 from "./app/prompt/routes.js"; import * as definitions_routes7 from "./app/roadmap/routes.js"; import * as definitions_routes8 from "./app/schedule/routes.js"; import * as definitions_routes9 from "./app/schema/routes.js"; import * as definitions_routes10 from "./app/space/routes.js"; import * as definitions_routes11 from "./app/stream/routes.js"; import * as definitions_routes12 from "./app/tasks/routes.js"; import * as definitions_routes13 from "./system/access/routes.js"; import * as definitions_routes14 from "./system/failure/routes.js"; import * as definitions_routes15 from "./system/landing/routes.js"; let definitions_routes = [definitions_routes0, definitions_routes1, definitions_routes2, definitions_routes3, definitions_routes4, definitions_routes5, definitions_routes6, definitions_routes7, definitions_routes8, definitions_routes9, definitions_routes10, definitions_routes11, definitions_routes12, definitions_routes13, definitions_routes14, definitions_routes15];
import * as definitions_locale0 from "./app/calendar/locale.js"; import * as definitions_locale1 from "./app/chains/locale.js"; import * as definitions_locale2 from "./app/employees/locale.js"; import * as definitions_locale3 from "./app/excuses/locale.js"; import * as definitions_locale4 from "./app/modules/locale.js"; import * as definitions_locale5 from "./app/node/locale.js"; import * as definitions_locale6 from "./app/people/locale.js"; import * as definitions_locale7 from "./app/profile/locale.js"; import * as definitions_locale8 from "./app/projects/locale.js"; import * as definitions_locale9 from "./app/prompt/locale.js"; import * as definitions_locale10 from "./app/roadmap/locale.js"; import * as definitions_locale11 from "./app/roles/locale.js"; import * as definitions_locale12 from "./app/schedule/locale.js"; import * as definitions_locale13 from "./app/schema/locale.js"; import * as definitions_locale14 from "./app/space/locale.js"; import * as definitions_locale15 from "./app/stream/locale.js"; import * as definitions_locale16 from "./app/tasks/locale.js"; import * as definitions_locale17 from "./layouts/web/locale.js"; import * as definitions_locale18 from "./system/access/locale.js"; import * as definitions_locale19 from "./system/landing/locale.js"; let definitions_locale = [definitions_locale0, definitions_locale1, definitions_locale2, definitions_locale3, definitions_locale4, definitions_locale5, definitions_locale6, definitions_locale7, definitions_locale8, definitions_locale9, definitions_locale10, definitions_locale11, definitions_locale12, definitions_locale13, definitions_locale14, definitions_locale15, definitions_locale16, definitions_locale17, definitions_locale18, definitions_locale19];

let routes = [];

definitions_routes.forEach(definition => routes = routes.concat(definition.default));

let Router = createRouter({
  history: createWebHistory(),
  routes,
});

let locales = {};

definitions_locale.forEach(locale => {
  locales.ru = {...locales.ru, ...locale.default.ru};
  locales.en = {...locales.en, ...locale.default.en};
});

const I18n = createI18n({
  locale        : "ru",
  fallbackLocale: "en",
  messages      : locales,
});

let app = createApp(LayouApp);
app.use(I18n);
app.use(Router);
app.mount('#app');

app.config.globalProperties.$t = I18n.global.t