import "regenerator-runtime"; // https://github.com/adi518/vue-facebook-login/issues/17#issuecomment-544247970

import Server from "../system/server/server";

export default {
  async status() {
    let response = await Server.get("access");

    return {
      person: response.data.person,
      status: response.data.status
    };
  }
};