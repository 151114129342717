<template>
  <ul class="tabs d-flex base-neutral flex-wrap m-0 p-0">
    <li
      v-for="tab in tabs"
      :key="tab.id"
      class="tab d-flex align-items-center"
      :class="{ active: tab.active || state[tab.id]}"
      @click="click(tab)"
      @mouseleave="hide(tab)"
    >
      <div class="item d-flex flex-grow align-items-center justify-content-center c-pointer">
        {{ $t(tab.name) }}

        <div v-if="state[tab.id]" class="dropdown">
          <div
            v-for="item in tab.list"
            :key="item.id"
            class="tab d-flex align-items-center"
            @click="click(item)"
          >
            <div class="item d-flex flex-grow align-items-center justify-content-center c-pointer">
              {{ $t(item.name) }}
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
  import { reactive  } from "vue";

  export default {
    props: {
      tabs: { type: Array, default: () => [] }
    },

    emits: [
      "activate"
    ],

    setup(props, context) {
      const state = reactive([]);

      return { state,
        hide: (tab) => { state[tab.id] = false; },

        click: (tab) => {
          if (tab.list) {
            state[tab.id] = !state[tab.id];
          }
          else {
            context.emit("activate", tab);
          }
        }
      };
    }
  };
</script>