<template>
  <layout-web v-slot="slotProps" fullscreen>
    <header-partial v-if="source.node.id"
      :project="source.project"
      :path="source.path[source.node.id]"
    />

    <div v-if="source.node.id" class="d-flex">
      <div class="d-flex flex-column w-65 w-md">
        <progress-partial
          :workers="source.workers"
          @update="update_workers"
        />

        <title-partial
          :id="source.node.id"
          :code="source.node.code"
          :value="source.node.title"
          :actions="source.actions"
          @update="update_node"
          @remove="remove_node"
          @finish="finish_node"
          @onward="onward_node"
          @backward="backward_node"
        />

        <description-partial
          :scope_id="scope"
          :entity_id="source.node.id"
          :value="source.node.description && source.node.description.replace(token.regex, token.value)"
          @update="update_node"
        />

        <extension-partial
          :id="source.node.id"
          :scope_id="scope"
          :users="source.users"  
          :history="source.history"
          :timings="source.timings"
        />
      </div>

      <div class="d-flex flex-column w-33 w-md p-sticky t-17px h-800px">
        <sidebar-partial
          :id="source.node.id"
          :scope_id="scope"
          :users_id="slotProps.user.id"

          :cycles_id="source.subject && source.subject.chains_cycles_id || 0"
          :complexities="source.complexities"
          :categories_id="source.category && source.category.categories_id || 0"
          :timing="source.timing[source.node.id]"
          :deadline="source.node.deadline"

          :leaves="source.leaves"
          :cycles="source.cycles"
          :states="source.states"
          :users="source.users"
          :watchers="source.watchers"
          :categories="source.categories"

          @update="update_node"
          @update_complexities="update_complexities"
          @update_timing="update_timing"
          @update_watcher="update_watcher"
        />
      </div>
    </div>
  </layout-web>
</template>

<script>
  import {
    ref,
    reactive,
    onMounted
  } from "vue";

  import Config from "../../../config.js";
  import Server from "../../../system/server/server.js";

  import LayoutWeb from "../../../layouts/Web.vue";

  import HeaderPartial      from "./partials/Header.vue";
  import ProgressPartial    from "./partials/Progress.vue";
  import TitlePartial       from "./partials/Title.vue";
  import DescriptionPartial from "./partials/Description.vue";
  import ExtensionPartial   from "./partials/Extension.vue";
  import SidebarPartial     from "./partials/Sidebar.vue";

  import DetailEntity  from "../entities/Detail.js";
  import DetailService from "../services/Detail.js";

  export default {
    components: {
      LayoutWeb,
      HeaderPartial,
      ProgressPartial,
      TitlePartial,
      DescriptionPartial,
      ExtensionPartial,
      SidebarPartial
    },

    props: {
      code: { type: String, default: () => "" }
    },

    setup(props) {
      const source = reactive(new DetailEntity);
      const scope  = ref(Config.SCOPE_NODE_ID);
      const token  = reactive({ value: "token=" + Server.token() + "\"", regex: new RegExp("token=([\\w,|]*)\"","g") });

      onMounted(async () => {
        Object.assign(source, await DetailService.load(props.code));
      });

      return {
        source,
        scope,
        token,

        remove_node: async () => {
          Object.assign(source, await DetailService.remove(source.node.id));
          if (!source.node) window.location.href = "/plan";
        },

        finish_node: async () => {
          Object.assign(source, await DetailService.finish(source.node.id));
        },

        onward_node: async () => {
          let status_state = source.states.find((state) => state.chains_series_id == Config.SERIES_STATUS);
          Object.assign(source, await DetailService.onward(source.node.id, status_state.id));
          window.location.href = "/plan";
        },

        backward_node: async () => {
          Object.assign(source, await DetailService.backward(source.node.id));
          window.location.href = "/plan";
        },

        update_node: async (field, value) => {
          Object.assign(source, await DetailService.update(source.node.id, field, value));
        },

        update_complexities: async (value, route) => {
          Object.assign(source, await DetailService.complexities(source.node.id, value, route));
        },

        update_workers: async (route_id, user_id) => {
          Object.assign(source, await DetailService.workers(source.node.id, route_id, user_id));
        },

        update_watcher: async (user_id) => {
          Object.assign(source, await DetailService.watcher(source.node.id, user_id));
        },

        update_timing: async (manual_timing, reason, created) => {
          let status_state = source.states.find((state) => state.chains_series_id == Config.SERIES_ROLE);
          Object.assign(source, await DetailService.timing(manual_timing, reason, created, source.node.id, status_state.id));
        }
      };
    }
  };
</script>