<template>
  <div class="d-flex flex-column">
    <div v-if="label" class="label">
      {{ label }}
    </div>

    <div class="input">
      <field-input
        v-model="fields.value"
        type="text"
        :failure="failures.value"
        :placeholder="$t('add_comment')"
        :message="$t('comment_is_empty')"
        @keyup="create"
      />
    </div>

    <div class="value">
      <div
        v-for="comment in source.comments"
        :key="comment.id"
        class="comment d-flex justify-content-between my-10"
      >
        <div>
          <div class="user font-light mb-5">
            {{ comment.name }} {{ comment.lastname }} ( {{ format(comment.created_at) }} )
          </div>

          <div class="content">
            {{ comment.content }}
          </div>
        </div>

        <div>
          <div class="icon icon-close icon-hover-white c-pointer" @click="remove(comment.id)" />
        </div>
      </div>
    </div>

    <div v-if="description" class="description font-memo">
      {{ description }}
    </div>
  </div>
</template>

<script>
  import {
    reactive,
    onMounted
  } from "vue";

  import FieldInput from "../../../fields/input/Input.vue";

  import CommentEntity  from "../entities/Comment.js";
  import CommentService from "../services/Comment.js";

  const KEYCODE_ENTER = 13;
  const KEYCODE_ESC   = 27;

  export default {
    components: {
      FieldInput
    },

    props: {
      scope_id   : { type: String, default: () => "" },
      entity_id  : { type: String, default: () => "" },
      label      : { type: String, default: () => "" },
      description: { type: String, default: () => "" },
      users      : { type: Array , default: () => [] }
    },

    setup(props) {
      const source   = reactive(new CommentEntity);
      const fields   = reactive({ value: "" });
      const failures = reactive({ value: false });

      onMounted(async () => {
        Object.assign(source, await CommentService.load({
          scopes_id       : props.scope_id,
          scopes_entity_id: props.entity_id
        }));
      });

      return { source, fields, failures,
        format: (date) => {
          return new Date(date).toLocaleDateString("ru-RU", { dateStyle: "long" });
        },

        create: async (event) => {
          if (event.keyCode == KEYCODE_ENTER) {
            if (fields.value != "") {
              failures.value = false;

              Object.assign(source, await CommentService.create({
                scopes_id       : props.scope_id,
                scopes_entity_id: props.entity_id,
                content         : fields.value
              }));

              fields.value = "";
            }
            else {
              failures.value = fields.value == "" ? true : false;
            }
          }
          else if (event.keyCode == KEYCODE_ESC) {
            fields.value = "";
          }
        },

        remove: async (id) => {
          Object.assign(source, await CommentService.remove({
            scopes_id       : props.scope_id,
            scopes_entity_id: props.entity_id,
            id              : id
          }));
        }
      };
    },
  };
</script>
