<template>
  <!-- eslint-disable-next-line vue/no-mutating-props vue/max-attributes-per-line -->
  <div v-if="node.entity.id == 0" :ref="element => { elements[node.entity.id] = element }"
    :class="'card-wrapper node node-' + node.entity.id + ' w-200px base-neutral border-radius-4 box-shadow-bottom'"
    :data-id="node.entity.id"
    :style="style()"
    draggable="false"
    tabindex="1"
  >
    <div
      class="content"
      draggable="false"
    >
      <field-input
        v-model="title"
        class="editor"
        :autofocus="true"
        :placeholder="$t('name')"
        @keyup.esc="$emit('canvas-cancel')"
        @keyup.enter="submit"
      />
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";

  import FieldInput from "../../../../visual/fields/input/Input.vue";

  export default {
    components: {
      FieldInput
    },

    props: {
      elements: { type: Object, default: () => {} },
      node    : { type: Object, default: () => {} },
      links   : { type: Array , default: () => [] },
      layout  : { type: Object, default: () => {} }
    },

    emits: [
      "canvas-child",
      "canvas-sibling",
      "canvas-cancel"
    ],

    setup(props, context) {
      const elements = ref(props.elements || []);
      const title    = ref("");

      return {
        // eslint-disable-next-line vue/no-dupe-keys
        elements,
        title,

        submit: () => {
          if (props.node.entity.type == "child") {
            context.emit('canvas-child', { id: props.node.entity.target, title: title.value });
          }
          else {
            context.emit('canvas-sibling', { id: props.node.entity.target, title: title.value });
          }

          title.value = "";
        },

        style: () => {
          return "z-index: " + 21 + "; " + (props.layout.nodes && props.layout.nodes[props.node.entity.id] || 'opacity: 0;');
        }
      };
    }
  };
</script>

<style lang="scss" scoped>
  .node {
    position: absolute;
    cursor: pointer;
    border: 1px solid transparent;

    &:focus-visible {
      outline: 1px solid transparent;
    }

    & > .content {
      display: flex;
      align-items: center;
      position: relative;
    }
  }
</style>