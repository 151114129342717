import Server from "../../../system/server/server";
import Entity from "../entities/Detail";

export default class Detail {
  static async load(id) {
    return new Entity(await Server.get("spaces/" + id));
  }

  static async update(space) {
    return new Entity(await Server.put("spaces/" + space.id, space));
  }
}