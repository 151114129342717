import Server from "../../../system/server/server";
import Entity from "../entities/Roster";

export default class Roster {
  static async load() {
    return new Entity(await Server.get("projects"));
  }

  static async create(project) {
    return new Entity(await Server.post("projects", project));
  }

  static async delete(project) {
    return new Entity(await Server.delete("projects/" + project.id));
  }
}