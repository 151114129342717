<template>
  <div
    class="d-flex justify-content-center align-items-center p-relative w-100 text-center base-dark font-neutral hover-neutral c-pointer"
    @click="toggle"
    @mouseleave="hide"
  >
    {{ user.name }} {{ user.lastname }}

    <div v-if="state"
      class="p-absolute c-pointer w-inherit base-dark font-neutral t-60px r-0 z-110"
    >
      <div class="h-1px color-border-light" />

      <div
        v-for="item in items"
        :key="item.name"
        class="d-flex justify-content-center align-items-center text-capitalize text-nowrap hover-neutral h-30px p-5 c-pointer"
        :class="item.type"
        @click="redirect(item.path)"
      >
        {{ $t(item.name) }}
      </div>

      <div class="h-1px color-border-light" />

      <div
        class="d-flex justify-content-center align-items-center hover-neutral h-30px p-5 c-pointer"
        @click="logout"
      >
        {{ $t("logout") }}
      </div>
    </div>
  </div>
</template>

<script>
  import {
    ref
  } from "vue";

  import Menu from "./Menu.js";

  export default {
    props: {
      user : { type: Object, default: () => {} },
      items: { type: Array , default: () => [] }
    },

    setup () {
      const state = ref(false);

      return {
        state,

        logout: async () => await Menu.logout(),

        toggle: () => { state.value = state.value ? false : true; },
        hide  : () => { state.value = false; },

        redirect: (url) => document.location.href = url
      };
    }
  };
</script>