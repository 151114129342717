export default class Schema {
  constructor(response) {
    this.categories = [];
    this.releases   = [];
    this.cycles     = [];
    this.nodes      = [];
    this.links      = [];
    this.leaves     = [];
    this.goals      = [];
    this.related    = [];
    this.path       = {};

    if (response) {
      if (response.data) {
        this.categories = response.data.categories;
        this.cycles     = response.data.cycles;
        this.goals      = response.data.goals;
        this.leaves     = response.data.leaves;
        this.links      = response.data.links;
        this.nodes      = response.data.nodes;
        this.path       = response.data.path;
        this.related    = response.data.related;
        this.releases   = response.data.releases;
      }
    }
  }
}