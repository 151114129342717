import Server from "../../../system/server/server";
import Entity from "../entities/Roster";

export default class Roster {
  static async load() {
    return new Entity(await Server.get("tasks"));
  }

  static async search(keyword = "") {
    return new Entity(await Server.get("tasks?keyword=" + keyword));
  }

  static async create(projects_id, root_id, title, keyword = "") {
    return new Entity(await Server.post("tasks", {
      title      : title,
      projects_id: projects_id,
      root_id    : root_id,
      keyword    : keyword
    }));
  }

  static async create_bundle(projects_id, root_id, title, keyword = "") {
    return new Entity(await Server.post("tasks/bundle", {
      title      : title,
      projects_id: projects_id,
      root_id    : root_id,
      keyword    : keyword
    }));
  }

  static async remove(node_id) {
    return new Entity(await Server.post("tasks/remove", {
      node_id: node_id
    }));
  }

  static async finish(node_id) {
    return new Entity(await Server.post("tasks/finish", {
      node_id: node_id
    }));
  }

  static async insert(data) {
    return new Entity(await Server.post("tasks/insert", data));
  }

  static async states(project_id, option, value, keyword = "") {
    return new Entity(await Server.post("tasks/states", {
      id     : project_id,
      option : option,
      value  : value,
      keyword: keyword
    }));
  }

  static async priority(projects, keyword = "") {
    return new Entity(await Server.post("tasks/priority", {
      list   : projects,
      keyword: keyword
    }));
  }

  static async onward(data) {
    return new Entity(await Server.post("tasks/onward", data));
  }

  static async period(data) {
    return new Entity(await Server.post("tasks/period", data));
  }

  static async move(data) {
    return new Entity(await Server.post("tasks/move", data));
  }

  static async structure(id, target, source, offset, keyword = "") {
    return new Entity(await Server.post("tasks/structure", {
      id     : id,
      target : target,
      source : source,
      offset : offset,
      keyword: keyword
    }));
  }

  static async access(id, type) {
    return new Entity(await Server.post("tasks/access", { id: id, type: type }));
  }
}