<template>
  <div v-if="source.access">
    <div class="font-section font-weight-500 mb-16">
      {{ source.role.name }}
    </div>

    <div class="box-grid col-5">
      <widget-card-checklist
        v-for="unit in source.units"
        :key="unit.id"
        :title="unit.name"
        :items="[unit]"
        @change="change"
      />
    </div>
  </div>

  <div v-else-if="!source.access && mounted" class="failure">
    {{ $t("access_is_closed") }}
  </div>
</template>

<script>
  import {
    ref,
    reactive,
    onMounted
  } from "vue";

  import WidgetCardChecklist from "../../../../visual/widgets/cards/checklist/Checklist.vue";

  import DetailEntity  from "../../entities/Detail.js";
  import DetailService from "../../services/Detail.js";

  export default {
    components: {
      WidgetCardChecklist
    },

    props: {
      spaces_id: { type: String, default: () => "" },
      role_id : { type: String, default: () => "" }
    },

    setup(props) {
      const source = reactive(new DetailEntity);

      const mounted = ref(false);
      onMounted(async () => {
        Object.assign(source, await DetailService.load(props.spaces_id, props.role_id));
        mounted.value = true;
      });

      return { source, mounted,
        change: async (unit) => Object.assign(
          source,
          await DetailService.change(props.role_id, {
            spaces_id : props.spaces_id,
            roles_id  : props.role_id,
            modules_id: unit.modules_id,
          })
        )
      };
    }
  };
</script>