<template>
  <layout-landing>
    <div class="scrollbar overflow-y-scroll h-100">
      <div class="h-80px w-100" />
      <HeaderPartial />
      <IntroPartial />
      <OverviewPartial />
      <ServicesPartial />
      <SeminarsPartial />
      <ReviewsPartial />
      <TeamPartial />
      <ContactsPartial />
    </div>
  </layout-landing>
</template>

<script>
  import LayoutLanding from "../../../layouts/Landing.vue";

  import HeaderPartial         from "./partials/Header.vue";
  import IntroPartial          from "./partials/Intro.vue";
  import OverviewPartial       from "./partials/Overview.vue";
  import ServicesPartial       from "./partials/Services.vue";
  import SeminarsPartial       from "./partials/Seminars.vue";
  import ReviewsPartial        from "./partials/Reviews.vue";
  import TeamPartial           from "./partials/Team.vue";
  import ContactsPartial       from "./partials/Contacts.vue";

  export default {
    components: {
      LayoutLanding,
      HeaderPartial,
      IntroPartial,
      OverviewPartial,
      ServicesPartial,
      SeminarsPartial,
      ReviewsPartial,
      TeamPartial,
      ContactsPartial
    },

    setup() {
      return {

      };
    }
  };
</script>

<style lang="scss">
  .landing {
    color: #68788D;

    .container-fluid {
      // max-width: 1280px;
      position: relative;

      .title {
        color: #68788D;
        font-size: 200%;
      }
    }

    .logo {
      width: 140px;
      height: 35px;
      min-width: 140px;
      background: url("@/themes/light/images/landing/logo2.svg");
    }

    button.details {
      color: #FFF;
      border:0;
      width: 230px;
      height: 60px;
      font-size: 87.5%;
      background: #4F8BDB;
      border-radius: 30px;
      box-shadow: 0 0 10px rgba(0,0,0,0.5);
    }

    .section > .title {
      color: #68788D;
      font-size: 200%;
    }

    .section > .content {
      font-size: 112.5%;
    }
  }
</style>