<template>
  <div class="p-absolute r--7px b--7px z-104">
    <div
      class="period box-shadow-bottom z-104"
      @click.stop="dropdown = !dropdown"
    >
      <div class="icon icon-period" />
    </div>

    <div v-if="dropdown"
      class="d-flex flex-column p-absolute border-radius-16 t-0 r-0 w-200px p-5 base-neutral box-shadow-bottom z-104"
      @mouseleave="dropdown = !dropdown"
    >
      <div
        v-for="period in periods"
        :key="period.id"
        class="my-5 p-5 border-radius-5 hover-base-light c-pointer short-title"
        @click.stop="handler(period.id)"
      >
        {{ format(period.start) }} - {{ format(period.finish) }}
      </div>
    </div>
  </div>
</template>

<script>
  import { ref } from "vue";

  export default {
    props: {
      item   : { type: Object, default: () => {} },
      periods: { type: Array , default: () => [] }
    },

    emits: [
      "handler"
    ],

    setup(props, context) {
      const dropdown = ref(false);

      return {
        dropdown,

        format: (date) => {
          return new Date(date).toLocaleDateString("ru-RU", { dateStyle: "long" });
        },

        handler: (id) => {
          context.emit("handler", { node_id: props.item.id, period_id: id });
          dropdown.value = !dropdown.value;
        }
      };
    }
  };
</script>

<style lang="scss" scoped>
  .card-wrapper {
    .period { display: none; }
    &:hover {
      .period { display: flex; }
    }
  }
</style>