<template>
  <div
    class="card main card-wrapper box-shadow-card"
    tabindex="1"
    :class="{ 'selected': selected }"
    @keydown.ctrl.c="copy_node"
    @keydown.ctrl.x="cut_node"
    @keydown.ctrl.v="insert_node"
    @click.ctrl.exact="redirect_node"
    @click.shift.exact="redirect_schema"
  >
    <div class="header p-8 c-pointer border-bottom-1 border-color-transparent">
      <div
        class="title short-text font-weight-500"
        :class="{ 'font-title': !selected, 'font-neutral': selected }"
      >
        {{ item.name || item.title }}
      </div>

      <div class="d-flex">
        <cards-partial-icon v-if="partials.includes('icon')"
          :item="item"
          :selected="selected"
        />

        <cards-partial-id v-if="partials.includes('id') && item.id"
          :id="item.id"
          :selected="selected"
        />

        <cards-partial-code v-if="partials.includes('code') && (item.id || item.code)"
          :code="item.code || item.id"
          :selected="selected"
        />
      </div>
    </div>

    <div class="content p-8">
      <cards-partial-description v-if="partials.includes('description') && item.description"
        :description="item.description"
        :selected="selected"
      />

      <cards-partial-project v-if="partials.includes('project') && item.project"
        :project="item.project"
        :selected="selected"
      />

      <cards-partial-complexity v-if="partials.includes('complexity') && item.complexity"
        :complexity="item.complexity"
        :selected="selected"
      />

      <cards-partial-path v-if="partials.includes('path') && item.path"
        class="mt-8"
        :suffix="item.projects_name"
        :path="item.path"
        @handler="redirect_schema_code"
      />

      <div class="mt-auto">
        <div v-if="(partials.includes('schedule') && item.schedule) || (partials.includes('deadline') && item.deadline)"
          class="d-flex justify-content-between mt-8"
        >
          <div>
            <cards-partial-schedule v-if="partials.includes('schedule') && item.schedule"
              :date="item.schedule"
              :selected="selected"
            />
          </div>

          <div>
            <cards-partial-deadline v-if="partials.includes('deadline') && item.deadline"
              :date="item.deadline"
              :selected="selected"
            />
          </div>
        </div>

        <cards-partial-period-value v-if="partials.includes('period-value') && item.period_start"
          class="mt-8"
          :start="item.period_start"
          :finish="item.period_finish"
          :selected="selected"
        />

        <cards-partial-progress-stages v-if="partials.includes('progress-stages')"
          class="mt-8"
          :stages="item.progress"
          :selected="selected"
        />
      </div>
    </div>

    <cards-partial-menu v-if="partials.includes('menu') && menu"
      :item="item"
      :actions="actions.list"
    />

    <cards-partial-finish v-if="partials.includes('finish')"
      :class="{'r-30px-imp': partials.includes('close')}"
      :item="item"
      @handler="(data) => $emit('finish', data)"
    />

    <cards-partial-close v-if="partials.includes('close')"
      :item="item"
      @handler="(data) => $emit('close', data)"
    />

    <cards-partial-launch v-if="partials.includes('launch') && cycles"
      :item="item"
      :cycles="cycles"
      @handler="(data) => $emit('onward', data)"
    />

    <cards-partial-period v-if="partials.includes('period') && periods"
      :item="item"
      :periods="periods"
      @handler="(data) => $emit('period', data)"
    />
  </div>
</template>

<script>
  import {
    reactive,
    onMounted,
    watch
  } from "vue";

  import CardsPartialId             from "../partials/id/Id.vue";
  import CardsPartialCode           from "../partials/code/Code.vue";
  import CardsPartialIcon           from "../partials/icon/Icon.vue";
  import CardsPartialDescription    from "../partials/description/Description.vue";
  import CardsPartialProject        from "../partials/project/Project.vue";
  import CardsPartialComplexity     from "../partials/complexity/Complexity.vue";
  import CardsPartialDeadline       from "../partials/deadline/Deadline.vue";
  import CardsPartialSchedule       from "../partials/schedule/Schedule.vue";
  import CardsPartialMenu           from "../partials/menu/Menu.vue";
  import CardsPartialPath           from "../partials/path/Path.vue";
  import CardsPartialProgressStages from "../partials/progress/stages/Progress.vue";
  import CardsPartialClose          from "../partials/close/Close.vue";
  import CardsPartialLaunch         from "../partials/launch/Launch.vue";
  import CardsPartialPeriod         from "../partials/period/Period.vue";
  import CardsPartialPeriodValue    from "../partials/period-value/Period.vue";
  import CardsPartialFinish         from "../partials/finish/Finish.vue";

  export default {
    components: {
      CardsPartialId,
      CardsPartialCode,
      CardsPartialIcon,
      CardsPartialDescription,
      CardsPartialProject,
      CardsPartialComplexity,
      CardsPartialDeadline,
      CardsPartialSchedule,
      CardsPartialMenu,
      CardsPartialPath,
      CardsPartialProgressStages,
      CardsPartialClose,
      CardsPartialLaunch,
      CardsPartialPeriod,
      CardsPartialPeriodValue,
      CardsPartialFinish
    },

    props: {
      item    : { type: Object , default: () => {}    },
      menu    : { type: Array  , default: () => []    },
      cycles  : { type: Array  , default: () => []    },
      periods : { type: Array  , default: () => []    },
      partials: { type: Array  , default: () => []    },
      selected: { type: Boolean, default: () => false }
    },

    emits: [
      "close",
      "onward",
      "finish",
      "period",
      "insert",
      "accesses"
    ],

    setup(props, context) {
      const actions = reactive({ list: []});

      onMounted(() => init_menu(props.menu));
      watch(() => props.menu, async (menu) => init_menu(menu));

      let init_menu = (menu) => {
        actions.list = [];

        menu.forEach((item) => {
          if      (item === "details")   { actions.list.push({ title: "Details"  , handler: redirect_node  , hotkey: "Ctrl + Click"  }); }
          else if (item === "strucrure") { actions.list.push({ title: "Structure", handler: redirect_schema, hotkey: "Shift + Click" }); }
          else if (item === "copy")      { actions.list.push({ title: "copy"     , handler: copy_node      , hotkey: "Click + C"     }); }
          else if (item === "cut")       { actions.list.push({ title: "cut"      , handler: cut_node       , hotkey: "Click + X"     }); }
          else if (item === "insert")    { actions.list.push({ title: "insert"   , handler: insert_node    , hotkey: "Click + V"     }); }
          else if (item === "limited")   { actions.list.push({ title: "Limited"  , handler: set_limited                              }); }
          else if (item === "shared")    { actions.list.push({ title: "Shared"   , handler: set_shared                               }); }
          else                           { actions.list.push(item);                                                                      }
        });
      };

      let redirect_node   = () => window.location.href = "/node/"   + props.item.code;
      let redirect_schema = () => window.location.href = "/schema/" + props.item.code;

      let copy_node = () => {
        localStorage.setItem("id", props.item.id);
        localStorage.setItem("action", "copy");
      };
      let cut_node = () => {
        localStorage.setItem("id", props.item.id);
        localStorage.setItem("action", "cut");
      };
      let insert_node = () => {
        let id = localStorage.getItem("id");
        if (id) {
          context.emit("insert", props.item.id, id, localStorage.getItem("action"));
          localStorage.removeItem("id");
          localStorage.removeItem("action");
        }
      };

      let set_limited = () => { context.emit("accesses", props.item.id, 2); };
      let set_shared  = () => { context.emit("accesses", props.item.id, 3); };

      let redirect_schema_code = (code) => window.location.href = "/schema/" + code;

      return {
        actions,

        redirect_node,
        redirect_schema,
        redirect_schema_code,

        copy_node,
        cut_node,
        insert_node,
        set_limited,
        set_shared
      };
    }
  };
</script>