import Server from "../../../system/server/server";
import Entity from "../entities/Detail";

export default class Detail {
  static async load(code, filters) {
    return new Entity(await Server.get("schema/" + code + "?archived=" + filters.archived + "&progress=" + filters.progress));
  }

  static async create_node_child(id, filters, target) {
    return new Entity(await Server.post("schema/child", { id: id, filters: filters, target: target }));
  }

  static async create_node_sibling(id, filters, target) {
    return new Entity(await Server.post("schema/sibling", { id: id, filters: filters, target: target }));
  }

  static async update_node_option(id, filters, option) {
    return new Entity(await Server.put("schema/option", { id: id, filters: filters, option: option }));
  }

  static async update_node_style(id, filters, style) {
    return new Entity(await Server.put("schema/style", { id: id, filters: filters, style: style }));
  }

  static async update_root(id, filters) {
    return new Entity(await Server.put("schema/root", { id: id, filters: filters }));
  }

  static async move_node(id, filters, node_id, nodes_target_id, location) {
    return new Entity(await Server.put("schema/move", { id: id, filters: filters, node_id: node_id, nodes_target_id: nodes_target_id, location: location }));
  }

  static async onward_node(id, filters, entity) {
    return new Entity(await Server.post("schema/onward", { id: id, filters: filters, entity: entity }));
  }

  static async insert(id, source_id, target_id) {
    return new Entity(await Server.post("schema/insert", { id: id, source_id: source_id, target_id: target_id }));
  }

  static async delete_bundle(id, filters, node) {
    return new Entity(await Server.post("schema/remove", { id: id, filters: filters, node: node }));
  }
}