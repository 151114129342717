import ProjectsRoster from './screens/Roster.vue';
import ProjectDetail  from './screens/Detail.vue';

export default [
  {
    path: "/projects",
    name: "Projects",
    component: ProjectsRoster
  }, {
    path: "/projects/:id",
    name: "ProjectDetail",
    component: ProjectDetail,
    props: true
  }
];