import SpaceRoster      from './screens/Roster.vue';
import SpaceDetail      from './screens/Detail.vue';
import SpaceModules     from './screens/Modules.vue';
import SpaceRoles       from './screens/Roles.vue';
import SpaceRolesDetail from './screens/roles/Detail.vue';
import SpaceChains      from './screens/Chains.vue';
import SpaceChainDetail from './screens/chains/Detail.vue';

export default [
  {
    path: "/spaces",
    name: "Space",
    component: SpaceRoster
  }, {
    path: "/spaces/:id",
    name: "SpaceDetail",
    component: SpaceDetail,
    props: true
  }, {
    path: "/spaces/:id/modules",
    name: "SpaceModules",
    component: SpaceModules,
    props: true
  }, {
    path: "/spaces/:id/roles",
    name: "SpaceRoles",
    component: SpaceRoles,
    props: true
  }, {
    path: "/spaces/:id/roles/:role_id",
    name: "SpaceRolesDetail",
    component: SpaceRolesDetail,
    props: true
  }, {
    path: "/spaces/:id/chains",
    name: "SpaceChains",
    component: SpaceChains,
    props: true
  }, {
    path: "/spaces/:id/chains/:chain_id",
    name: "SpaceChainDetail",
    component: SpaceChainDetail,
    props: true
  }
];