import SYSTEM from "../system.js";

export default class Detail {
  constructor(response) {
    this.access        = false;
    this.errors        = {};
    this.status        = {};
    this.node          = {};
    this.actions       = {};
    this.project       = {};
    this.subject       = {};
    this.states        = [];
    this.path          = [];
    this.category      = {};
    this.categories    = [];
    this.journey       = {};
    this.release       = [];
    this.release_nodes = [];
    this.releases      = [];
    this.complexities  = [];
    this.leaves        = [];
    this.leaves_nodes  = [];
    this.users         = [];
    this.workers       = [];
    this.watchers      = [];
    this.cycles        = {};
    this.steps         = [];
    this.history       = [];
    this.timing        = [];
    this.timings       = [];

    if (response) {
      if (response.access) {
        this.access = Object.values(response.access).includes(SYSTEM.MODULE);
      }

      if (response.errors) {
        this.errors = response.errors;
      }

      if (response.status) {
        this.status = response.status;
      }

      if (response.data) {
        this.node          = response.data.node;
        this.actions       = response.data.actions;
        this.project       = response.data.project;
        this.subject       = response.data.subject;
        this.states        = response.data.states;
        this.path          = response.data.path;
        this.category      = response.data.category;
        this.categories    = response.data.categories;
        this.journey       = response.data.journey;
        this.release       = response.data.release;
        this.release_nodes = response.data.release_nodes;
        this.releases      = response.data.releases;
        this.complexities  = response.data.complexities;
        this.leaves        = response.data.leaves;
        this.leaves_nodes  = response.data.leaves_nodes;
        this.users         = response.data.users;
        this.workers       = response.data.workers;
        this.watchers      = response.data.watchers;
        this.cycles        = response.data.cycles;
        this.steps         = response.data.steps;
        this.history       = response.data.history;
        this.timing        = response.data.timing;
        this.timings       = response.data.timings;
      }
    }
  }
}