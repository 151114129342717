<template>
  <div class="d-flex w-100" @click.stop="$emit('choose', 'progress')">
    <div class="p-relative w-32px h-32px">
      <div class="icon large" :class="icon_percent()" />
      <div class="icon small p-absolute t-9px l-9px" :class="icon_status()" />
    </div>

    <div
      class="d-flex w-100 h-32px border-top-1 border-right-1 border-bottom-1 border-radius-0-16-16-0"
      :class="style()"
    >
      <div class="d-flex flex-column justify-content-center align-items-center pl-2 pr-8">
				<div class="font-section" :class="style()">
					{{ complete(stages) || 0 }}
				</div>

				<div class="font-soft">
					{{ total(stages) || 0 }}
				</div>
			</div>

      <div v-for="stage in stages" :key="stage.state_id">
        <div v-if="stage.state_status == 'current'"
          class="my-3 mx-8"
        >
          <img v-if="stage.avatar && stage.state_status"
            class="item object-fit-cover"
            :src="url(stage.user_id)"
          >

          <div v-else-if="!stage.avatar && stage.state_status"
            class="item"
            :class="{ 'base-selection': stage.state_status == 'history', 'base-highlight': stage.state_status == 'current' }"
          >
            {{ title(stage.worker) }}
          </div>
        </div>
      </div>
		</div>
  </div>
</template>

<script>
  import {
    ref,
    onMounted,
    watch
  } from "vue";

  import Config from "../../../../../config.js";
  import Server from "../../../../../system/server/server.js";

  export default {
    props: {
      stages: { type: Array  , default: () => [] }
    },

    emits: [
      "clear",
      "choose",
      "submit"
    ],

    setup(props) {
      const api = ref(Config.API);

      const percent = ref(0);

      onMounted(() => recount(props.stages));
      watch(() => props.stages, async (stages) => recount(stages));

      let recount = (stages) => {
        if (props.stages.length) { percent.value = Math.ceil((complete(stages) / stages.length * 100) / 5) * 5; }
      };

      let icon_percent = () => {
        if (percent.value > 200) { return "progress-percent-200"; }
        else { return "progress-percent-" + percent.value; }
      };

      let icon_status = () => {
        if      (percent.value == 0)                       { return "progress-completed-empty";    }
        else if (percent.value > 0 && percent.value < 100) { return "progress-completed-progress"; }
        else if (percent.value == 100)                     { return "progress-completed-full";     }
        else if (percent.value > 100)                      { return "progress-completed-denial";   }
      };

      let style = () => {
        if      (percent.value == 0)                       { return "color-border-soft font-soft";           }
        else if (percent.value > 0 && percent.value < 100) { return "color-border-highlight font-highlight"; }
        else if (percent.value == 100)                     { return "color-border-selection font-selection"; }
        else if (percent.value > 100)                      { return "color-border-denial font-denial";       }
      };

      let title = (user) => {
        if(user) {
          let short = user.split(" ");
          return short[0].charAt(0) + short[1].charAt(0);
        }
      };

      let complete = (stages) => {
        let value = 0;
        stages.length && stages.forEach(stage => { value += stage.state_status == 'history' ? 1 : 0; });
        return value;
      };

      let total = (stages) => {
        let value = 0;
        if (stages.length && (stages[0].state_status == 'current' || stages[0].state_status == 'history')) {
          value = stages.length;
        }
        return value;
      };

      return {
        icon_percent,
        icon_status,
        style,
				title,
				complete,
        total,

        url: (id) => api.value + '/api/profile/download?id=' + id + '&token=' + Server.token()
      };
    }
  };
</script>

<style lang="scss" scoped>
  .item {
    margin-left: -6px;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    color: #FFFFFF;
    text-align: center;
    line-height: 20px;
    border: 1px solid #FFFFFF;
  }
</style>