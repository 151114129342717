<template>
  <div class="d-flex w-100" :class="{ 'c-pointer': editable }" @click.stop="$emit('choose', 'estimate')">
    <div class="p-relative w-32px h-32px">
      <div class="icon large" :class="icon_percent()" />
      <div class="icon small p-absolute t-9px l-9px" :class="icon_status()" />
    </div>

    <div
      class="d-flex w-100 h-32px border-top-1 border-right-1 border-bottom-1 border-radius-0-16-16-0"
      :class="style()"
    >
      <div class="transition-100 d-flex flex-column justify-content-center align-items-center pl-2 pr-8">
        <div v-if="editable" class="font-section" :class="style()">
          {{ actually || 0 }}
        </div>

        <div :class="{ 'font-soft': editable, 'font-section': !editable }">
          {{ complexity || 0 }}
        </div>
      </div>

      <div
        class="diagram-edit d-flex align-items-center base-neutral font-dark h-30px my-1 border-radius-16"
        :class="{ 'transition-100': edit, 'd-none': !edit }"
        :style="{ 'opacity': 0 }"
      >
        <field-input
          v-model="fields.complexity"
          :class="{ 'mx-0': !edit, 'mx-16': edit }"
          :autofocus="true"
          @keyup.stop
          @keyup.esc.stop="hide_form"
          @keyup.enter.stop="set_value"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import {
    ref,
    reactive,
    onMounted,
    watch
  } from "vue";
  
  import FieldInput from "../../../../fields/input/Input.vue";

  export default {
    components: {
      FieldInput
    },

    props: {
      actually  : { type: Number , default: () => 0     },
      complexity: { type: Number , default: () => 0     },
      edit      : { type: Boolean, default: () => false },
      editable  : { type: Boolean, default: () => true  }
    },

    emits: [
      "clear",
      "choose",
      "submit"
    ],

    setup(props, context) {
      const percent = ref(0);
      const fields  = reactive({ complexity: props.complexity });

      onMounted(() => recount(props.complexity));
      watch(props.complexity, (complexity) => recount(complexity));

      let recount = (complexity) => {
        fields.complexity = complexity;
        if (complexity) { percent.value = Math.ceil((props.actually / complexity * 100) / 5) * 5; }
      };

      let icon_percent = () => {
        if (percent.value > 200) { return "progress-percent-200"; }
        else { return "progress-percent-" + percent.value; }
      };

      let icon_status = () => {
        if      (percent.value == 0)                       { return "progress-estimate-empty";    }
        else if (percent.value > 0 && percent.value < 100) { return "progress-estimate-progress"; }
        else if (percent.value == 100)                     { return "progress-estimate-full";     }
        else if (percent.value > 100)                      { return "progress-estimate-denial";   }
      };

      let style = () => {
        if      (percent.value == 0)                       { return "color-border-soft font-soft";           }
        else if (percent.value > 0 && percent.value < 100) { return "color-border-highlight font-highlight"; }
        else if (percent.value == 100)                     { return "color-border-selection font-selection"; }
        else if (percent.value > 100)                      { return "color-border-denial font-denial";       }
      };

      let hide_form = (rollback = true) => {
        if (rollback) { fields.complexity = props.complexity; }
        context.emit("clear");
      };

      return {
        fields,

        icon_percent,
        icon_status,
        style,

        hide_form,

        set_value: () => {
          context.emit("submit", fields.complexity);
          hide_form(false);
        }
      };
    }

  };
</script>