<template>
  <layout-web fullscreen>
    <partial-filter>
      <template #content>
        <widget-projects
          :projects="filter.projects"
          @activate="project_active"
          @priority="priority"
        />

        <div class="d-flex align-items-center">
          <widget-filter
            class="ml-8"
            :projects="source.projects"
            @projects_handler="project_show"
          />
        </div>
      </template>
    </partial-filter>

    <partial-content :project="filter.active" :complex="true" :background="false">
      <template #content>
        <div class="d-flex w-100">
          <div class="overflow-y-scroll scrollbar-disable w-300px min-w-300px pr-8 pb-8 pt-10 mt--10">
            <widget-cards-simple
              v-for="user in source.units"
              :key="user.id"
              class="base-neutral mb-8 c-pointer"
              :item="{ id: user.id, name: (user.name + ' ' + user.lastname), description: user.email }"
              :menu="['remove']"
              :partials="['description', 'menu']"
              @click="pick_user(user.id)"
              @remove="remove"
            />

            <div
              class="d-flex justify-content-center mt-10 c-pointer border-1 border-radius-8 color-border-light hover-border-highlight base-neutral p-8"
              @click="show_popups_create"
            >
              {{ $t("add") }}
            </div>
          </div>

          <div v-if="filter.active.user_pick" class="d-flex flex-column flex-grow base-neutral overflow-hidden">
            <div class="p-8">
              <div>
                {{ $t("Roles") }}
              </div>

              <div class="d-flex">
                <widget-cards-cloud-simple
                  v-for="role in source.user.roles"
                  :key="role.id"
                  :item="role"
                  class="pr-8"
                />

                <div>+</div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </partial-content>

    <!-- Popup: Create user -->
    <popup-base v-if="popups.create">
      <template #header>
        <div>{{ $t('add_user') }}</div>

        <div
          class="icon icon-close c-pointer"
          @click="hide_popups_create"
        />
      </template>

      <template #content>
        <field-autocomplete
          v-model="fields.keyword"
          class="mb-15"
          :items="source_search.users"
          :target="fields.user_id"
          :placeholder="$t('search_user')"
          @handle_search="handle_search_user"
          @handle_choose="handle_choose_user"
        />
      </template>

      <template #footer>
        <div class="button primary" @click="create_user">
          {{ $t('ok') }}
        </div>
      </template>
    </popup-base>
  </layout-web>
</template>

<script>
  import {
    reactive,
    onMounted,
  } from "vue";

  import Config from "../../../config.js";

  import Mediator from "../../../system/mediator/Mediator";

  import LayoutWeb from "../../../layouts/Web.vue";

  import PartialFilter  from "../../../visual/partials/Filter.vue";
  import PartialContent from "../../../visual/partials/Content.vue";

  import WidgetFilter   from "../../../visual/widgets/filter/Filter.vue";
  import WidgetProjects from "../../../visual/widgets/projects/Projects.vue";

  import PopupBase              from "../../../visual/popups/base/Base.vue";
  import FieldAutocomplete      from "../../../visual/fields/autocomplete/Autocomplete.vue";
  import WidgetCardsSimple      from "../../../visual/widgets/cards/simple/Simple.vue";
  import WidgetCardsCloudSimple from "../../../visual/widgets/cards/cloud/Simple.vue";

  import RosterEntity  from "../entities/Roster.js";
  import RosterService from "../services/Roster.js";
  import SearchEntity  from "../entities/Search.js";
  import SearchService from "../services/Search.js";

  export default {
    components: {
      LayoutWeb,
      PartialFilter,
      PartialContent,
      WidgetProjects,
      WidgetFilter,
      PopupBase,
      FieldAutocomplete,
      WidgetCardsSimple,
      WidgetCardsCloudSimple
    },

    setup() {
      const source = reactive(new RosterEntity);
      const filter = reactive({ projects: [], active: {}, search: "" });

      const source_search = reactive(new SearchEntity);

      const popups = reactive({ create: false });
      const fields = reactive({ keyword: "", user_id: 0 });

      onMounted(async () => {
        Object.assign(source, await RosterService.load());
        refresh();

        Mediator.define("people:reload", async () => {
          Object.assign(source, await RosterService.load());
          refresh();
        });
      });

      let refresh = () => {
        filter.projects = source.projects.filter(item => item.hidden != 1);
        filter.active   = filter.projects.find(item => item.hidden != 1 && item.active);
      };

      let show_popups_create = () => popups.create = true;
      let hide_popups_create = () => {
        popups.create  = false;
        fields.keyword = "";
        fields.user_id = 0;
      };

      return {
        source,
        filter,
        source_search,
        popups,
        fields,

        show_popups_create,
        hide_popups_create,

        search: (value) => filter.search = value,

        handle_search_user: async (value) => {
          fields.keyword = value;

          Object.assign(source_search, await SearchService.search_user(value));
        },

        handle_choose_user: (item) => {
          fields.user_id = item.id;
          fields.keyword = item.name;
        },

        create_user: async () => {
          Object.assign(source, await RosterService.create({ scopes_id: Config.SCOPE_PROJECT_ID, scopes_entity_id: filter.active.id, users_id: fields.user_id, roles_id: -1 }));
          hide_popups_create();
        },

        create_role: async (role_id, user_id) => Object.assign(source, await RosterService.create({ scopes_id: Config.SCOPE_PROJECT_ID, scopes_entity_id: filter.active.id, users_id: user_id, roles_id: role_id })),

        remove: async (team_id) => Object.assign(source, await RosterService.delete(team_id)),

        // confirm_filter_user: (user) => {
        //   let name = user.name + " " +  user.lastname;

        //   return filter.search === "" ||
        //     ( filter.search !== "" && name.toLowerCase().indexOf(filter.search.toLowerCase()) !== -1 )
        //   ;
        // },

        pick_user: async (id) => { Object.assign(source, await RosterService.states(filter.active.id, "user_pick", id)); refresh(); },

        project_active: async (project) => { Object.assign(source, await RosterService.states(project.id, "project_active", project.id)); refresh(); },
        project_show  : async (project) => { Object.assign(source, await RosterService.states(project.id, "project_show", project.status)); refresh(); },
        priority      : async (event) => {
          if (event.moved) {
            Object.assign(source, await RosterService.priority(filter.projects.map(project => { return { id: project.id }; })));
            refresh();
          }
        }
      };
    }
  };
</script>