<template>
  <div class="font-memo short-text">
    {{ $t("weight") }}: {{ weight }} %
  </div>
</template>

<script>
  export default {
    props: {
      weight: { type: Number, default: () => 0 }
    }
  };
</script>