<template>
  <div class="d-flex justify-content-between w-100 mb-8">
    <div class="font-dark">
      <span class="font-dark font-bold">[{{ type }}]</span> [#{{ item.code || item.node_id }}] [{{ item.project_name }}] {{ item.node_title }}
    </div>

    <div>
      <span class="font-dark font-bold">{{ format(item.date) }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      item: { type: Object, default: () => {} },
      type: { type: String, default: () => "" }
    },

    emits:[
      "detail"
    ],

    setup() {
      return {
        format: (date) => {
          return new Date(date).toLocaleTimeString("ru-RU", { timeStyle: "medium" }) + " " + new Date(date).toLocaleDateString("ru-RU", { dateStyle: "medium" });
        }
      };
    }
  };
</script>