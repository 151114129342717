<template>
  <div v-if="item.progress[0].state_id" class="icon small" :class="{ 'icon-work': !selected, 'icon-work-hover': selected }" />
  <div v-else class="icon small" :class="{ 'icon-idea': !selected, 'icon-idea-hover': selected }" />
</template>

<script>
  export default {
    props: {
      item    : { type: Object , default: () => {}    },
      selected: { type: Boolean, default: () => false }
    }
  };
</script>