import SYSTEM from "../system.js";

export default class Detail {
  constructor(response) {
    this.access   = false;
    this.errors   = {};
    this.status   = {};
    this.projects = [];
    this.events   = [];
    this.nodes    = [];

    if (response) {
      if (response.access) {
          this.access = Object.values(response.access).includes(SYSTEM.MODULE);
      }

      if (response.errors) {
        this.errors = response.errors;
      }

      if (response.status) {
          this.status = response.status;
      }

      if (response.data) {
          this.projects = response.data.projects;
          this.events   = response.data.events;
          this.nodes    = response.data.nodes;
      }
    }
  }
}