const SPACE_BETWEEN_NODES  = 5;
const SPACE_BETWEEN_LEVELS = 30;

export default class Region {
  constructor() {
    this._left   = { 0: 0 };
    this._top    = { 0: 0 };
    this._bottom = { 0: 0 };
  }

  detect_left(level) {
    return this._left[level];
  }

  detect_top(level) {
    return this._top[level];
  }

  detect_bottom(level) {
    return this._bottom[level];
  }

  detect_next_top(level, height) {
    let top = this._bottom[level];
    this._bottom[level] += height + SPACE_BETWEEN_NODES;
    return top;
  }

  detect_middle_top(level, height) {
    return this._top[level] + (
      (this._bottom[level] - this._top[level]) // Region height
      -
      (height + SPACE_BETWEEN_NODES) // Node + spacing height
    ) / 2;
  }

  create_nested(level, width) {
    this._left[level] = level ? 
      this._left[level - 1] + width + SPACE_BETWEEN_LEVELS :
      0
    ;

    // @INFO: We can't just get top of the previous level.
    // Top coordinate is top of the whole level region. This is not the top value of the node.
    // Meanwhile we handle nodes one-by-one. So, bottom of the previous level is the bottom of 
    // the level region and the bottom of the previous sibling of the parent node (bottom value
    // will include current parent node only after all children will be handled). 
    this._top[level]    = this._bottom[level - 1] || 0;
    this._bottom[level] = this._top[level] || 0;
  }

  parent_bigger_than_nested(level, parent_height) {
    let nested_height =
      this._bottom[level + 1] - this._top[level + 1] - SPACE_BETWEEN_NODES
    ;
    return (parent_height - nested_height) / 2;
  }
  
  equate_parent_height_to_nested_height(level) {
    this._bottom[level] = this._bottom[level + 1];
  }
}