export default {
  ru: {
    Login                 : "Войти",
    Registration          : "Регистрация",
    forgot_your_password  : "забыли пароль",
    create_new_account    : "Создать аккаунт",
    email_is_empty        : "Пустая электронная почта",
    password_is_empty     : "Пустой пароль",
    lastname_is_empty     : "Пустая фамилия",
    password_not_confirmed: "Пароль не подтвержден",
  },
  en: {
    Login                 : "Login",
    Registration          : "Registration",
    forgot_your_password  : "forgot your password",
    create_new_account    : "Create new account",
    email_is_empty        : "Email is empty",
    password_is_empty     : "Password is empty",
    lastname_is_empty     : "Lastname is empty",
    password_not_confirmed: "Password not confirmed",
  }
};