<template>
  <div class="d-flex align-items-center">
    <draggable
      class="list d-flex"
      :list="projects"
      item-key="id"
      group="projects"
      @change="$emit('priority', $event)"
    >
      <template #item="{element}">
        <div
          class="item w-120px mx-4 margin-x-truncate border-bottom-2 px-8 py-12 c-pointer"
          :class="{
            'color-border-highlight': element.active,
            'border-color-transparent': !element.active
          }"
          @click="$emit('activate', element)"
        >
          <div
            class="short-title font-weight-500"
            :class="{
              'font-highlight': element.active
            }"
          >
            {{ element.name }}
          </div>

          <div
            class="short-title font-memo"
            :class="{
              'font-highlight': element.active,
              'font-soft': !element.active
            }"
          >
            {{ element.description }}
          </div>
        </div>
      </template>
    </draggable>

    <div v-if="projects.length > 7"
      class="p-relative border-radius-12 mx-4 base-neutral h-inherit d-flex box-shadow-bottom w-32px h-32px justify-content-center align-items-center c-pointer"
      :class="{
        'base-highlight': dropdown,
        'base-neutral': !dropdown
      }"
      @click="dropdown = !dropdown"
    >
      <div
        class="icon little"
        :class="{
          'icon-arrow-top-hover': dropdown,
          'icon-arrow-bottom': !dropdown
        }"
      />

      <div v-if="dropdown"
        class="p-absolute t-39px r-0 w-200px p-16 base-neutral z-107 box-shadow-bottom border-radius-16 d-flex flex-column"
      >
        <div v-for="(element, index) in projects" :key="element.id">
          <div v-if="index > 6"
            class="dropdown-item w-inherit my-4 w-150px border-bottom-2 p-8 c-pointer"
            :class="{
              'color-border-highlight': element.active,
              'border-color-transparent': !element.active
            }"
            @click="$emit('activate', element)"
          >
            <div
              class="short-title"
              :class="{
                'font-highlight': element.active
              }"
            >
              {{ element.name }}
            </div>

            <div
              class="short-title font-memo"
              :class="{
                'font-highlight': element.active,
                'font-soft': !element.active
              }"
            >
              {{ element.description }}
            </div>
          </div>
        </div>
     </div>
    </div>
  </div>
</template>
  
<script>
  import { ref } from "vue";

  import Draggable from "vuedraggable";

  export default {
    components: {
      Draggable
    },

    props: {
      projects: { type: Array, default: () => [] }
    },

    emits: [
      "activate",
      "priority"
    ],

    setup() {
      const dropdown = ref(false);

      return {
        dropdown
      };
    }
  };
</script>

<style lang="scss" scoped>
  .list .item:nth-child(n+8) { display: none; }
  .item, .dropdown-item { &:hover { div { color: #0CAEFF; } } }
</style>