<template>
  <div class="path">
    <div v-if="suffix">
      <div class="disable">
        {{ suffix }}
      </div>
    </div>

    <div v-for="(member, index) in path" :key="index">
      <div class="member" @click.stop="$emit('handler', index)">
        {{ member }}
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      path  : { type: Object, default: () => {} },
      suffix: { type: String, default: () => "" }
    },

    emits: [
      "handler"
    ]
  };
</script>