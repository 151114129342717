<template>
  <layout-web>
    <div>
      <partial-header
        :id="$route.params.id"
        :active="2"
      />

      <div class="base-neutral border-radius-8 p-8">
        <roles-roster
          :spaces_id="id"
          root_url="spaces"
        />
      </div>
    </div>
  </layout-web>
</template>

<script>
  import LayoutWeb     from "../../../layouts/Web.vue";
  import PartialHeader from "./partials/Header.vue";
  import RolesRoster   from "../../roles/plugins/roster/Roster.vue";

  export default {
    components: {
      LayoutWeb,
      PartialHeader,
      RolesRoster
    },

    props: {
      id: { type: String, default: () => "" }
    }
  };
</script>