<template>
  <div class="d-flex justify-content-between base-neutral border-radius-16 mx-27 mb-16 px-27 py-18">
    <div class="d-flex flex-column flex-grow">
      <div class="font-memo py-5">
        #{{ code }}
      </div>

      <field-input-combo
        :value="value"
        :placeholder="$t('please_insert_title')"
        @submit="(value) => $emit('update', 'title', value)"
      />
    </div>

    <div class="d-flex flex-wrap">
      <div v-if="actions.remove"
        class="button cancel mx-5 mb-5"
        @click="$emit('remove')"
      >
        {{ $t("remove") }}
      </div>

      <div v-if="actions.backward"
        class="button cancel mx-5 mb-5"
        @click="$emit('backward')"
      >
        {{ $t("backward") }}
      </div>

      <div v-if="actions.onward"
        class="button primary mx-5 mb-5"
        @click="$emit('onward')"
      >
        {{ $t("done") }}
      </div>

      <div v-if="actions.onward"
        class="button cancel mx-5 mb-5"
        @click="$emit('finish')"
      >
        {{ $t("abort") }}
      </div>
    </div>
  </div>
</template>

<script>
  import FieldInputCombo from "../../../../visual/fields/input/combo/Combo.vue";

  export default {
    components: {
      FieldInputCombo,
    },

    props: {
      id     : { type: Number, default: () => 0  },
      code   : { type: String, default: () => "" },
      value  : { type: String, default: () => "" },
      actions: { type: Object, default: () => {} }
    },

    emits: [
      "update",
      "remove",
      "finish",
      "onward",
      "backward"
    ]
  };
</script>
