<template>
  <router-view />
</template>

<script>
  import "../themes/light.scss";

  export default {
    setup () {
      return {};
    }
  };
</script>