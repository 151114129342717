<template>
  <div class="field w-100 mb-16">
    <div v-if="label" class="label">
      {{ label }}
    </div>

    <div class="checkbox">
      <label>
        <input 
          class="checkbox"
          type="checkbox"
          :checked="!!value.status"
          @click="$emit('submit', { id: value.id, status: !value.status })"
        >

        <span class="checkmark" />

        <div v-if="lang" class="text lh-20">
          {{ $t(value.content) }}
        </div>

        <div v-else class="text lh-20">
          {{ value.content }}
        </div>
      </label>
    </div>

    <div v-if="description" class="description font-memo">
      {{ description }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      lang       : { type: Boolean, default: () => false },
      label      : { type: String , default: () => ""    },
      value      : { type: Object , default: () => {}    },
      description: { type: String , default: () => ""    }
    },

    emits: [
      "submit"
    ]
  };
</script>