<template>
  <div class="field autocomplete w-100">
    <div v-if="label" class="label">
      {{ label }}
    </div>

    <input
      :ref="(element) => { input = element }"
      type="text"
      class="my-5"
      :value="modelValue"
      :placeholder="placeholder"
      @keyup="search"
    >

    <div v-if="items.length > 0 && modelValue !== '' && target == 0"
      class="dropdown box-shadow"
    >
      <div
        v-for="item in items"
        :key="item.id"
        class="item"
        :class="{ active: item.active }"
        @click="choose(item)"
      >
        {{ item.name }}
      </div>
    </div>

    <div v-if="description" class="description font-memo">
      {{ description }}
    </div>
  </div>
</template>

<script>
  const KEYCODE_ENTER = 13;
  const KEYCODE_ESC   = 27;
  const KEYCODE_DOWN  = 40;
  const KEYCODE_UP    = 38;

  import {
    ref,
    onMounted,
    nextTick
  } from "vue";

  export default {
    props: {
      // eslint-disable-next-line vue/prop-name-casing
      modelValue : { type: String , default: () => ""    },
      label      : { type: String , default: () => ""    },
      items      : { type: Array  , default: () => []    },
      target     : { type: Number , default: () => 0     },
      placeholder: { type: String , default: () => ""    },
      description: { type: String , default: () => ""    },
      autofocus  : { type: Boolean, default: () => false },
      creatable  : { type: Boolean, default: () => false }
    },
 
    emits: [
      "handle_choose",
      "handle_search"
    ],

    setup(props, context) {
      const input = ref();

      onMounted(async() => {
        await nextTick();
        if (props.autofocus) input.value.focus();
      });

      let handle_move_down = function (items, index) {
        if (index != -1) {
          items.map(item => item.active = false);
          if (items[index + 1]) { items[index + 1].active = true; }
        }
        else if (items[0]) {
          items[0].active = true;
        }
      };

      let handle_move_up = function (items, index) {
        if (index != -1) {
          items.map(item => item.active = false);
          if (items[index - 1]) { items[index - 1].active = true; }
        }
        else if (items[(items.length - 1)]) {
          items[(items.length - 1)].active = true;
        }
      };

      let handle_choose = function (items, index) {
        if (index != -1 || props.creatable) {
          context.emit("handle_choose", { id: index != -1 ? items[index].id : 0, name: index != -1 ? items[index].name : input.value.value });
        }
      };

      let handle_reset = function() {
        context.emit("handle_choose", { id: 0, name: "" });
      };

      return {
        input,

        search: (event) => {
          let index = props.items.findIndex(item => item.active == true);
          
          if (event.keyCode == KEYCODE_DOWN) {
            handle_move_down(props.items, index);
          }
          else if (event.keyCode == KEYCODE_UP) {
            handle_move_up(props.items, index);
          }
          else if (event.keyCode == KEYCODE_ENTER) {
            handle_choose(props.items, index);
          }
          else if (event.keyCode == KEYCODE_ESC) {
            handle_reset();
          }
          else {
            context.emit("handle_search", event.target.value);
          }
        },

        choose: (item) => {
          context.emit("handle_choose", item);
        }
      };
    },
  };
</script>
