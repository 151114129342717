<template>
  <div class="d-flex flex-column justify-content-between base-neutral border-radius-16 mb-16 px-27 py-18">
    <field-select-combo v-if="leaves.includes(parseInt(id)) || leaves.length == 0"
      class="mb-32"
      :value="cycles_id"
      :items="cycles"
      :selected="cycles_id"
      :label="$t('Process')"
      :placeholder="$t('To_run_task_specify_process')"
      @submit="(value) => $emit('update', 'cycles_id', value)"
    />

    <widget-complexities
      class="mb-32"
      :complexity="complexities.value && complexities.value[0]"
      :routes="complexities.routes"
      :label="$t('Complexity')"
      :placeholder="$t('please_insert_complexity')"
      @submit="(value, route) => $emit('update_complexities', value, route)"
    />

    <field-select-combo
      class="mb-32"
      :value="categories_id"
      :items="categories"
      :selected="categories_id"
      :label="$t('Category')"
      :placeholder="$t('please_insert_category')"
      :clear="true"
      @submit="(value) => $emit('update', 'categories_id', value)"
    />

    <widget-timing v-if="states"
      class="mb-32"
      :label="$t('Time_current_stage')"
      :value="timing"
      @submit="(value, reason, created) => $emit('update_timing', value, reason, created)"
    />

    <field-datetime-deadline-combo
      class="mb-32"
      :value="deadline"
      :label="$t('deadline')"
      :placeholder="$t('Deadline_not_specified')"
      @submit="(value) => $emit('update', 'deadline', value)"
    />

    <widget-users-watchers
      class="mb-32"
      :label="$t('watchers')"
      :users="users"
      :selected="watchers"
      @submit="(id) => $emit('update_watcher', id)"
    />

    <widget-files-combo
      class="mb-32"
      :label="$t('files')"
      :entity_id="id"
      :scope_id="scope_id"
      :users_id="users_id"
    />
  </div>
</template>

<script>
  import FieldSelectCombo           from "../../../../visual/fields/select/combo/Combo.vue";
  import FieldDatetimeDeadlineCombo from "../../../../visual/fields/datetime/deadline/combo/Combo.vue";

  import WidgetComplexities  from "../../../../visual/widgets/complexities/Complexities.vue";
  import WidgetFilesCombo    from "../../../../visual/widgets/files/combo/Combo.vue";
  import WidgetUsersWatchers from "../../../../visual/widgets/users/watchers/Watchers.vue";
  import WidgetTiming        from "../../../../visual/widgets/timing/Timing.vue";

  export default {
    components: {
      FieldSelectCombo,
      FieldDatetimeDeadlineCombo,
      WidgetComplexities,
      WidgetFilesCombo,
      WidgetUsersWatchers,
      WidgetTiming
    },

    props: {
      id           : { type: Number, default: () => 0  },
      scope_id     : { type: Number, default: () => 0  },
      users_id     : { type: Number, default: () => 0  },

      cycles_id    : { type: Number, default: () => 0  },
      complexities : { type: Object, default: () => {} },
      categories_id: { type: Number, default: () => 0  },
      timing       : { type: String, default: () => "" },
      deadline     : { type: String, default: () => "" },

      leaves       : { type: Array , default: () => [] },
      cycles       : { type: Array , default: () => [] },
      states       : { type: Array , default: () => [] },
      users        : { type: Array , default: () => [] },
      watchers     : { type: Array , default: () => [] },
      categories   : { type: Array , default: () => [] },
    },

    emits: [
      "update",
      "update_complexities",
      "update_timing",
      "update_watcher"
    ]
  };
</script>
