<template>
  <layout-web v-slot="slotProps" fullscreen>
    <partial-filter>
      <template #content>
        <widget-projects
          :projects="filter.projects"
          @activate="project_active"
          @priority="priority"
        />

        <div class="d-flex align-items-center">
          <widget-filter
            class="ml-8"
            :projects="source.projects"
            @projects_handler="project_show"
          />
        </div>
      </template>
    </partial-filter>

    <partial-content :project="filter.active" :complex="true" :background="false">
      <template #content>
        <div class="d-flex w-100">
          <milestones-partial
            :project="filter.active"
            :units="source.units"
            :roots="source.roots"

            @pick="milestone_pick"
            @create="milestone_create"
            @remove="milestone_remove"
            @order="milestone_order"
            @complexity="complexity"
            @period="milestone_period"
            @shipped="milestone_shipped"
          />

          <div v-if="filter.active.roadmap_pick" class="d-flex flex-column flex-grow base-neutral overflow-hidden">
            <chart-mindmap
              class="overflow-hidden box-shadow z-1"
              :mode="'roadmap'"
              
              :nodes="schema.nodes"
              :links="schema.links"
              :path="schema.path"
              :root="+filter_schema.root"
              :progress="source.progress"

              :active="filter_schema.active"
              :selected="selected_schema"

              @child="schema_create_node_child"
              @sibling="schema_create_node_sibling"
              @remove="schema_delete_bundle"
              @move="schema_move_node"
              @insert="schema_insert_node"
              @fold="schema_update_node_option"

              @deadline="deadline"
              @complexity="complexity"

              @focused="focused"
              @select="show_popups_detail_schema"
              @cancel="hide_popups_detail_schema"

              @child_placeholder="schema_child_placeholder"
              @sibling_placeholder="schema_sibling_placeholder"
              @cancel_placeholder="schema_cancel_placeholder"
            />

            <sprints-partial
              :project="filter.active"
              :sprints="source.sprints"
              :list="source.sprints_nodes"

              @detail="show_popups_detail"
              @cancel="hide_popups_detail"

              @break="sprint_break"
              @create="sprint_create"
              @remove="sprint_remove"
              @reset="sprint_reset"
              @move="sprint_node_move"
              @period="milestone_period"
              @shipped="milestone_shipped"
            />
          </div>
        </div>
      </template>
    </partial-content>

    <!-- Popup: Details -->
    <popup-sidebar v-if="popups.detail">
      <template #header>
        <div
          class="font-title c-pointer"
          @click="redirect(popups.detail.code)"
        >
          {{ $t(popups.detail.header) }}
        </div>

        <div
          class="font-highlight lh-16 c-pointer"
          @click="popups.detail = false"
        >
          {{ $t("close") }}
        </div>
      </template>

      <template #content>
        <node-plugin
          :node_id="popups.detail.id"
          :code_id="popups.detail.code"
          :user_id="slotProps.user.id"
        />
      </template>

      <template #footer />
    </popup-sidebar>
  </layout-web>
</template>

<script>
  import {
    ref,
    reactive,
    onMounted,
    defineAsyncComponent
  } from "vue";

  import Mediator from "../../../system/mediator/Mediator";

  import LayoutWeb         from "../../../layouts/Web.vue";
  import MilestonesPartial from "./partials/Milestones.vue";
  import SprintsPartial    from "./partials/Spints.vue";

  import PartialFilter  from "../../../visual/partials/Filter.vue";
  import PartialContent from "../../../visual/partials/Content.vue";

  import PopupSidebar from "../../../visual/popups/sidebar/Sidebar.vue";

  import WidgetFilter   from "../../../visual/widgets/filter/Filter.vue";
  import WidgetProjects from "../../../visual/widgets/projects/Projects.vue";

  import ChartMindmap      from "../../../system/chart/Chart.vue";
  import ChartEntityBundle from "../../../system/chart/entities/Bundle.js";

  import SchemaEntity  from "../entities/Schema.js";
  import RosterEntity  from "../entities/Roster.js";
  import RosterService from "../services/Roster.js";

  export default {
    components: {
      LayoutWeb,
      ChartMindmap,
      MilestonesPartial,
      SprintsPartial,
      PartialFilter,
      PartialContent,
      PopupSidebar,
      WidgetFilter,
      WidgetProjects,
      NodePlugin: defineAsyncComponent(() => import('../../node/plugins/roster/Roster.vue'))
    },

    setup() {
      const source = reactive(new RosterEntity);
      const schema = reactive(new SchemaEntity);

      const popups = reactive({ detail: false });
      const filter = reactive({ projects: [], active: {}, units: [] });
      const count  = ref(0);

      const filter_schema = reactive({ active : { entity: {} }, root : 0, filters: { progress: 1, archived: 1 } });
      const selected_schema = ref(0);

      onMounted(async () => {
        Object.assign(source, await RosterService.load());
        refresh();

        Mediator.define("roadmap:reload", async () => {
          Object.assign(source, await RosterService.load());
          refresh();
        });
      });

      let refresh = () => {
        filter.projects = source.projects.filter(item => item.hidden != 1);
        filter.active   = filter.projects.find(item => item.hidden != 1 && item.active);

        filter_schema.root = source.schema && source.schema.nodes && source.schema.nodes[0] && source.schema.nodes[0].entity.id || 0;
        
        Object.assign(schema, source.schema);
      };

      let redirect = (id) => window.location.href = "/node/" + id;

      let show_popups_detail_schema = (node) => {
        filter_schema.active = node;
        selected_schema.value = node.entity.id;
        document.querySelector("[data-id='" + filter_schema.active.entity.id + "']").focus();
        show_popups_detail({ id: node.entity.id, code: node.entity.code, header: '#' + node.entity.code });
      };

      let hide_popups_detail_schema = () => {
        selected_schema.value = 0;
        hide_popups_detail();
      };

      let show_popups_detail = (data) => {
        popups.detail = data;
      };

      let hide_popups_detail = () => {
        if (popups.detail.id) {
          popups.detail = false;
        }
      };

      function schema_child_placeholder(target) {
        let source_link = schema.links.find(link => link.entity.nodes_target_id == target);
        let source_node = schema.nodes.find(node => node.entity.id == target);

        schema.links = schema.links.filter(link => link.entity.id != 0);
        schema.nodes = schema.nodes.filter(node => node.entity.id != 0);

        let bundle = reactive(new ChartEntityBundle);

        bundle.link.entity.nodes_root_id   = source_link && source_link.entity.nodes_root_id || target;
        bundle.link.entity.nodes_source_id = target;
        bundle.node.entity.target          = target;
        bundle.node.entity.type            = "child";
        bundle.node.option.level           = source_node.option.level + 1;
        bundle.node.option.offset          = Date.now();

        schema.links.push(bundle.link);
        schema.nodes.push(bundle.node);
      }

      function schema_sibling_placeholder(target) {
        let source_link = schema.links.find(link => link.entity.nodes_target_id == target);
        let source_node = schema.nodes.find(node => node.entity.id == target);

        schema.links = schema.links.filter(link => link.entity.id != 0);
        schema.nodes = schema.nodes.filter(node => node.entity.id != 0);

        let bundle = reactive(new ChartEntityBundle);

        bundle.link.entity.nodes_root_id   = source_link && source_link.entity.nodes_root_id || target;
        bundle.link.entity.nodes_source_id = source_link && source_link.entity.nodes_source_id || null;
        bundle.node.entity.target          = target;
        bundle.node.entity.type            = "sibling";
        bundle.node.option.level           = source_node.option.level;
        bundle.node.option.offset          = source_node.option.offset + 0.5 + count.value;

        schema.links.push(bundle.link);
        schema.nodes.push(bundle.node);
      }

      function schema_cancel_placeholder() {
        count.value = 0;
        schema.links = schema.links.filter(link => link.entity.id != 0);
        schema.nodes = schema.nodes.filter(node => node.entity.id != 0);

        if (filter_schema.active.entity.id) {
          document.querySelector("[data-id='" + filter_schema.active.entity.id + "']").focus();
        }
      }

      return {
        source,
        schema,
        popups,
        filter,

        filter_schema,
        selected_schema,

        show_popups_detail_schema,
        hide_popups_detail_schema,

        show_popups_detail,
        hide_popups_detail,

        redirect,

        milestone_pick   : async (node) => { Object.assign(source, await RosterService.states(filter.active.id, "roadmap_pick", node.id)); refresh(); },
        milestone_create : async (node) => { Object.assign(source, await RosterService.create(filter.active.id, node.id, node.name)); refresh(); },
        milestone_remove : async (milestones_id) => { Object.assign(source, await RosterService.remove_milestone(filter.active.id, milestones_id)); refresh(); },
        milestone_order  : async (target_id, source_id, offset) => { Object.assign(source, await RosterService.order(filter.active.id, target_id, source_id, offset)); refresh(); },
        milestone_shipped: async (milestones_id) => { Object.assign(source, await RosterService.shipped(filter.active.id, milestones_id)); refresh(); },
        milestone_period : async (milestones_id, start, finish) => { Object.assign(source, await RosterService.period(filter.active.id, milestones_id, start, finish)); refresh(); },
        
        sprint_break    : async (milestones_id, value) => { Object.assign(source, await RosterService.break_sprints(milestones_id, value)); refresh(); },
        sprint_create   : async (milestones_id) => { Object.assign(source, await RosterService.create_sprint(filter.active.id, milestones_id)); refresh(); },
        sprint_remove   : async (milestones_id) => { Object.assign(source, await RosterService.remove_milestone(filter.active.id, milestones_id)); refresh(); },
        sprint_reset    : async (node) => { Object.assign(source, await RosterService.reset_node_sprint(filter.active.id, node)); refresh(); },
        sprint_node_move: async (source_id, target_id) => {
          if (source_id && target_id) {
            Object.assign(source, await RosterService.move_node(filter.active.id, source_id, target_id)); refresh();
          }
        },

        complexity: async (node_id, complexity) => { Object.assign(source, await RosterService.complexity(filter.active.id, node_id, complexity)); refresh(); },
        deadline  : async (node_id, deadline)   => { Object.assign(source, await RosterService.deadline(filter.active.id, node_id, deadline)); refresh(); },

        schema_child_placeholder,
        schema_sibling_placeholder,
        schema_cancel_placeholder,

        schema_create_node_child: async (target) => {
          Object.assign(schema, await RosterService.schema_create_node_child(filter_schema.root, filter_schema.filters, target));
          schema_child_placeholder(target.id);
        },

        schema_create_node_sibling: async (target) => {
          let count_old = schema.nodes.length;
          Object.assign(schema, await RosterService.schema_create_node_sibling(filter_schema.root, filter_schema.filters, target));
          let count_new = schema.nodes.length;

          if (count_old != count_new + 1) { count.value++; }

          schema_sibling_placeholder(target.id);
        },

        schema_delete_bundle: async (id) => {
          if (filter_schema.root == id) {
            RosterService.schema_delete_bundle(filter_schema.root, filter_schema.filters, id);
            history.back();
            window.location.href = document.referrer + '?date=' + new Date().valueOf();
          }
          else {
            Object.assign(schema, await RosterService.schema_delete_bundle(filter_schema.root, filter_schema.filters, id));
          }
        },

        schema_move_node: async (data) => {
          Object.assign(schema, await RosterService.schema_move_node(filter_schema.root, filter_schema.filters, data.node_id, data.target_id, data.location));
        },

        schema_insert_node: async (source_id, target_id, action) => {
          if (action == "copy") { Object.assign(schema, await RosterService.schema_insert(filter_schema.root, source_id, target_id)); }
          else if (action == "cut") { Object.assign(schema, await RosterService.schema_move_node(filter_schema.root, filter_schema.filters, source_id, target_id, "LOCATION_CHILD")); }
        },

        schema_update_node_option: async (option) => {
          Object.assign(schema, await RosterService.schema_update_node_option(filter_schema.root, filter_schema.filters, option));
        },

        project_active: async (project) => { Object.assign(source, await RosterService.states(project.id, "project_active", project.id)); refresh(); },
        project_show  : async (data) => { Object.assign(source, await RosterService.states(data.id, "project_show", data.status)); refresh(); },
        priority      : async (event) => {
          if (event.moved) {
            Object.assign(source, await RosterService.priority(filter.projects.map(project => { return { id: project.id }; })));
            refresh();
          }
        }
      };
    }
  };
</script>