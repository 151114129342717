<template>
  <div :data-id="item.id || 0">
    <div class="d-flex min-w-200px">
      <div v-for="n in item.level + 1"
        :key="n"
        class="w-34px max-w-34px min-w-34px border-left-3 border-highlight-slave"
      />

      <widget-card-node-simple v-if="item.nodes_source_id" 
        class="structure-item my-4 base-neutral min-w-200px"

        :data-level="item.level + 1"

        :item="item"
        :menu="['details', 'strucrure', 'copy', 'cut', 'insert', 'remove', 'limited', 'shared']"
        :partials="['code', 'fold', 'idea', 'work', 'progress-complete', 'menu', 'launch', 'period', 'create']"
        :cycles="cycles"
        :selected="project.structure_pick == item.id || selected"
        :periods="periods"

        :folded="{ access: item.folded_access, status: !item.folded_status }"
        @fold="$emit('structure_fold', project.id, item)"

        @keyup.left.exact="$emit('move_left', project.id, item)"
        @keyup.right.exact="$emit('move_right', project.id, item)"
        @keyup.up.exact="$emit('move_up', project.id, item)"
        @keyup.down.exact="$emit('move_down', project.id, item)"
        @keyup.enter.exact="$emit('move_enter', project.id, item)"

        @dragover.prevent
        @dragenter.prevent
        @dragleave.prevent
        @drop="$emit('catch', $event, project, item)"

        @accesses="(id, type) => $emit('set_accesses', id, type)"
        @create="(item) => $emit('show_bundle', item)"
        @remove="(node_id) => $emit('remove', node_id)"
        @onward="(data) => $emit('onward', data)"
        @period="(data) => $emit('period', data)"
        @insert="(source_id, target_id, action) => $emit('insert', source_id, target_id, action)"
        @click.stop="$emit('structure_pick', project.id, item)"
      />

      <widget-card-node-simple v-else-if="!item.nodes_source_id"
        class="structure-item mb-4 base-neutral min-w-200px"

        :data-level="item.level + 1"

        :item="{ name: project.name, drafts: item.drafts, nodes: item.nodes, complete: item.complete, id: item.id }"
        :menu="['insert']"
        :partials="['idea', 'work', 'menu', 'progress-complete', 'launch', 'period', 'create']"
        :selected="!project.structure_pick || selected"
        :cycles="cycles"
        :periods="periods"

        @keyup.down.exact="$emit('move_down', project.id, item)"
        @keyup.enter.exact="$emit('move_enter', project.id, item)"

        @dragover.prevent
        @dragenter.prevent
        @dragleave.prevent
        @drop="$emit('catch', $event, project, null)"

        @create="(item) => $emit('show_bundle', item)"
        @onward="(data) => $emit('onward', data)"
        @period="(data) => $emit('period', data)"
        @insert="(source_id, target_id, action) => $emit('insert', source_id, target_id, action, project.id)"
        @click.stop="$emit('structure_pick', project.id, item)"
      />
    </div>

    <div v-if="bundle === item.id" class="d-flex w-100">
      <div v-for="n in item.level + 2"
        :key="n"
        class="w-34px max-w-34px min-w-34px border-left-3 border-highlight-slave"
      />

      <widget-card-node-placeholder
        class="mb-4 base-neutral"
        @close="$emit('hide_bundle')"
        @create="create"
      />
    </div>
  </div>
</template>

<script>
  import WidgetCardNodeSimple      from "../../../../visual/widgets/cards/node/Simple.vue";
  import WidgetCardNodePlaceholder from "../../../../visual/widgets/cards/node/simple/Placeholder.vue";

  export default {
    components: {
      WidgetCardNodeSimple,
      WidgetCardNodePlaceholder
    },

    props: {
      project : { type: Object , default: () => {}    },
      item    : { type: Object , default: () => {}    },
      cycles  : { type: Array  , default: () => []    },
      periods : { type: Array  , default: () => []    },
      bundle  : { type: Number , default: () => 0     },
      selected: { type: Boolean, default: () => false },
    },

    emits: [
      "structure_fold",
      "structure_pick",
      "show_bundle",
      "hide_bundle",
      "move_left",
      "move_right",
      "move_up",
      "move_down",
      "move_enter",
      "create",
      "catch",
      "remove",
      "onward",
      "period",
      "insert",
      "set_accesses"
    ],

    setup(props, context) {
      return {
        branch: (array) => array && Object.keys(array).length != 0,

        folded: (array) => array && Object.values(array).find(
          child => child.branch && Object.values(child.branch).length != 0 && Object.values(child.branch)
        ),

        create: async (title) => { context.emit("create", title); }
      };
    }
  };
</script>